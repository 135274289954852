import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material/material.module';
import { TogglePasswordDirective } from './directives/toggle-password.directive';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderMenuComponent } from './components/header-menu/header-menu.component';
import { UserIconComponent } from './components/user-icon/user-icon.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { BurgerMenuComponent } from './components/burger-menu/burger-menu.component';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { ErrorTooltipComponent } from './components/error-tooltip/error-tooltip.component';
import { ErrorBoxComponent } from './components/error-box/error-box.component';
import { ClearFilterComponent } from './components/clear-filter/clear-filter.component';
import { BasicModalComponent } from './components/basic-modal/basic-modal.component';
import { CancelRequestComponent } from './components/cancel-request/cancel-request.component';
import { RescheduleRequestToastComponent } from './components/reschedule-request-toast/reschedule-request-toast.component';
import { DeleteAttachmentComponent } from './components/delete-attachment/delete-attachment.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { PhoneInputComponent } from './components/phone-input/phone-input.component';
import { SearchComponent } from './components/search/search.component';
import { FilesService } from './services/files.service';
import { TruckConfigurationComponent } from './components/truck-configuration/truck-configuration.component';
import { GetFileExtensionPipe } from './pipes/get-file-extension.pipe';
import { FormatBytesPipe } from './pipes/format-bytes.pipe';
import { SuccessToastComponent } from './components/success-toast/success-toast.component';
import { MakeReadComponent } from './components/make-read/make-read.component';
import { VehicleMapComponent } from './components/vehicle-map/vehicle-map.component';
import { DoughnutChartComponent } from './components/doughnut-chart/doughnut-chart.component';
import { ClearFilterChargersComponent } from './components/clear-filter-chargers/clear-filter-chargers.component';
import { UserIconPreviewComponent } from './components/user-icon-preview/user-icon-preview.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { PeriodFormComponent } from './components/period-form/period-form.component';
import { DatepickerHeaderComponent } from './components/datepicker-header/datepicker-header.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { EnergyPricingChartComponent } from './components/energy-pricing-chart/energy-pricing-chart.component';
import { DatepickerMomentHeaderComponent, MomentDatepickerComponent } from './components/moment-datepicker/moment-datepicker.component';
import { ScreenMessageComponent } from './components/screen-message/screen-message.component';
import { ReplacePipe } from './pipes/replace-pipe';
import { PeakScheduleComponent } from './components/peak-schedule/peak-schedule.component';
import { WarrantyClaimModalComponent } from './components/warranty-claim-modal/warranty-claim-modal.component';
import { MilesDrivenWidgetComponent } from './components/miles-driven-widget/miles-driven-widget.component';
import { ChipsAutocompleteComponent } from './components/chips-autocomplete/chips-autocomplete.component';
import { SelectActiveOptionDirective } from './directives/select-active-option-directive';
import { ReportFiltersComponent } from './components/report-filters/report-filters.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { MaintenanceErrorComponent } from './components/maintenance-error/maintenance-error.component';
import { PremiumFeatureScreenComponent } from './components/premium-feature-screen/premium-feature-screen.component';
import { ThousandsToKPipe } from './pipes/thousands-to-k.pipe';
import { ImpersonationMessageComponent } from './components/impersonation-message/impersonation-message.component';
import { NotFoundRequestsStateComponent } from './components/not-found-requests-state/not-found-requests-state.component';
import { ExportButtonComponent } from './components/export-button/export-button.component';
import { DownloadAppComponent } from './components/download-app/download-app.component';
import { FreePlanUpgradeLabelComponent } from './free-plan-upgrade-label/free-plan-upgrade-label.component';
import { UpgradeBannerComponent } from './upgrade-banner/upgrade-banner.component';
import { SwitcherComponent } from './components/switcher/switcher.component';
import { MultipleSearchComponent } from './components/multiple-search/multiple-search.component';
import { VehiclesEmptyComponent } from './components/vehicles-empty/vehicles-empty.component';
import { MultipleSelectComponent } from './components/multiple-select/multiple-select.component';
import { ChipsSelectComponent } from './components/chips-select/chips-select.component';

const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  MaterialModule,
  RouterModule,
  NgxMatFileInputModule,
  NgxSkeletonLoaderModule,
  NgxSliderModule,
  OverlayModule,
];
const components = [
  HeaderComponent,
  FooterComponent,
  HeaderMenuComponent,
  UserIconComponent,
  SidenavComponent,
  SpinnerComponent,
  BurgerMenuComponent,
  ErrorTooltipComponent,
  ErrorBoxComponent,
  BasicModalComponent,
  ClearFilterComponent,
  CancelRequestComponent,
  RescheduleRequestToastComponent,
  SuccessToastComponent,
  DeleteAttachmentComponent,
  PhoneInputComponent,
  SearchComponent,
  TruckConfigurationComponent,
  GetFileExtensionPipe,
  FormatBytesPipe,
  ReplacePipe,
  MakeReadComponent,
  VehicleMapComponent,
  DoughnutChartComponent,
  ClearFilterChargersComponent,
  UserIconPreviewComponent,
  TooltipComponent,
  DatepickerHeaderComponent,
  DatepickerComponent,
  PeriodFormComponent,
  EnergyPricingChartComponent,
  DatepickerMomentHeaderComponent,
  ScreenMessageComponent,
  PeakScheduleComponent,
  MomentDatepickerComponent,
  WarrantyClaimModalComponent,
  MilesDrivenWidgetComponent,
  ChipsAutocompleteComponent,
  ChipsSelectComponent,
  ReportFiltersComponent,
  MaintenanceErrorComponent,
  ImpersonationMessageComponent,
  PremiumFeatureScreenComponent,
  ThousandsToKPipe,
  NotFoundRequestsStateComponent,
  ExportButtonComponent,
  DownloadAppComponent,
  FreePlanUpgradeLabelComponent,
  UpgradeBannerComponent,
  SwitcherComponent,
  MultipleSearchComponent,
  MultipleSelectComponent,
  VehiclesEmptyComponent,
];
const directives = [TogglePasswordDirective, SelectActiveOptionDirective];
const providers = [FilesService, FormatBytesPipe];

@NgModule({
  declarations: [...components, ...directives],
  imports: [...modules],
  exports: [...modules, ...components, ...directives],
  providers: [...providers],
})
export class SharedModule {}
