<h1>Create New Password</h1>
<form [formGroup]="createPasswordForm" (ngSubmit)="createPassword()" class="login-form">
  <xos-error-box *ngIf="lastError.message" [errorMessage]="lastError"></xos-error-box>
  <mat-form-field appearance="fill" class="password-wrapper">
    <label for="password">Create new password</label>
    <input type="password" id="password" matInput placeholder="Enter new password" formControlName="password" xosTogglePassword />
    <xos-error-tooltip [errors]="f.password.errors" *ngIf="f.password?.touched && f.password?.errors"></xos-error-tooltip>
  </mat-form-field>
  <mat-form-field appearance="fill" class="password-wrapper">
    <label for="password">Confirm</label>
    <input
      [disabled]="loading"
      type="password"
      id="passwordConfirm"
      matInput
      placeholder="Confirm new password"
      formControlName="passwordConfirm"
      xosTogglePassword
    />
    <xos-error-tooltip
      [errors]="f.passwordConfirm.errors"
      *ngIf="f.passwordConfirm?.errors && f.passwordConfirm?.touched"
    ></xos-error-tooltip>
  </mat-form-field>
  <mat-form-field *ngIf="showAgreeTerms">
    <input hidden="true" matInput />
    <mat-checkbox class="xos-checkbox" [formControlName]="'agreeTerms'">
      By signing in you are accepting
      <a href="https://www.xostrucks.com/msa/" target="_blank" rel="noopener noreferrer">Terms of service</a></mat-checkbox
    >
  </mat-form-field>

  <button type="submit" [disabled]="loading || !createPasswordForm.valid" mat-flat-button color="primary" class="width-100">
    Create New Password
    <ng-container *ngIf="loading">
      <mat-spinner strokeWidth="1" diameter="18"></mat-spinner>
    </ng-container>
  </button>
</form>
