<ul class="header-menu">
  <li *ngIf="isFreePlan">
    <div
      class="upgrade-button"
      matTooltipPosition="left"
      matTooltipClass="xos-tooltip actions-tooltip"
      matTooltip="Click to unlock more features!"
      (click)="upgradeFreePlan()"
    >
      Upgrade
    </div>
  </li>
  <li *ngIf="isNotificationsAvailable">
    <a (click)="toggleNotificationCenter()">
      <i class="xos-icon icon-notification"></i>
    </a>
    <span *ngIf="notificationsCount" class="notification-count">{{ notificationsCount }}</span>
  </li>
  <li>
    <xos-user-icon
      *ngIf="currentUserInfo$ | async as userInfo"
      [matMenuTriggerFor]="userMenu"
      [userName]="userInfo.fullName"
      [avatarUrl]="userInfo?.profilePictureUrl || ''"
    ></xos-user-icon>
  </li>
  <mat-menu #userMenu="matMenu" backdropClass="user-menu">
    <button *ngIf="currentUserInfo$ | async as userInfo" mat-menu-item class="btn-with-additional-info">
      {{ userInfo.fullName }}
      <span class="additional-info">{{ userInfo.email }}</span>
    </button>
    <button mat-menu-item (click)="navigateToSettings()">Profile settings</button>
    <button mat-menu-item *ngIf="isFreePlan" (click)="upgradeFreePlan()">Upgrade Free plan</button>
    <button
      *ngIf="membershipService.checkPermissionAsync(PermissionType.OpenKnowledgeCenter) | async"
      mat-menu-item
      (click)="openKnowledgeCenter()"
    >
      Knowledge center
    </button>
    <button *ngIf="isGuideAvailable()" mat-menu-item (click)="openGuide()" class="start-guide">Start quick tutorial guide</button>
    <button mat-menu-item class="log-out" (click)="onClickLogout()">Sign out</button>
  </mat-menu>
</ul>
