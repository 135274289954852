import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Validators as CustomValidators } from '../../../shared/utils/validators';
import { Errors, ValidationMessages } from 'src/app/shared/models/validation-messages';
import { ErrorMessage, NoError } from 'src/app/shared/components/error-box/error-box.component';
import { FormatAWSError } from 'src/app/shared/utils/aws-errors';
import { AnalyticsService } from '../../services/analytics.service';
import { EMPTY, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { UserService } from '../../services/auth/user.service';

@Component({
  selector: 'xos-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['../login-form/login-form.component.scss', './forgot-password-form.component.scss'],
})
export class ForgotPasswordFormComponent implements OnInit, OnDestroy {
  forgotPasswordForm!: UntypedFormGroup;
  validationMessages = ValidationMessages;
  errors = Errors;
  lastError: ErrorMessage = NoError;
  private unsubscribe$: Subject<void> = new Subject();
  loading: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private auth: AuthService,
    private router: Router,
    private analytics: AnalyticsService,
    private route: ActivatedRoute,
    private userService: UserService,
  ) {}

  get f() {
    return this.forgotPasswordForm.controls;
  }

  ngOnInit(): void {
    this.createForm();
    this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe(params => this.forgotPasswordForm.patchValue({ ...params }));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  createForm(): void {
    this.forgotPasswordForm = this.fb.group({
      email: [null, [Validators.required, Validators.email, CustomValidators.removeSpaces]],
    });
  }

  forgotPassword(): void {
    if (this.forgotPasswordForm.invalid) {
      this.forgotPasswordForm.markAllAsTouched();
      return;
    }

    this.lastError = NoError;
    const { email } = this.forgotPasswordForm.value;

    this.loading = true;
    this.userService
      .resetOrphanPassword(email)
      .pipe(
        catchError((error: Error) => {
          console.error(error.message);
          return EMPTY;
        }),
      )
      .subscribe(result => {
        if (result) {
          this.analytics.trackEvent('Authentication.ForgotPassword');
          this.router.navigate(['/forgot-password-confirmation']);
        } else {
          this.loading = false;
          this.auth
            .forgotPassword(email)
            .then(() => {
              this.analytics.trackEvent('Authentication.ForgotPassword');
              this.router.navigate(['/forgot-password-confirmation']);
            })
            .catch(err => {
              err = FormatAWSError(err);
              this.lastError = err;
            });
        }
      });
  }

  onBlur() {
    this.forgotPasswordForm.controls.email.patchValue(this.forgotPasswordForm.value.email.trim());
  }
}
