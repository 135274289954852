import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getFileExtension',
})
export class GetFileExtensionPipe implements PipeTransform {
  transform(fileName: string): unknown {
    return fileName.split('.')[1];
  }
}
