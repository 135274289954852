import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AuthService } from '../../services/auth/auth.service';
import { UserService } from '../../services/auth/user.service';

@Injectable({ providedIn: 'root' })
export class LoginGuard implements CanActivate {
  constructor(private router: Router, private auth: AuthService, private userService: UserService) {}

  canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.getAuthenticatedUser(true).pipe(
      switchMap(cognitoUser =>
        !cognitoUser
          ? of(true)
          : this.userService.getCurrentUser().pipe(
              map(currentUser => {
                !(currentUser?.isMfaEnabled && cognitoUser.preferredMFA === 'NOMFA') && this.router.navigate(['/']);
                return currentUser?.isMfaEnabled && cognitoUser.preferredMFA === 'NOMFA';
              }),
            ),
      ),
    );
  }
}
