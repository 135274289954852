import { AssetType } from 'src/app/maintenance/models/tabs.enum';
import { WarrantyClaimStatus } from 'src/app/maintenance/models/warranty-claim-status.enum';

export enum NotificationType {
  ServiceRequest = 1,
  DealerRequest = 2,
  PMSRReminder = 3,
  WarrantyClaim = 4,
  ImpactReport = 5,
  WarrantyRegistration = 6,
  PeriodicReporting = 7,
  LowChargeEvent = 8,
}

export enum NotificationTypeSubtype {
  ServiceRequestScheduled = 1,
  ServiceRequestInProgress = 2,
  ServiceRequestCompleted = 3,
  ServiceRequestSubmitted = 4,
  ServiceRequestOnHold = 5,
  QuoteSubmitted = 10,
  QuoteCompleted = 11,
  QuotePendingCustomerApproval = 12,
  PMSRReminder = 20,
  WarrantyClaimStatusChanged = 30,
  ImpactReportReceived = 40,
  WarrantyRegistrationSubmitted = 50,
  UserPeriodicReports = 60,
  LowChargeEvent = 70,
}

export enum NotificationState {
  New = 0,
  Read = 1,
  Deleted = 2,
}

export const NotificationTypeStrings = new Map([
  [NotificationTypeSubtype.ServiceRequestScheduled, 'was scheduled'],
  [NotificationTypeSubtype.ServiceRequestInProgress, 'in progress'],
  [NotificationTypeSubtype.ServiceRequestCompleted, 'was successfully completed'],
  [NotificationTypeSubtype.ServiceRequestSubmitted, 'was submitted'],
  [NotificationTypeSubtype.ServiceRequestOnHold, 'was placed on-hold'],
  [NotificationTypeSubtype.QuoteSubmitted, 'was submitted'],
  [NotificationTypeSubtype.QuoteCompleted, 'is ready for sign off'],
  [NotificationTypeSubtype.QuotePendingCustomerApproval, 'is pending customer approval'],
  [NotificationTypeSubtype.WarrantyRegistrationSubmitted, 'was submitted'],
]);

export const WCStatusMessages = new Map([
  [WarrantyClaimStatus.NotStarted, 'was submitted'],
  [WarrantyClaimStatus.Scheduled, 'was submitted'],
  [WarrantyClaimStatus.OnHold, 'put on hold'],
  [WarrantyClaimStatus.InProgress, 'was escalated'],
  [WarrantyClaimStatus.Escalated, 'was escalated'],
  [WarrantyClaimStatus.ReOpened, 'was escalated'],
  [WarrantyClaimStatus.PendingWarrantyReview, 'was escalated'],
  [WarrantyClaimStatus.WarrantyApproved, 'was approved'],
  [WarrantyClaimStatus.WarrantyClaimInReview, 'is in review'],
  [WarrantyClaimStatus.WarrantyClaimReturned, 'was returned'],
  [WarrantyClaimStatus.WarrantyClaimAccepted, 'was accepted'],
  [WarrantyClaimStatus.WarrantyClaimDenied, 'was denied'],
  [WarrantyClaimStatus.Closed, 'was closed'],
]);

export interface NotificationDataWarrantyRegistration {
  displayId: number;
}

export interface NotificationDataLowCharge {
  id: number;
  nickName: string;
  vin: string;
}

export interface NotificationDataQuote {
  quoteId: number;
  quoteNumber: string;
  truckType: string;
  contactEmail: string;
}

export interface NotificationDataServiceRequest {
  assetType: AssetType;
  caseId: number;
  caseNumber: string;
  chargerId: number;
  chargerName: string;
  chargerShortName: string;
  chargerNickname: string;
  chargerSerialNumber: number;
  status: number;
  dateClosed: Date | string;
  vehicleId: number;
  vehicleNickName: string;
  vehicleVin: string;
  notes: string;
  summary: string;
  serviceDate: Date | string;
  serviceTime: null;
}

export enum NotificationReminderType {
  Time = 0,
  Miles = 1,
}

export interface NotificationDataReminder {
  vehicleId: number;
  vehicleNickName: string;
  vehicleVin: string;
  reminderType: NotificationReminderType;
  timeLimit: number;
  distanceLimit: number;
  timeRemaining: number;
  distanceRemaining: number;
  serviceIntervalMessage: string;
}

export interface WarrantyClaimNotificationData {
  id: number;
  caseNumber: string;
  vin: string;
  status: WarrantyClaimStatus;
  contactEmail: string;
}

export interface NotificationMessage {
  id: number;
  notificationType: NotificationType;
  notificationTypeSubtype: NotificationTypeSubtype;
  notificationState: NotificationState;
  data:
    | NotificationDataServiceRequest
    | NotificationDataQuote
    | NotificationDataReminder
    | WarrantyClaimNotificationData
    | NotificationDataImpactReport
    | NotificationDataWarrantyRegistration
    | NotificationDataLowCharge;
  created: string;
}

export interface NotificationDataImpactReport {
  userId: number;
  subject: string;
  customerName: string;
  monthCount: number;
  tcoSavings: number;
  distanceDriven: number;
  cO2Savings: number;
  timeInService: number;
}
