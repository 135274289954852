<div [ngClass]="{ 'miles-detailed': view === reportView.MilesDetailed }">
  <div (click)="openVehicles()">
    <div *ngIf="loading || (telematics && telematics.dataPoints.length === 0)">
      <div *ngIf="view === reportView.Detailed" class="base-title">CO<sub>2</sub> Savings</div>
      <div *ngIf="isFreePlan && view === reportView.Detailed" class="upgrade">
        <xos-free-plan-upgrade-label [theme]="'light'"></xos-free-plan-upgrade-label>
      </div>
      <div *ngIf="view !== reportView.Detailed" class="base-title">{{ getType() }}</div>
    </div>
    <div *ngIf="isFreePlan || (!loading && telematics && telematics.dataPoints.length !== 0)">
      <div *ngIf="view === reportView.Detailed" class="base-title">
        You've prevented {{ isFreePlan ? 'N/A' : (co2Savings | number) }} lbs of CO<sub>2</sub> from hurting our planet!
      </div>
      <div *ngIf="view !== reportView.Detailed" class="base-title checking">{{ getType() }}</div>
    </div>

    <div *ngIf="!isFreePlan && loading" class="loader-wrapper">
      <ngx-skeleton-loader count="1" [theme]="loaderStyles"></ngx-skeleton-loader>
    </div>

    <div class="{{ isChartVisible ? '' : 'invisible' }}">
      <div [ngClass]="{ 'reverse-info': view === reportView.DataType }">
        <div *ngIf="assetType !== existingAssetType.Charger && currentMode.mode !== dataTypes['SOC']" class="info-block">
          <h2>{{ isFreePlan ? 'N/A' : (totalMiles | number) }} {{ userInfo?.useMetricSystem ? 'km' : 'mi' }}</h2>
          <span class="subtitle">Distance driven</span>
        </div>
        <div *ngIf="view !== reportView.DataType || currentMode.mode === dataTypes['Emissions savings']" class="info-block">
          <h2>{{ isFreePlan ? 'N/A' : (co2Savings | number) }} lbs</h2>
          <span class="subtitle">Avoided emissions</span>
        </div>
        <div *ngIf="view === reportView.Detailed || currentMode.mode === dataTypes['Time in operation']" class="info-block">
          <h2>{{ isFreePlan ? 'N/A' : (timeInService | number) }} hours</h2>
          <span class="subtitle">Time in operation</span>
        </div>
        <div *ngIf="currentMode.mode === dataTypes['Energy'] && assetType === existingAssetType.Vehicle" class="info-block">
          <div class="info-block">
            <h2>{{ totalKwhConsumed | number }} kWh</h2>
            <div class="legend">
              <div class="legend-box soc-used"></div>
              <div class="subtitle">Energy Used</div>
            </div>
          </div>
          <div class="info-block">
            <h2>{{ totalKwhCharged | number }} kWh</h2>
            <div class="legend">
              <div class="legend-box soc-charged"></div>
              <div class="subtitle">Energy Charged</div>
            </div>
          </div>
        </div>
        <div
          *ngIf="view === reportView.Detailed || (currentMode.mode === dataTypes['TCO savings'] && assetType === existingAssetType.Vehicle)"
          class="info-block"
        >
          <h2>{{ isFreePlan ? 'N/A' : '$' + (tcoSavings | number) }}</h2>
          <span class="subtitle">Est. fuel savings</span>
        </div>
        <div *ngIf="view === reportView.DataType && assetType === existingAssetType.Charger" class="info-block">
          <h2>{{ totalPowerDispensed | number }} kWh</h2>
          <span class="subtitle">Energy usage per period</span>
        </div>
        <ng-container *ngIf="currentMode.mode === dataTypes['SOC'] && assetType === existingAssetType.Vehicle">
          <div class="info-block">
            <h2>{{ avgSocUsed | number: '1.0-0' }}%</h2>
            <div class="legend">
              <div class="legend-box soc-used"></div>
              <div class="subtitle">Average SOC Used</div>
            </div>
          </div>
          <div class="info-block">
            <h2>{{ avgSocCharged | number: '1.0-0' }}%</h2>
            <div class="legend">
              <div class="legend-box soc-charged"></div>
              <div class="subtitle">Average SOC Charged</div>
            </div>
          </div>
        </ng-container>
      </div>
      <div *ngIf="view !== reportView.MilesDetailed && !isNoData" class="chart-title">
        {{
          view === reportView.Detailed
            ? (userInfo?.useMetricSystem ? 'Kilometers' : 'Miles') + ' driven'
            : currentMode?.unit === 'Miles'
            ? userInfo?.useMetricSystem
              ? 'Kilometers'
              : 'Miles'
            : currentMode?.unit
        }}
      </div>

      <div *ngIf="!isFreePlan && !showNonElectricWarning" class="chart-wrapper">
        <div class="{{ isChartVisible ? '' : 'invisible' }}">
          <canvas
            [id]="'telematicsChart' + assetType + '_' + chartId"
            #telematicsChart
            [width]="chartWidth"
            [height]="chartHeight"
          ></canvas>
        </div>
      </div>
    </div>

    <div class="empty-state-container">
      <div *ngIf="isFreePlan" class="free-plan-state"></div>
      <div *ngIf="showNonElectricWarning" class="non-electric-state">
        <i class="xos-icon icon-upgrade-stars"></i>
        <h3>Electrify your fleet to unlock full potential of Xosphere!</h3>
        <p>
          Data for {{ dataTypes[currentMode.mode] }} is unavailable for selected vehicles. <br />
          Select electric vehicles to unveil electrification impact.
        </p>
        <a href="mailto:sales@xostrucks.com">Have non-electric vehicles? Contact our sales team to learn more about how to electrify.</a>
      </div>
      <div
        *ngIf="!showNonElectricWarning && (isNoData || (!isFreePlan && !loading && telematics && telematics.dataPoints.length === 0))"
        class="empty-state"
      >
        <h3>No data yet</h3>
        <span
          >Each {{ userInfo?.useMetricSystem ? 'kilometer' : 'mile' }} driven in an Xos vehicle prevents carbon emissions from hurting our
          planet.<br />We’re excited for you to make an impact!</span
        >
      </div>
    </div>
  </div>
</div>
