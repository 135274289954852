import { Component } from '@angular/core';

@Component({
  selector: 'xos-upgrade-banner',
  templateUrl: './upgrade-banner.component.html',
  styleUrls: ['./upgrade-banner.component.scss'],
})
export class UpgradeBannerComponent {
  constructor() {}
}
