import { Component, Input } from '@angular/core';

@Component({
  selector: 'xos-free-plan-upgrade-label',
  templateUrl: './free-plan-upgrade-label.component.html',
  styleUrls: ['./free-plan-upgrade-label.component.scss'],
})
export class FreePlanUpgradeLabelComponent {
  @Input() theme: 'dark' | 'light' = 'dark';
  constructor() {}
}
