import { BehaviorSubject, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ImpersonatedUser } from '../models/user.interface';
import { catchError } from 'rxjs/operators';

export interface AuthInfo {
  userName: string;
}

@Injectable({
  providedIn: 'root',
})
export class ImpersonationService {
  private impersonatedUser$: BehaviorSubject<ImpersonatedUser | null> = new BehaviorSubject<ImpersonatedUser | null>(null);

  constructor(private httpClient: HttpClient) {}

  getAuthInfo(userId: number): Observable<AuthInfo | null> {
    return this.httpClient.get<AuthInfo>(`${environment.apiUrl}/admin/users/${userId}/authInfo`).pipe(
      catchError((error: Error) => {
        console.error(error.message);
        return of(null);
      }),
    );
  }

  setImpersonatedUser(user: ImpersonatedUser | null): void {
    this.impersonatedUser$.next(user);
  }

  getCurrentImpersonatedUser(): ImpersonatedUser | null {
    return this.impersonatedUser$.value;
  }

  getImpersonatedUserObservable(): Observable<ImpersonatedUser | null> {
    return this.impersonatedUser$;
  }
}
