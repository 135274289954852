import { BehaviorSubject, Observable, of, from } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Auth } from 'aws-amplify';
import { HttpClient } from '@angular/common/http';
import { ImpersonationService } from 'src/app/admin/services/impersonation.service';
import { ImpersonatedUser } from 'src/app/admin/models/user.interface';
import { SmartBannerService } from '../smartbanner.service';

@Injectable()
export class AuthService {
  public loggedIn: BehaviorSubject<boolean>;
  public currentUser: any;
  mfaUser: any;
  constructor(
    private httpClient: HttpClient,
    private impersonationService: ImpersonationService,
    private smartBannerService: SmartBannerService,
  ) {
    const rememberMe = this.savedRememberMe;
    Auth.configure({ Auth: environment.amplify.Auth, storage: rememberMe ? localStorage : sessionStorage });
    this.loggedIn = new BehaviorSubject<boolean>(false);
  }

  public get lastUser(): boolean {
    const savedUser = localStorage.getItem('lastUser');
    return savedUser ? JSON.parse(savedUser) : '';
  }

  public get savedRememberMe(): boolean {
    const savedRememberMe = localStorage.getItem('rememberMe');
    return savedRememberMe ? JSON.parse(savedRememberMe) : false;
  }

  public async logIn(username: string, password: string, rememberMe: boolean, impersonatedUser?: ImpersonatedUser | null) {
    localStorage.setItem('rememberMe', JSON.stringify(rememberMe));

    Auth.configure({ storage: rememberMe ? localStorage : sessionStorage });
    const user = await Auth.signIn(username, password);
    if (user && impersonatedUser) {
      this.impersonationService.setImpersonatedUser({ ...impersonatedUser, authConfirmed: true });
    }
    localStorage.setItem('lastUser', JSON.stringify(username));
    this.currentUser = user;
    return user;
  }

  public getAccessToken(): Observable<string> {
    return from(Auth.currentSession()).pipe(switchMap(session => of(session.getAccessToken().getJwtToken())));
  }

  public async session() {
    return Auth.currentSession();
  }

  public async agreeToTerms() {
    return await this.httpClient.post<boolean>(`${environment.apiUrl}/auth/agree-to-terms`, null).toPromise();
  }

  public async logOut() {
    try {
      this.impersonationService.setImpersonatedUser(null);
      await this.httpClient.post(`${environment.apiUrl}/auth/signout`, null).toPromise();
    } catch (error) {
      console.warn('Error executing signout at api:', error);
    }

    try {
      await Auth.signOut();
      this.currentUser = null;
    } catch (error) {
      console.error('error signing out: ', error);
    }

    this.smartBannerService.hide();
  }

  public async forgotPassword(email: string) {
    return Auth.forgotPassword(email);
  }

  public async forgotPasswordSubmit(email: string, code: string, newPassword: string) {
    return Auth.forgotPasswordSubmit(email, code, newPassword).then(async _ => Auth.signIn(email, newPassword));
  }

  public async changePassword(oldPassword: string, newPassword: string): Promise<any> {
    try {
      return await Auth.currentAuthenticatedUser().then(user => Auth.changePassword(user, oldPassword, newPassword));
    } catch (error) {
      console.log('error creating password: ', error);
      return error;
    }
  }

  public async createNewPassword(newPassword: string) {
    try {
      if (this.currentUser) {
        return await Auth.completeNewPassword(this.currentUser, newPassword);
      }
    } catch (error) {
      console.log('error creating password: ', error);
    }
  }

  public getAuthenticatedUser(bypassCache?: boolean): Observable<any> {
    return from(this.getCurrentUser(bypassCache)).pipe(
      map(user => {
        this.mfaUser = user;
        this.loggedIn.next(true);
        return user;
      }),
      catchError(() => {
        this.loggedIn.next(false);
        return of(false);
      }),
    );
  }

  public async getCurrentUser(bypassCache?: boolean) {
    return Auth.currentAuthenticatedUser({ bypassCache: bypassCache || false });
  }
}
