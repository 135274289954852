import { Component, OnDestroy, OnInit } from '@angular/core';
import { ExternalService } from '../../services/external.service';
import { EMPTY, Subject, timer } from 'rxjs';
import { catchError, concatMap, takeUntil } from 'rxjs/operators';
import { WidgetData } from '../../models/overview-widget.interface';

@Component({
  selector: 'xos-external-widget',
  templateUrl: './external-widget.component.html',
  styleUrls: ['./external-widget.component.scss'],
})
export class ExternalWidgetComponent implements OnInit, OnDestroy {
  data!: WidgetData;
  fails: number = 0;
  private unsubscribe$: Subject<void> = new Subject();
  readonly interval: number = 15 * 60 * 1000; // 15 minutes
  readonly failAttempts: number = 20;

  constructor(private externalService: ExternalService) {}

  ngOnInit() {
    timer(0, this.interval)
      .pipe(
        concatMap(() => {
          return this.externalService.getWidgetData().pipe(
            catchError((error: Error) => {
              this.fails++;
              console.error(error.message);
              // stop polling after 20 attempts
              if (this.fails === this.failAttempts) {
                this.unsubscribe$.next();
              }
              return EMPTY;
            }),
          );
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(data => {
        this.fails = 0;
        this.data = data;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
