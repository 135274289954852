import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth/auth.service';
import { Errors, ValidationMessages } from 'src/app/shared/models/validation-messages';
import { ConfirmedValidator } from '../../../shared/utils/confirmed.validator';
import { PasswordStrengthValidator } from '../../../shared/utils/password-strength.validator';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorMessage, NoError } from 'src/app/shared/components/error-box/error-box.component';
import { KnowledgeCenterService } from 'src/app/knowledge-center/services/knowledge-center.service';
import { FormatAWSError } from 'src/app/shared/utils/aws-errors';
import { AnalyticsService } from '../../services/analytics.service';
import { PermissionType } from '../../models/permissions.enum';
import { MembershipService } from '../../services/membership/membership.service';

@Component({
  selector: 'xos-create-password-form',
  templateUrl: './create-password-form.component.html',
  styleUrls: ['./create-password-form.component.scss'],
})
export class CreatePasswordFormComponent implements OnInit {
  createPasswordForm!: UntypedFormGroup;
  validationMessages = ValidationMessages;
  errors = Errors;
  lastError: ErrorMessage = NoError;
  loading: boolean = false;
  showAgreeTerms: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private auth: AuthService,
    private knowledgeCenterService: KnowledgeCenterService,
    private router: Router,
    private route: ActivatedRoute,
    private analytics: AnalyticsService,
    private membershipService: MembershipService,
  ) {}

  get f() {
    return this.createPasswordForm.controls;
  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm(): void {
    this.route.queryParams.subscribe(params => {
      const username = params.username;
      const code = params.code;
      if (username === undefined && code === undefined) {
        this.createPasswordForm = this.fb.group(
          {
            password: [null, [Validators.required, PasswordStrengthValidator()]],
            passwordConfirm: [null, [Validators.required]],
            agreeTerms: [false, [Validators.requiredTrue]],
          },
          {
            validators: ConfirmedValidator('password', 'passwordConfirm'),
          },
        );
        this.showAgreeTerms = true;
      } else {
        this.createPasswordForm = this.fb.group(
          {
            password: [null, [Validators.required, PasswordStrengthValidator()]],
            passwordConfirm: [null, [Validators.required]],
            agreeTerms: [false],
          },
          {
            validators: ConfirmedValidator('password', 'passwordConfirm'),
          },
        );
      }
    });
  }

  createPassword(): void {
    if (this.createPasswordForm.invalid) {
      this.createPasswordForm.markAllAsTouched();
      return;
    }

    const { password, passwordConfirm, agreeTerms } = this.createPasswordForm.value;

    if (password !== passwordConfirm) {
      this.f.password.setErrors({ passwordValidationMatch: true });
    }

    this.route.queryParams.subscribe(params => {
      const username = params.username;
      const code = params.code;

      this.lastError = NoError;
      if (username === undefined && code === undefined) {
        // we should come there only for new password submit after account is created
        this.createNewPassword(password, agreeTerms);
      } else {
        if (username == null || username.trim() === '') {
          this.lastError = { message: 'Username cannot be empty' };
        } else if (code == null || code.trim() === '') {
          this.lastError = { message: 'Confirmation code cannot be empty' };
        } else {
          this.submitForgotPassword(username, code, password);
        }
      }
    });
  }

  private createNewPassword(password: string, agreeToTerms: boolean): void {
    if (!agreeToTerms) return;
    this.loading = true;
    this.analytics.trackEvent('Authentication.AgreedToTerms');
    this.auth
      .createNewPassword(password)
      .then(() => this.analytics.trackEvent('Authentication.CreateNewPassword'))
      .then(() => this.auth.agreeToTerms())
      .then(() => this.analytics.trackEvent('Authentication.AgreedToTerms'))
      .then(() => this.membershipService.reloadPermissions().subscribe(() => this.router.navigate(['/home'])))
      .catch(err => {
        err = FormatAWSError(err);
        this.lastError = err;
        this.loading = false;
      });
  }

  private submitForgotPassword(username: string, code: string, password: string): void {
    this.loading = true;
    this.auth
      .forgotPasswordSubmit(username, code, password)
      .then(() => {
        this.analytics.trackEvent('Authentication.ChangePassword');
        this.membershipService.reloadPermissions().subscribe(() => {
          this.membershipService
            .checkPermissionAsync(PermissionType.OpenKnowledgeCenterWebWidget)
            .subscribe(allowed => allowed && this.knowledgeCenterService.authenticateWidget());
          this.router.navigate(['/home']);
        });
      })
      .catch(err => {
        err = FormatAWSError(err);
        this.lastError = err;
        this.loading = false;
      });
  }
}
