import { isEqual, min, reject, sortBy, uniq } from 'lodash/fp';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ChangeContext } from '@angular-slider/ngx-slider';
import { RangeSlider } from '../../models/range-slider';
import { HourPrice, PriceType, UpdatedRange } from '../../../chargers/models/utility-policies.models';
import { TimezonesService } from '../../../core/services/timezones.service';
import { UtilityPolicyService } from '../../../settings/services/utility-policy.service';

@Component({
  selector: 'xos-peak-schedule',
  templateUrl: './peak-schedule.component.html',
  styleUrls: [
    '../../modules/charging-schedule/components/schedule-widget/schedule-widget.component.scss',
    './peak-schedule.component.scss',
  ],
})
export class PeakScheduleComponent implements OnChanges {
  @Input() ranges!: Array<number[]>;
  @Input() priceType: PriceType | any;
  @Input() hourPrices: HourPrice[] = [];
  @Output() rangeUpdate: EventEmitter<UpdatedRange> = new EventEmitter<UpdatedRange>();
  sliders: RangeSlider[] = [];
  priceTypes = PriceType;
  timeRanges: Array<{ value: number[]; range: string[] }> = [];
  emptyRanges: number[][] = [];
  manualRefresh: EventEmitter<void> = new EventEmitter<void>();
  disabledRanges: Array<number[]> = [];
  currentHourPrices: HourPrice[] = [];

  constructor(private timezonesService: TimezonesService, private utilityPolicyService: UtilityPolicyService) {}

  ngOnChanges(changes: SimpleChanges): void {
    changes.hourPrices?.currentValue?.length && this.setData();
    changes.priceType?.currentValue !== null && !changes.hourPrices?.currentValue?.length && this.setInitDate();
  }

  private setData(): void {
    this.currentHourPrices = [...this.hourPrices];
    const anotherPrices = this.hourPrices.filter(el => !(el.from === 0 && el.to === 23) && el.type !== this.priceType);
    this.disabledRanges = anotherPrices.length
      ? sortBy(
          item => item[1],
          anotherPrices.map(item => [item.from, item.to]),
        )
      : [];
    this.setExistingRanges();
    const hasEmptySpots = this.hourPrices.reduce((acc, cur) => cur.to - cur.from + 1 + acc, 0) < 24;
    hasEmptySpots && this.computeEmptyRanges();
    this.hourPrices = sortBy('to', [
      ...this.hourPrices,
      ...(hasEmptySpots ? this.emptyRanges.map(item => ({ from: item[0], to: item[1], type: this.priceType })) : []),
    ]);
    hasEmptySpots && this.addRange();
  }

  private setInitDate(): void {
    this.hourPrices = [{ from: 0, to: 23, type: this.priceType }];
    this.sliders = [new RangeSlider(0, 5)];
    const defaultRange = [[0, 5]];
    this.ranges = [...defaultRange];
    this.timeRanges = [
      {
        value: [0, 5],
        range: [this.timezonesService.getAMPM(0), this.timezonesService.getAMPM(5 + 1)],
      },
    ];
    this.computeEmptyRanges();
    this.rangeUpdate.emit({ ranges: this.ranges, type: this.priceType });
  }

  onSlideMove(e: ChangeContext, i: number): void {
    const { value, highValue = 0 } = e;
    const currentRangeIndex = this.ranges.findIndex(item => isEqual(item, this.timeRanges[i].value));
    this.ranges[currentRangeIndex] = [e.value, e.highValue as number];
    this.ranges = [...this.ranges];
    this.timeRanges[i] = {
      value: [value, highValue],
      range: [this.timezonesService.getAMPM(value), this.timezonesService.getAMPM(highValue + 1)],
    };
  }

  userChangeEnd(): void {
    this.computeEmptyRanges();
    this.rangeUpdate.emit({ ranges: this.ranges, type: this.priceType });
  }

  private computeEmptyRanges(): void {
    this.currentHourPrices = [
      ...this.ranges.map(item => ({ from: item[0], to: item[1], type: this.priceType })),
      ...this.hourPrices.filter(el => !(el.from === 0 && el.to === 23) && el.type !== this.priceType),
    ];
    this.emptyRanges = this.currentHourPrices ? this.utilityPolicyService.getFreeRanges(this.currentHourPrices) : [[0, 23]];
  }

  delete(value: number[], i: number): void {
    this.timeRanges = reject({ value }, this.timeRanges);
    this.sliders.splice(i, 1);
    this.disabledRanges = reject(item => isEqual(item, value), this.disabledRanges);
    this.ranges = reject(item => isEqual(item, value), this.ranges);
    this.currentHourPrices = reject({ from: value[0], to: value[1] }, this.currentHourPrices);
    this.computeEmptyRanges();
    this.rangeUpdate.emit({ ranges: this.ranges, type: this.priceType });
  }

  addRange(): void {
    this.emptyRanges = this.getAvailableIntervals();
    if (this.emptyRanges.length) {
      const firstEmptyRange = this.emptyRanges[0];
      if (firstEmptyRange[1] - firstEmptyRange[0] < 6) {
        const increment = firstEmptyRange[1] - firstEmptyRange[0] <= 5 ? 0 : 1;
        this.updateRange(firstEmptyRange[0], firstEmptyRange[1] - increment);
      } else {
        this.updateRange(firstEmptyRange[0], firstEmptyRange[0] + 5);
      }
      this.computeEmptyRanges();
      this.rangeUpdate.emit({ ranges: this.ranges, type: this.priceType });
    }
  }

  private updateRange(from: number, to: number): void {
    this.ranges = [...this.ranges, [from, to]].sort((a, b) => a[0] - b[0]);
    const maxLimit = min(this.disabledRanges.filter(item => item[0] >= to).map(item => item[0]));
    this.sliders = [...this.sliders, new RangeSlider(from, to, from, maxLimit ? maxLimit - 1 : 23)];
    this.timeRanges = [
      ...this.timeRanges,
      { value: [from, to], range: [this.timezonesService.getAMPM(from), this.timezonesService.getAMPM(to + 1)] },
    ];
  }

  private getAvailableIntervals() {
    this.disabledRanges = sortBy(item => item[1], uniq([...this.disabledRanges, ...this.ranges]));
    this.emptyRanges = this.emptyRanges.filter(el => !this.disabledRanges.find(item => isEqual(item, el)));
    const disabledIntervals = this.emptyRanges.reduce((ranges, e) => ranges.filter(d => d[0] >= e[0] && e[1] >= d[1]), this.disabledRanges);
    let pointsMatrix = [];
    let latestPoint = 0;
    let latestInterval: any[] = [];
    for (let i = 0; i <= 24; ++i) {
      let isEnabled = this.emptyRanges.some(interval => i >= interval[0] && i <= interval[1]);
      if (!isEnabled) continue;
      let isDisabled = disabledIntervals.some(interval => i >= interval[0] && i <= interval[1]);
      if (isDisabled) continue;

      if (i - latestPoint > 1 && latestInterval.length) {
        pointsMatrix.push(latestInterval);
        latestInterval = [i];
      } else {
        latestInterval.push(i);
      }
      latestPoint = i;
    }
    if (latestInterval.length) pointsMatrix.push(latestInterval);

    let result = [];
    for (let item of pointsMatrix) {
      result.push([item[0], item[item.length - 1]]);
    }
    return result;
  }

  private setExistingRanges(): void {
    const currentTypeRanges = this.hourPrices.filter(el => el.type === this.priceType);
    if (currentTypeRanges.length) {
      this.sliders = currentTypeRanges.map(range => new RangeSlider(range.from, range.to, range.from, range.to));
      this.timeRanges = currentTypeRanges.map(range => ({
        value: [range.from, range.to],
        range: [this.timezonesService.getAMPM(range.from), this.timezonesService.getAMPM(range.to + 1)],
      }));
    }
  }
}
