import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MfaView } from '../../models/mfa.enum';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'xos-mfa',
  templateUrl: './mfa.component.html',
  styleUrls: ['./mfa.component.scss'],
})
export class MfaComponent implements OnInit, OnChanges {
  @Input() qrCode: string = '';
  @Input() view: MfaView = MfaView.Start;
  @Input() wrongCode: boolean = false;
  @Output() passedCode: EventEmitter<number> = new EventEmitter<number>();
  views = MfaView;
  mfaForm!: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {}

  get f() {
    return this.mfaForm.controls;
  }

  ngOnInit() {
    this.mfaForm = this.fb.group({
      code: [null, [Validators.required]],
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.wrongCode?.currentValue) {
      this.f.code.setErrors({ wrongCode: true });
      this.f.code.markAsTouched();
    }
  }

  clicked() {
    if (this.view === MfaView.Start) {
      this.view = MfaView.Setup;
    } else {
      this.mfaForm?.valid && this.passedCode.emit(this.mfaForm?.value?.code);
    }
  }

  filterAllowedValues() {
    const allowedValue = this.mfaForm.value?.code.replace(/[^\d]/, '');
    this.mfaForm.patchValue({ code: allowedValue });
  }
}
