import { Vehicle } from 'src/app/maintenance/models/vehicle.interface';

export interface TelematicsInfo {
  charge: Indicator;
  energyEfficiency: Indicator;
  odo: Indicator;
  range: Indicator;
  dailyDistance: number;
  state: VehicleState;
  timeToCharge: number;
  co2Savings: number;
  gps: GPS;
  timeInService: Indicator;
}

export interface Indicator {
  value: string | number;
  timestamp: string;
}
export interface TelematicsVehicleInfo {
  id: number;
  vid: string;
  nickName: string;
  vin: string;
  telematics: TelematicsInfo;
  status?: VehicleStatus;
  vehicleTypeName: string;
  address?: string;
  telematicsClient?: TelematicsClient;
  isXosVehicle: boolean;
  isInService: boolean;
  isPowertrain: boolean;
  isElectric: boolean;
}

export interface VehicleStatus {
  id: number;
  name: string;
  class: string;
}

export enum VehicleState {
  Unknown = 0,
  Active = 1,
  Charging,
  LowCharge,
  ReadyToGo,
  InService,
  Inactive,
}

export enum HubState {
  Standby = 0,
  PowerUp = 1,
  NotReady,
  Precharge,
  Ready,
  InitCharge,
  ChargeIn,
  ChargeOut,
  ChargeInOut,
  PowerDown,
}

export enum ChargingStatus {
  Unknown = -1,
  Idle,
  Connected,
  Charging,
  Issue,
  Unavailable,
}

export enum TelematicsPeriod {
  All,
  Month,
  Year,
  Custom,
  CustomLong,
}

export enum ReportView {
  Detailed,
  DataType,
  MilesDetailed,
}

export interface TelematicsDataPoint {
  dateTime: Date | string;
  Odo?: number;
  TCOSavings?: number;
  CO2Savings?: number;
  SocUsed?: number;
  SocCharged?: number;
  EnergyCharged?: number;
  EnergyUsed?: number;
  power?: number;
}

export interface TelematicsData {
  co2Savings: number;
  tcoSavings: number;
  chargeTime: number;
  powerDispensed: number;
  timeInService: number;
  socUsedTotal: number;
  socChargedTotal: number;
  avgSocUsed: number;
  avgSocCharged: number;
  dataPoints: TelematicsDataPoint[];
}

export interface TotalMilesDrivenData {
  date: Date | string;
  data: {
    Odo: number;
    TCOSavings: number;
    CO2Savings: number;
  };
}

export interface GPS {
  latitude: number;
  longitude: number;
  timestamp: string;
}

export interface ExtendedVehicleInfo extends Vehicle {
  vehicleTypeName: string;
  makeAndModel: string;
  vehicleClass: string;
  curbWeight: string;
  wheelBase: string;
  gvwr: string;
  dimensions: string;
  deliveryDate: Date | null;
}

export interface VehicleVersionInfo {
  id: string;
  vid: string;
  vin: string;
  version: string;
}

export enum TelematicsClient {
  Sibros = 1,
  Viriciti,
  Geotab,
}

export enum DetailsTabs {
  Status,
  Charging,
  Service,
  Performance,
}
