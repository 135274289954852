<div class="error-wrapper">
  <div class="icon-wrapper">
    <i class="xos-icon icon-info"></i>
  </div>
  <div class="error-main">
    <h4>Impersonation mode!</h4>
    <div>
      You are signed in in impersonation mode as <strong>{{ user?.fullName }}</strong> by email <strong>{{ user?.email }}</strong
      >. To use your primary account, sign out and sign in again.
    </div>
  </div>
</div>
