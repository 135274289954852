import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth/auth.service';
import { Router } from '@angular/router';
import { Validators as CustomValidators } from '../../../shared/utils/validators';
import { Errors, ValidationMessages } from 'src/app/shared/models/validation-messages';

@Component({
  selector: 'xos-forgot-password-confirmation-form',
  templateUrl: './forgot-password-confirmation-form.component.html',
  styleUrls: ['./forgot-password-confirmation-form.component.scss'],
})
export class ForgotPasswordConfirmationFormComponent implements OnInit {
  forgotPasswordConfirmationForm!: UntypedFormGroup;
  validationMessages = ValidationMessages;
  errors = Errors;

  constructor(private fb: UntypedFormBuilder, private auth: AuthService, private router: Router) {}

  get f() {
    return this.forgotPasswordConfirmationForm.controls;
  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm(): void {
    this.forgotPasswordConfirmationForm = this.fb.group({
      email: [null, [Validators.required, Validators.email, CustomValidators.removeSpaces]],
    });
  }

  createPassword(): void {
    if (this.forgotPasswordConfirmationForm.invalid) {
      return;
    }

    this.router.navigate(['/home']);
  }
}
