import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

declare var smartbanner: any;

@Injectable({
  providedIn: 'root',
})
export class SmartBannerService {
  private isShown: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  show(): void {
    smartbanner.publish();
    this.isShown.next(true);
  }

  hide(): void {
    if (this.isShown.value) {
      smartbanner.hide();
      this.isShown.next(false);
    }
  }
}
