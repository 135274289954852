import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';

@Component({
  selector: 'xos-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {
  hideDetails: boolean = false;

  constructor(private cd: ChangeDetectorRef) {}

  mfaChanged() {
    this.hideDetails = true;
    this.cd.detectChanges();
  }
}
