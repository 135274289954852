import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { OptionalCategory } from '../../../products/models/optional-feature.interface';
import { Styles } from '../../models/loading-skeleton.interface';
import { STORAGE_TOKEN } from '../../services/storage-token';
import { StorageService } from '../../models/storage.interface';
import { Price } from '../../../products/models/basic-truck-configuration.interface';
import { MappedQuote } from '../../../products/models/quote.interface';
import { MappedOrder } from '../../../products/models/order.interface';

@Component({
  selector: 'xos-truck-configuration',
  templateUrl: './truck-configuration.component.html',
  styleUrls: ['./truck-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TruckConfigurationComponent {
  @Input() truck!: any;
  @Input() optionCategories!: OptionalCategory[];
  @Input() price!: Price;
  @Input() selected!: MappedQuote | MappedOrder | null;
  formStyles: Partial<Styles> = {
    height: '62px',
    'margin-bottom.px': 14,
    'background-color': '#F3F2F1',
    'border-radius.px': 2,
  };

  constructor(@Inject(STORAGE_TOKEN) private storage: StorageService) {}
}
