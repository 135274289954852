<div class="modal-content-wrapper">
  <header class="modal-header">
    <h1 class="modal-title">Warranty repair information</h1>
  </header>
  <section class="modal-body">
    <div class="main-content">
      <p>
        All claims must be submitted to Xos for all Covered Repairs for a vehicle within (30) days of completion of the Covered Repairs. Any
        Covered Repairs performed and for which a claim is not received within thirty (30) days of completion thereof will not be reimbursed
        to Customer by Company. Company shall review claims submitted by Customer and within thirty (30) calendar days of receipt of the
        same, shall provide Customer with its response to the claim.
      </p>
      <p>
        Prior to submission, please confirm compliance in all respects with the New Vehicle Limited Warranty Agreement. If approved, Xos
        will invoice using the information in this claim. In the event, Company does not approve a claim in full, Company shall provide
        Customer the reasons with its response to the claim.
      </p>
    </div>
    <div>
      <mat-checkbox class="xos-checkbox accept-checkbox" [(ngModel)]="data.checked">Accept Warranty Repair Agreement</mat-checkbox>
    </div>
    <p>
      By checking this box, I hereby declare that the information provided is true and correct to the best of my knowledge and belief and
      that I have complied with all the conditions of the Warranty Repair Agreement.
    </p>
    <p>Please type your name below to provide your e-signature:</p>
    <div class="xos-field-wrapper">
      <div class="xos-field-label">Name*</div>
      <mat-form-field floatLabel="never" appearance="fill" class="xos-input">
        <input type="text" matInput [(ngModel)]="data.name" placeholder="Enter your name" autocomplete="off" maxlength="128" />
      </mat-form-field>
    </div>
  </section>
  <footer class="modal-footer">
    <button mat-raised-button class="`modal-cancel-button`" (click)="close()">Back to Warranty claim form</button>
    <button
      [disabled]="!data.checked || !data.name"
      mat-raised-button
      class="modal-action-button"
      [ngClass]="data.type | lowercase"
      (click)="action()"
    >
      Submit claim
    </button>
  </footer>
</div>
