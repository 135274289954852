import { createAction, props } from '@ngrx/store';
import { OptionalFeature } from '../models/optional-feature.interface';
import { BasicTruckConfiguration } from '../models/basic-truck-configuration.interface';
import { VehicleType } from '../models/vehicle-type.interface';

export interface FeatureStateItem {
  typeId: number;
  optionalFeatures: ReadonlyArray<OptionalFeature>;
}

export interface BasicConfigStateItem {
  typeId: number;
  config: ReadonlyArray<BasicTruckConfiguration>;
}

export const retrievedFeatures = createAction('[Optional Features/API] Retrieve Features Success', props<FeatureStateItem>());

export const loadFeatures = createAction('[Optional Features/API] Load Features', props<{ typeId: number }>());

export const retrievedBasicConfig = createAction('[Basic Config/API] Retrieve Basic Config Success', props<BasicConfigStateItem>());

export const loadBasicConfig = createAction('[Basic Config/API] Load Basic Config', props<{ typeId: number }>());

export const loadVehicleTypes = createAction('[Vehicle Types/API] Load Vehicle Types');

export const retrievedVehicleTypes = createAction(
  '[Vehicle Types/API] Retrieve Vehicle Types Success',
  props<{ vehicleTypes: VehicleType[] }>(),
);
