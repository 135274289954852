import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'xos-warranty-claim-modal',
  templateUrl: './warranty-claim-modal.component.html',
  styleUrls: ['../basic-modal/basic-modal.component.scss', './warranty-claim-modal.component.scss'],
})
export class WarrantyClaimModalComponent {
  constructor(
    private dialogRef: MatDialogRef<WarrantyClaimModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: ''; checked: false; name: null; type: '' },
  ) {}

  action(): void {
    this.dialogRef.close(this.data);
  }

  close(): void {
    this.dialogRef.close();
  }
}
