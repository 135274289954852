import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';
import { EXTERNAL_API, EXTERNAL_API_WIDGET_ID } from '../../external/services/external.service';
import { ImpersonationService } from 'src/app/admin/services/impersonation.service';

const ANONYMOUS_API = [EXTERNAL_API, EXTERNAL_API_WIDGET_ID, '/Users/ResetOrphanPassword'];

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private impersonationService: ImpersonationService) {}

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    //handle your auth error or rethrow
    if (err.status === 401 || err.status === 403) {
      //navigate /delete cookies or whatever
      console.error('Auth error', err);
      //the error will be downstreamed
    }
    return throwError(err);
  }

  // @ts-ignore
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> | void {
    const isApiUrl = req.url.startsWith(environment.apiUrl);
    const isExternalCall = ANONYMOUS_API.some(apiUrl => req.url.search(apiUrl) > -1);
    if (!isApiUrl || isExternalCall) {
      return next.handle(req);
    }

    return this.authService.getAccessToken().pipe(
      switchMap(jwtToken => {
        const impersonatedUser = this.impersonationService.getCurrentImpersonatedUser();
        let authReq = null;
        if (impersonatedUser && impersonatedUser.sub) {
          const impersonatedUserId: string = impersonatedUser.sub;
          authReq = req.clone({ setHeaders: { Authorization: `Bearer ${jwtToken}`, 'x-impersonate': impersonatedUserId } });
        } else {
          authReq = req.clone({ setHeaders: { Authorization: `Bearer ${jwtToken}` } });
        }
        return next.handle(authReq).pipe(catchError(x => this.handleAuthError(x)));
      }),
    );
  }
}
