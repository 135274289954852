<div class="xos-field-label" *ngIf="title">
  <span>{{ title }}</span>
  <i *ngIf="icon" [matTooltip]="iconTooltip" matTooltipPosition="above" matTooltipClass="xos-tooltip" [class]="'xos-icon ' + icon"></i>
</div>
<mat-form-field
  appearance="fill"
  class="xos-datepicker"
  [ngClass]="{ 'date-selected': !!selectedDate || (startDate && endDate), open: isDatePickerOpened }"
>
  <mat-label>
    <ng-container *ngIf="!startDate">{{ label }}</ng-container>
  </mat-label>
  <ng-container *ngIf="type === 'range'; else default">
    <mat-date-range-input [rangePicker]="pickerRange" [max]="maxDate" [min]="minDate">
      <input matStartDate #dateRangeStart [(ngModel)]="startDate" (dateChange)="startDateChange()" readonly />
      <input matEndDate #dateRangeEnd [(ngModel)]="endDate" (dateChange)="datesChanged()" readonly />
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="pickerRange" *ngIf="!(startDate || endDate)"></mat-datepicker-toggle>
    <button *ngIf="startDate || endDate" mat-icon-button class="clear-suffix" (click)="clear([startDate, endDate], $event)">
      <i class="xos-icon icon-close-circle"></i>
    </button>
    <mat-date-range-picker
      [panelClass]="todayAllowed ? '' : 'today-not-allowed'"
      #pickerRange
      [dateClass]="dateClass"
      [calendarHeaderComponent]="datepickerHeaderComponent"
      (closed)="closed()"
    ></mat-date-range-picker>
  </ng-container>
  <ng-template #default>
    <input
      [disabled]="disabled"
      matInput
      [value]="selectedDate"
      [matDatepicker]="picker"
      [matDatepickerFilter]="validationFn"
      (dateChange)="dateChanged($event)"
    />
    <mat-datepicker-toggle *ngIf="!selectedDate && !disabled" matSuffix [for]="picker"></mat-datepicker-toggle>
    <button *ngIf="selectedDate && !disabled" matSuffix mat-icon-button class="clear-suffix" (click)="clear()">
      <i class="xos-icon icon-close-circle"></i>
    </button>
    <mat-datepicker
      #picker
      [dateClass]="dateClass"
      [panelClass]="todayAllowed ? '' : 'today-not-allowed'"
      [calendarHeaderComponent]="datepickerHeaderComponent"
    ></mat-datepicker>
  </ng-template>
</mat-form-field>
