import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'xos-basic-modal',
  templateUrl: './basic-modal.component.html',
  styleUrls: ['./basic-modal.component.scss'],
})
export class BasicModalComponent {
  constructor(private dialogRef: MatDialogRef<BasicModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  action(): void {
    this.dialogRef.close(this.data?.type);
  }

  close(): void {
    this.dialogRef.close();
  }
}
