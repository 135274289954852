import { NgModule } from '@angular/core';
import { LoginComponent } from './components/login/login.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { SharedModule } from '../shared/shared.module';
import { AuthService } from './services/auth/auth.service';
import { CreatePasswordComponent } from './components/create-password/create-password.component';
import { CreatePasswordFormComponent } from './components/create-password-form/create-password-form.component';
import { UserService } from './services/auth/user.service';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ForgotPasswordFormComponent } from './components/forgot-password-form/forgot-password-form.component';
import { ForgotPasswordConfirmationComponent } from './components/forgot-password-confirmation/forgot-password-confirmation.component';
import { ForgotPasswordConfirmationFormComponent } from './components/forgot-password-confirmation-form/forgot-password-confirmation-form.component';
import { TimezonesService } from './services/timezones.service';
import { STORAGE_TOKEN } from '../shared/services/storage-token';
import { SessionStorageService } from '../shared/services/session-storage.service';
import { MfaComponent } from './components/mfa/mfa.component';
import { QRCodeModule } from 'angularx-qrcode';

@NgModule({
  declarations: [
    LoginComponent,
    LoginFormComponent,
    CreatePasswordComponent,
    CreatePasswordFormComponent,
    ForgotPasswordComponent,
    ForgotPasswordFormComponent,
    ForgotPasswordConfirmationComponent,
    ForgotPasswordConfirmationFormComponent,
    MfaComponent,
  ],
  imports: [SharedModule, QRCodeModule],
  providers: [
    AuthService,
    UserService,
    TimezonesService,
    {
      provide: STORAGE_TOKEN,
      useClass: SessionStorageService,
    },
  ],
  exports: [],
})
export class CoreModule {}
