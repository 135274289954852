export enum Intervals {
  'All time',
  'Last month',
  'Last year',
  Custom,
}

export enum ReportIntervals {
  'All time',
  'Last 30 days',
  'Last year',
  'Previous month',
  'Previous quarter',
  'Previous year',
  Custom,
}

export enum VehicleDataTypes {
  'TCO savings' = 1,
  'Miles driven',
  'Emissions savings',
  'Time in operation',
  'SOC',
  'Energy',
}

export enum ChargerDataTypes {
  'Power dispensed' = 1,
}

export enum FiltersView {
  Full,
  DateButtons,
  DateOnly,
}
