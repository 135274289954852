import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { TelematicsService } from '../../../fleet/services/telematics.service';
import { environment } from '../../../../environments/environment';
import { GPS, TelematicsVehicleInfo, VehicleState, VehicleStatus } from '../../../fleet/models/telematics.interface';
import { Subject } from 'rxjs';
import * as mapboxgl from 'mapbox-gl';
import { takeUntil } from 'rxjs/operators';
import { VehicleStatuses } from '../../../fleet/models/vehicle-statuses.constant';
import { SidenavService } from '../../services/sidenav.service';
import { UserInfo, UserService } from '../../../core/services/auth/user.service';
import { Router } from '@angular/router';
import { MapMode } from '../../models/map-mode.enum';
import { AnalyticsService } from 'src/app/core/services/analytics.service';
import { round } from 'lodash';

export enum MarkerColor {
  MainBlack = '#070707',
  MainWhite = '#FFFFFF',
  Shadow = '#c4c4c4',
}

export enum VehicleCircleColor {
  Active = '#070707',
  LowCharge = '#F66565',
  Charging = '#88C66B',
  ReadyToGo = '#88C66B',
  Inactive = '#898989',
  InService = '#5DA8ED',
  Mixed = '#FFFFFF',
}

const UNKNOWN_VEHICLE_ID = -1;
const OPACITY_HALO = 0.3;
const OPACITY_MARKER = 0.8;
const MAX_ZOOM = 16;

@Component({
  selector: 'xos-vehicle-map',
  templateUrl: './vehicle-map.component.html',
  styleUrls: ['./vehicle-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleMapComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() zoom: boolean = true;
  @Input() mode: MapMode = MapMode.Default;
  @Input() navigationControls: boolean = true;
  @Input() userInfo!: UserInfo | null;
  @Input() isFreePlan: boolean = false;
  @Input() isMapView: boolean = true;
  vehicles!: TelematicsVehicleInfo[];
  private selectedVehicleId: number = UNKNOWN_VEHICLE_ID;
  private filteredVehicleIds: number[] = [];
  private unsubscribe$: Subject<void> = new Subject();
  private map!: mapboxgl.Map;
  private searchValue!: string;
  private initialStatus: VehicleStatus = { id: 0, name: 'Fleet Status', class: '' };
  private selectedStatus: VehicleStatus = this.initialStatus;
  private firstUpdate: boolean = true;
  // objects for caching and keeping track of HTML marker objects (for performance)
  private markersOnScreen: any = {};

  @HostListener('click') onMapClick() {
    if (this.mode === MapMode.Widget) {
      this.analytics.trackEvent('Overview.OpenVehiclesMap');
      this.router.navigate(['/fleet']);
    }
  }

  constructor(
    private telematicsService: TelematicsService,
    private sideNavService: SidenavService,
    private userService: UserService,
    private router: Router,
    private analytics: AnalyticsService,
  ) {}

  ngOnInit(): void {
    this.subscribeToVehicleSelect();
    this.subscribeToVehiclesChanges();
    this.subscribeToVehicleFilterByValue();
    this.subscribeToVehicleFilterByStatus();
    this.firstUpdate = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.isMapView?.currentValue && this.map) {
      this.map.resize();
      this.boundMap();
    }
  }

  ngAfterViewInit(): void {
    Promise.resolve().then(() => {
      this.buildMap();
      this.setParams();
      this.resizeOnRender();
      this.subscribeToSideNavState();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.map.remove();
  }

  getCircleColor(state: VehicleState): VehicleCircleColor {
    switch (state) {
      case VehicleState.Unknown:
        return VehicleCircleColor.Inactive;
      case VehicleState.Active:
        return VehicleCircleColor.Active;
      case VehicleState.Charging:
        return VehicleCircleColor.Charging;
      case VehicleState.InService:
        return VehicleCircleColor.InService;
      case VehicleState.Inactive:
        return VehicleCircleColor.Inactive;
      case VehicleState.LowCharge:
        return VehicleCircleColor.LowCharge;
      case VehicleState.ReadyToGo:
        return VehicleCircleColor.ReadyToGo;
      default:
        return VehicleCircleColor.Mixed;
    }
  }

  private buildVehicleFeatures(vehicles: TelematicsVehicleInfo[]): GeoJSON.FeatureCollection<GeoJSON.Geometry> {
    return {
      type: 'FeatureCollection',
      features: vehicles.map(v => ({
        type: 'Feature',
        id: v.id,
        properties: {
          cluster: false,
          id: v.id,
          title: v.nickName,
          state: v.telematics.state,
          circleColor: this.getCircleColor(v.telematics.state),
          name: this.userService.CurrentSettings.useVehicleName ? v?.nickName || v.vin : v.vin,
          range: round(Number(v.telematics.range?.value), 1) || 'N/A',
          charge: v.telematics?.charge?.value != null || '',
          soc: `${v.telematics?.charge?.value != null ? v.telematics?.charge?.value + '%' : ''}`,
          category: 'test',
        },
        geometry: { type: 'Point', coordinates: [v.telematics?.gps?.longitude, v.telematics?.gps?.latitude] },
      })),
    };
  }

  private buildVehiclesGeoJson(vehicles: TelematicsVehicleInfo[]): any {
    const stateUnknown = ['==', ['get', 'state'], VehicleState.Unknown];
    const stateActive = ['==', ['get', 'state'], VehicleState.Active];
    const stateCharging = ['==', ['get', 'state'], VehicleState.Charging];
    const stateLowCharge = ['==', ['get', 'state'], VehicleState.LowCharge];
    const stateReadyToGo = ['==', ['get', 'state'], VehicleState.ReadyToGo];
    const stateInService = ['==', ['get', 'state'], VehicleState.InService];
    const stateInactive = ['==', ['get', 'state'], VehicleState.Inactive];

    return {
      type: 'geojson',
      data: this.buildVehicleFeatures(vehicles),
      cluster: true,
      clusterRadius: 40,
      clusterMaxZoom: MAX_ZOOM - 1,
      clusterProperties: {
        // keep separate counts for each vehicle status in a cluster
        countUnknown: ['+', ['case', stateUnknown, 1, 0]],
        countActive: ['+', ['case', stateActive, 1, 0]],
        countCharging: ['+', ['case', stateCharging, 1, 0]],
        countLowCharge: ['+', ['case', stateLowCharge, 1, 0]],
        countReadyToGo: ['+', ['case', stateReadyToGo, 1, 0]],
        countInService: ['+', ['case', stateInService, 1, 0]],
        countInactive: ['+', ['case', stateInactive, 1, 0]],
      },
    };
  }

  private buildClustersLayer(): mapboxgl.AnyLayer {
    // this layer is invisible but it is there to handle cluster expansion events
    return {
      id: 'clusters',
      type: 'circle',
      source: 'vehicles',
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': [
          'case',
          ['==', ['get', 'point_count'], ['get', 'countUnknown']],
          VehicleCircleColor.Inactive,
          ['==', ['get', 'point_count'], ['get', 'countActive']],
          VehicleCircleColor.Active,
          ['==', ['get', 'point_count'], ['get', 'countCharging']],
          VehicleCircleColor.Charging,
          ['==', ['get', 'point_count'], ['get', 'countLowCharge']],
          VehicleCircleColor.LowCharge,
          ['==', ['get', 'point_count'], ['get', 'countReadyToGo']],
          VehicleCircleColor.ReadyToGo,
          ['==', ['get', 'point_count'], ['get', 'countInService']],
          VehicleCircleColor.InService,
          ['==', ['get', 'point_count'], ['get', 'countInactive']],
          VehicleCircleColor.Inactive,
          VehicleCircleColor.Mixed,
        ],
        'circle-radius': ['step', ['get', 'point_count'], 22, 10, 26, 100, 32],
        'circle-opacity': 0,
      },
    };
  }

  private buildVehicleCircleSelectedLayer(): mapboxgl.AnyLayer {
    return {
      id: 'vehicle-circle-selected',
      type: 'circle',
      source: 'vehicles',
      filter: ['!has', 'point_count'],
      paint: {
        'circle-opacity': ['case', ['boolean', ['feature-state', 'select'], false], OPACITY_HALO, 0],
        'circle-color': ['get', 'circleColor'],
        'circle-radius': 28,
      },
    };
  }

  private buildVehicleShadowLayer(): mapboxgl.AnyLayer {
    return {
      id: 'vehicle-circle-shadow',
      type: 'circle',
      source: 'vehicles',
      filter: ['all', ['!has', 'point_count']],
      paint: {
        'circle-color': ['case', ['boolean', ['feature-state', 'hover'], false], MarkerColor.MainWhite, ['get', 'circleColor']],
        'circle-stroke-color': MarkerColor.Shadow,
        'circle-stroke-width': 1,
        'circle-radius': 18,
        'circle-opacity': 0.2,
        'circle-blur': 0.4,
      },
    };
  }

  private buildVehicleCircleLayer(): mapboxgl.AnyLayer {
    return {
      id: 'vehicle-circle',
      type: 'circle',
      source: 'vehicles',
      filter: ['all', ['!has', 'point_count']],
      paint: {
        'circle-color': ['case', ['boolean', ['feature-state', 'hover'], false], MarkerColor.MainWhite, ['get', 'circleColor']],
        'circle-stroke-color': MarkerColor.MainWhite,
        'circle-stroke-width': 0.5,
        'circle-radius': 16,
        'circle-opacity': OPACITY_MARKER,
      },
    };
  }

  private buildSocRectShadowLayer(): mapboxgl.AnyLayer {
    return {
      id: 'soc-rect-shadow',
      type: 'symbol',
      source: 'vehicles',
      filter: ['all', ['!has', 'point_count'], ['!=', 'soc', '']],
      layout: {
        'icon-image': 'soc-rect-outline',
        'icon-anchor': 'bottom',
        'icon-offset': [0, -9],
        'icon-allow-overlap': true,
        'text-allow-overlap': true,
      },
      paint: {
        'icon-color': MarkerColor.MainWhite,
        'icon-opacity': this.isFreePlan ? 0 : ['case', ['boolean', ['feature-state', 'select'], false], 0, 0.2],
        'icon-halo-color': MarkerColor.Shadow,
        'icon-halo-blur': 0.4,
        'icon-halo-width': 2,
      },
    };
  }
  private buildSocRectOutlineLayer(): mapboxgl.AnyLayer {
    return {
      id: 'soc-rect-outline',
      type: 'symbol',
      source: 'vehicles',
      filter: ['all', ['!has', 'point_count'], ['!=', 'soc', '']],
      layout: {
        'icon-image': 'soc-rect-outline',
        'icon-anchor': 'bottom',
        'icon-offset': [0, -9],
        'icon-allow-overlap': true,
        'text-allow-overlap': true,
      },
      paint: {
        'icon-color': MarkerColor.MainWhite,
        'icon-opacity': this.isFreePlan ? 0 : ['case', ['boolean', ['feature-state', 'select'], false], 0, OPACITY_MARKER],
        'icon-halo-color': MarkerColor.MainWhite,
        'icon-halo-blur': 1,
        'icon-halo-width': 3,
      },
    };
  }

  private buildSocRectLayer(): mapboxgl.AnyLayer {
    return {
      id: 'soc-rect',
      type: 'symbol',
      source: 'vehicles',
      filter: ['all', ['!has', 'point_count'], ['!=', 'soc', '']],
      layout: {
        'icon-image': 'soc-rect',
        'icon-anchor': 'bottom',
        'icon-offset': [0, -9],
        'icon-allow-overlap': true,
      },
      paint: {
        'icon-color': ['get', 'circleColor'],
        'icon-opacity': this.isFreePlan ? 0 : ['case', ['boolean', ['feature-state', 'select'], false], 0, OPACITY_MARKER],
      },
    };
  }

  private buildVehicleIconLayer(): mapboxgl.AnyLayer {
    return {
      id: 'vehicle-icon',
      type: 'symbol',
      source: 'vehicles',
      filter: ['!has', 'point_count'],
      layout: {
        'text-font': ['Montserrat Medium'],
        'icon-image': 'vehicle-white',
        'icon-anchor': 'center',
        'icon-size': 1,
        'text-field': ['get', 'soc'],
        'text-offset': [0, -1.8],
        'text-anchor': 'bottom',
        'text-size': 10,
        'text-padding': 5,
        'icon-allow-overlap': true,
        'text-allow-overlap': true,
      },
      paint: {
        'text-color': MarkerColor.MainWhite,
        'text-halo-color': ['get', 'circleColor'],
        'icon-opacity': OPACITY_MARKER,
        'icon-color': ['case', ['boolean', ['feature-state', 'hover'], false], ['get', 'circleColor'], MarkerColor.MainWhite],
        'text-opacity': ['case', ['boolean', ['feature-state', 'select'], false], 0, 1],
      },
    };
  }

  private buildReadyToGoIconLayer(): mapboxgl.AnyLayer {
    return {
      id: 'ready-to-go',
      type: 'symbol',
      source: 'vehicles',
      filter: ['all', ['!has', 'point_count'], ['==', 'state', VehicleState.ReadyToGo]],
      layout: {
        'icon-image': 'ready-to-go',
        'icon-anchor': 'center',
        'icon-offset': [10, -10],
        'icon-size': 0.5,
        'icon-allow-overlap': true,
      },
      paint: {
        'icon-opacity': OPACITY_MARKER,
      },
    };
  }

  private registerMapEvents() {
    this.map.on('mouseover', 'vehicle-circle', event => {
      if (!!event && !!event.features && event.features?.length > 0 && !!event.features[0].properties) {
        this.hoverVehicle(event.features[0].properties.id);
      } else {
        this.hoverVehicle();
      }
    });

    this.map.on('mouseout', 'vehicle-circle', _ => {
      this.hoverVehicle();
    });

    this.map.on('click', event => {
      const features = this.map.queryRenderedFeatures(event.point, { layers: ['vehicle-circle'] });
      if (features && features.length > 0 && features[0].properties) {
        const vehicleId = features[0].properties.id;
        this.selectVehicle(vehicleId);
      } else {
        this.selectVehicle();
      }
    });

    // Create a popup, but don't add it to the map yet.
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      className: 'hover-tooltip',
    });

    this.map.on('mouseenter', 'vehicle-circle', event => {
      if (this.isFreePlan || !event || !event.features || event.features.length < 1 || !event.features[0].properties) return;
      this.map.getCanvas().style.cursor = 'pointer';
      // Copy coordinates array.
      if (event.features[0].geometry.type != 'Point') return;
      const coordinates = event.features[0].geometry.coordinates;
      const props = event.features[0].properties;

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(event.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += event.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      // Populate the popup and set its coordinates
      // based on the feature found.
      this.telematicsService
        .getVehicleLocationAddress(coordinates[1], coordinates[0])
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(address => {
          const popupHTML = `<p>${props.name}</p><span><span class='vehicle-status-text'>${
            VehicleStatuses.find(item => item.id === props.state)?.name
          }</span> • <span class='vehicle-range-charge-tooltip-text'>${props.range} ${this.userInfo?.useMetricSystem ? 'km' : 'mi'}${
            props.soc != '' ? ' • ' + props.soc : ''
          }</span></span><span class='address'>${address}</span>`;
          popup.setLngLat([coordinates[0], coordinates[1]]).setHTML(popupHTML).addTo(this.map);
        });
    });

    this.map.on('mouseleave', 'vehicle-circle', () => {
      this.map.getCanvas().style.cursor = '';
      popup.remove();
    });

    // inspect a cluster on click
    this.map.on('click', 'clusters', e => {
      this.expandClusterAtLocation(e.point);
    });

    this.map.on('zoomend', () => {
      if (!this.map.isSourceLoaded('vehicles')) return;
      this.updateMarkers();
    });

    this.map.on('data', () => {
      if (!this.map.isSourceLoaded('vehicles')) return;
      this.updateMarkers();
    });
  }

  private expandClusterAtLocation(point: mapboxgl.Point) {
    const features = this.map.queryRenderedFeatures(point, {
      layers: ['clusters'],
    });
    const clusterId = features[0].properties?.cluster_id;
    this.getSource().getClusterExpansionZoom(clusterId, (err, zoom) => {
      if (err) return;

      if (features[0].geometry.type == 'Point') {
        this.map.easeTo({
          center: [features[0].geometry.coordinates[0], features[0].geometry.coordinates[1]],
          zoom: zoom,
        });
      }
    });
  }

  private addMapSource() {
    this.map.addSource('vehicles', this.buildVehiclesGeoJson(this.vehicles));
  }

  private getSource(): mapboxgl.GeoJSONSource {
    return this.map.getSource('vehicles') as mapboxgl.GeoJSONSource;
  }

  private updateMapSource() {
    const filteredVehicles = this.vehicles.filter(v => !!this.filteredVehicleIds.find(f => f === v.id));
    const source = this.getSource();
    if (source) {
      source.setData(this.buildVehicleFeatures(filteredVehicles));
    }
  }

  private buildMapLayers(): void {
    this.map.addLayer(this.buildClustersLayer());
    this.map.addLayer(this.buildVehicleShadowLayer());
    this.map.addLayer(this.buildVehicleCircleSelectedLayer());
    this.map.addLayer(this.buildVehicleCircleLayer());
    this.map.addLayer(this.buildSocRectShadowLayer());
    this.map.addLayer(this.buildSocRectOutlineLayer());
    this.map.addLayer(this.buildSocRectLayer());
    this.map.addLayer(this.buildVehicleIconLayer());
    this.map.addLayer(this.buildReadyToGoIconLayer());
  }

  clearFilter() {
    this.setFilter(this.vehicles.map(v => v.id));
  }

  setFilter(vehicleIds: number[]) {
    this.filteredVehicleIds = vehicleIds;
    this.updateMapSource();
  }

  updateSelectedVehicle(vehicleId: number = UNKNOWN_VEHICLE_ID) {
    this.selectedVehicleId = vehicleId;
    this.vehicles.forEach(v => this.map.setFeatureState({ source: 'vehicles', id: v.id }, { select: v.id === vehicleId ? true : false }));
  }

  selectVehicle(vehicleId: number = UNKNOWN_VEHICLE_ID) {
    this.analytics.trackEvent('OpenVehicleDetails', { source: 'Map' });
    const info = this.vehicles.find(v => v.id === vehicleId);
    this.telematicsService.selectVehicle({ target: info ?? null });
    this.router.navigate(['/fleet'], { queryParams: { id: info?.id } });
    if (!info) this.sideNavService.toggleVehicleSideNav(false);
  }

  hoverVehicle(vehicleId: number = UNKNOWN_VEHICLE_ID) {
    this.vehicles.forEach(v => this.map.setFeatureState({ source: 'vehicles', id: v.id }, { hover: v.id === vehicleId ? true : false }));
  }

  subscribeToVehiclesChanges(): void {
    let coordinates: GPS;
    this.telematicsService.vehicles$.pipe(takeUntil(this.unsubscribe$)).subscribe(({ vehicles }) => {
      this.vehicles = vehicles;
      if (this.firstUpdate) {
        this.addMapSource();
        this.buildMapLayers();
        this.registerMapEvents();
        this.clearFilter();
        this.boundMap();
        this.firstUpdate = false;
      } else {
        this.updateMapSource();

        if (this.selectedVehicleId !== UNKNOWN_VEHICLE_ID) {
          const vehicleInfo = vehicles.find(v => v.id === this.selectedVehicleId);
          if (!!vehicleInfo) {
            const { longitude: lng, latitude: lat } = vehicleInfo.telematics.gps;
            if (coordinates?.longitude !== lng || coordinates?.latitude !== lat) {
              coordinates = { ...vehicleInfo.telematics.gps };
              this.flyToSelectedVehicle(coordinates);
            }
          }
        }
      }
    });
  }

  subscribeToVehicleSelect(): void {
    this.telematicsService
      .getSelectedVehicleObservable$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(({ target, center }) => {
        const vehicleId = target?.id ?? UNKNOWN_VEHICLE_ID;
        this.updateSelectedVehicle(vehicleId);
        if (center && target) {
          this.flyToSelectedVehicle(target.telematics.gps);
        }
      });
  }

  subscribeToVehicleFilterByValue(): void {
    this.telematicsService
      .getFilterByValueObservable$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: string) => {
        this.searchValue = value;
        let markersToShow = this.selectedStatus?.id
          ? this.vehicles.filter(v => v.telematics.state === this.selectedStatus.id)
          : this.vehicles;
        markersToShow = this.telematicsService.filterVehicles<TelematicsVehicleInfo>(markersToShow, value);
        this.setFilter(markersToShow.map(m => m.id));
        this.selectVehicle();
        this.boundMap();
      });
  }

  subscribeToVehicleFilterByStatus(): void {
    this.telematicsService
      .getFilterByStatusObservable$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((status: VehicleStatus) => {
        this.selectedStatus = status;
        let markersToShow = status.id ? this.vehicles.filter(v => v.telematics.state === status.id) : this.vehicles;
        markersToShow = this.telematicsService.filterVehicles<TelematicsVehicleInfo>(markersToShow, this.searchValue ?? '');
        this.setFilter(markersToShow.map(m => m.id));
        this.selectVehicle();
        this.boundMap();
      });
  }

  subscribeToSideNavState(): void {
    this.sideNavService
      .getIsOpenedMainSideNavObservable()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        setTimeout(() => this.map.resize(), 300);
      });
  }

  boundMap(): void {
    let bounds = new mapboxgl.LngLatBounds();

    this.vehicles.forEach(v => {
      if (this.filteredVehicleIds.find(f => f == v.id)) {
        v.telematics?.gps?.longitude &&
          v.telematics?.gps?.latitude &&
          bounds.extend(new mapboxgl.LngLat(v.telematics.gps.longitude, v.telematics.gps.latitude));
      }
    });
    this.boundToMarkers(bounds);
    if (Object.keys(bounds).length) {
      this.map.fitBounds(bounds, { padding: 50 });
    }
  }

  private loadImagesToMap() {
    this.map.loadImage('assets/images/vehicle-white.png', (error, image) => {
      if (error) throw error;
      else if (image) this.map.addImage('vehicle-white', image, { sdf: true });
    });

    this.map.loadImage('assets/images/ready-to-go.png', (error, image) => {
      if (error) throw error;
      else if (image) this.map.addImage('ready-to-go', image);
    });

    this.map.loadImage('assets/images/soc-rect.png', (error, image) => {
      if (error) throw error;
      else if (image) {
        this.map.addImage('soc-rect-outline', image, { sdf: true });
        this.map.addImage('soc-rect', image, { sdf: true });
      }
    });
  }

  buildMap(): void {
    // default map position to Xos headquaters location
    const DEFAULT_MAP_LAT = 34.119407608937;
    const DEFAULT_MAP_LNG = -118.25252788203515;
    const DEFAULT_ZOOM = 8;
    const style = 'mapbox://styles/xos/ckybci3z584jl14nuaefvkj1l';

    this.map = new mapboxgl.Map({
      accessToken: environment.mapbox.accessToken,
      container: 'map',
      style: style,
      zoom: DEFAULT_ZOOM,
      center: [DEFAULT_MAP_LNG, DEFAULT_MAP_LAT],
      attributionControl: false,
    });

    this.loadImagesToMap();

    if (this.navigationControls) {
      this.map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
    }

    this.map.addControl(new mapboxgl.AttributionControl(), 'bottom-left');
  }

  private updateMarkers() {
    const newMarkers: any = {};
    const features = this.map.querySourceFeatures('vehicles', { sourceLayer: 'clusters', filter: ['has', 'point_count'] });

    // remove old markers
    for (const id in this.markersOnScreen) {
      if (this.markersOnScreen[id]) {
        this.markersOnScreen[id].remove();
        delete this.markersOnScreen[id];
      }
    }

    for (const feature of features) {
      const coords = feature.geometry.type === 'Point' ? feature.geometry.coordinates : null;
      if (!!coords) {
        const props = feature.properties;
        if (!props?.cluster) continue;
        const id = props.cluster_id;
        let marker = newMarkers[id];
        if (!marker) {
          const el = this.createDonutChart(props);
          const marker = (newMarkers[id] = new mapboxgl.Marker(el as HTMLElement).setLngLat({ lon: coords[0], lat: coords[1] }));
          marker.addTo(this.map);
        }
      }
    }

    this.markersOnScreen = newMarkers;
  }

  // code for creating an SVG donut chart from feature properties
  private createDonutChart(props: any) {
    const colors = ['#898989', '#070707', '#F66565', '#88C66B', '#88C66B', '#898989', '#5DA8ED'];
    const offsets = [];
    const counts = [
      props.countUnknown,
      props.countActive,
      props.countLowCharge,
      props.countCharging,
      props.countReadyToGo,
      props.countInactive,
      props.countInService,
    ];
    let total = 0;
    for (const count of counts) {
      offsets.push(total);
      total += count;
    }
    const fontSize = 14;
    const r = total >= 100 ? 32 : total >= 10 ? 26 : 22;
    const r0 = Math.round(r * 0.6);
    const w = r * 2;

    let html = `<div><svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="font: ${fontSize}px Montserrat, font-weight: 500, display: block">`;

    for (let i = 0; i < counts.length; i++) {
      html += this.donutSegment(offsets[i] / total, (offsets[i] + counts[i]) / total, r, r0, colors[i]);
    }
    html += `<circle cx="${r}" cy="${r}" r="${r0}" fill="white" />  <text dominant-baseline="central" transform="translate(${r}, ${r})">${total.toLocaleString()}</text></svg></div>`;
    const el = document.createElement('div');
    el.innerHTML = html;
    return el.firstChild;
  }

  donutSegment(start: number, end: number, r: number, r0: number, color: string) {
    if (end - start === 1) end -= 0.00001;
    const a0 = 2 * Math.PI * (start - 0.25);
    const a1 = 2 * Math.PI * (end - 0.25);
    const x0 = Math.cos(a0),
      y0 = Math.sin(a0);
    const x1 = Math.cos(a1),
      y1 = Math.sin(a1);
    const largeArc = end - start > 0.5 ? 1 : 0;

    // draw an SVG path
    return `<path d="M ${r + r0 * x0} ${r + r0 * y0} L ${r + r * x0} ${r + r * y0} A ${r} ${r} 0 ${largeArc} 1 ${r + r * x1} ${
      r + r * y1
    } L ${r + r0 * x1} ${r + r0 * y1} A ${r0} ${r0} 0 ${largeArc} 0 ${r + r0 * x0} ${r + r0 * y0}" fill="${color}" />`;
  }

  resizeOnRender(): void {
    // this method needs to fit map in 100% of parent block, otherwise width will be not correct
    this.map.once('render', _e => {
      this.map.resize();
    });
  }

  setParams(): void {
    if (!this.zoom) this.map.scrollZoom.disable();
  }

  flyToSelectedVehicle({ longitude, latitude }: GPS): void {
    this.map.flyTo({
      center: { lng: longitude, lat: latitude },
      zoom: MAX_ZOOM,
    });
  }

  boundToMarkers(bounds: mapboxgl.LngLatBounds): void {
    if (Object.keys(bounds).length) {
      this.map.fitBounds(bounds, { padding: 50, maxZoom: MAX_ZOOM });
    }
  }
}
