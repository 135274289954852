import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'xos-multiple-select',
  templateUrl: './multiple-select.component.html',
  styleUrls: ['./multiple-select.component.scss'],
})
export class MultipleSelectComponent {
  @Input() showLabel: boolean = false;
  @Input() label!: string;
  @Input() optionsCount: number = 0;
  @Input() options!: any[];
  @Input() selectedOptions: any[] = [];
  @Output() selectionUpdated: EventEmitter<any[]> = new EventEmitter<any[]>();
  isOpen: boolean = false;

  get formattedLabel(): string {
    if (this.selectedOptions.length > 1) {
      return `${this.label} (${this.optionsCount})`;
    } else if (this.selectedOptions.length === 1) {
      return this.selectedOptions[0];
    } else {
      return this.label;
    }
  }

  togglePanel(): void {
    this.isOpen = !this.isOpen;
  }

  closePanel(): void {
    this.isOpen = false;
  }
}
