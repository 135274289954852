import { Component, Input } from '@angular/core';
import { ImpersonatedUser } from 'src/app/admin/models/user.interface';

@Component({
  selector: 'xos-impersonation-message',
  templateUrl: './impersonation-message.component.html',
  styleUrls: ['./impersonation-message.component.scss'],
})
export class ImpersonationMessageComponent {
  @Input() user!: ImpersonatedUser | null;
}
