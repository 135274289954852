export enum PermissionType {
  ViewChildCompanies = 1,
  VehiclesBaseInformation = 2,
  VehiclesEditNicknames = 3,
  VehiclesTelematics = 4,
  ChargersBaseInformation = 5,
  ChargersEditNicknames = 6,
  ChargersManagement = 7,
  ChargersReporting = 8,
  ServiceRequests = 9,
  ServiceRequestsHistory = 10,
  ViewOrders = 11,
  SubmitQuotes = 12,
  ViewQuotes = 13,
  OpenKnowledgeCenter = 14,
  OpenKnowledgeCenterWebWidget = 15,
  EditUtilityPolicies = 16,
  RequestService = 17,
  ViewWarrantyClaims = 18,
  SubmitWarrantyClaims = 19,
  QuoteDealerPricing = 20,
  QuoteBasePricing = 21,
  AccessReporting = 22,
  TenantAdministration = 23,
  DriverWiFiSetup = 24,
  ViewPowertrainsBaseInformation = 25,
  EditPowertrainsNicknames = 26,
  ViewPreventativeMaintenance = 27,
  RequestPreventativeMaintenance = 28,
  ReceiveReportingEmails = 29,
  ViewAllWarrantyClaims = 30,
  ViewWarrantyRegistrations = 31,
  CreateWarrantyRegistration = 32,
  ViewAllWarrantyRegistrations = 33,
  ViewInspections = 34,
  CreateInspection = 35,
  AccessAllVehicles = 36,
  ViewSpareParts = 37,
  SetChargingSchedule = 38,
  StartStopCharging = 39,
  ViewBasicVehicleTelematics = 40,
  ManageTelematics = 41,
}
