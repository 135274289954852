import { createReducer, on } from '@ngrx/store';
import { retrievedBasicConfig, retrievedFeatures, retrievedVehicleTypes } from './products.actions';
import { initialState } from './products.state';

export const productsReducer = createReducer(
  initialState,
  on(retrievedFeatures, (state, { typeId, optionalFeatures }) => ({
    ...state,
    features: [...state.features, { typeId, optionalFeatures }],
  })),
  on(retrievedBasicConfig, (state, { typeId, config }) => ({ ...state, basicConfig: [...state.basicConfig, { typeId, config }] })),
  on(retrievedVehicleTypes, (state, { vehicleTypes }) => ({ ...state, vehicleTypes })),
);
