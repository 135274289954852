<mat-accordion>
  <div *ngIf="showLabel" class="xos-field-label">{{ label }}</div>
  <mat-expansion-panel
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    class="asset-chips-filter"
    [ngClass]="{ opened: isOpen, filled: optionsCount > 1 && !isOpen }"
    [expanded]="isOpen"
    (click)="togglePanel()"
  >
    <mat-expansion-panel-header class="asset-header">
      <div>
        {{ formattedLabel }}
      </div>
    </mat-expansion-panel-header>
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="isOpen"
      cdkConnectedOverlayPanelClass="chips-overlay"
      (overlayOutsideClick)="closePanel()"
    >
      <xos-chips-select
        [options]="options"
        [selectedOptions]="selectedOptions"
        (selectionUpdated)="selectionUpdated.emit($event)"
      ></xos-chips-select>
    </ng-template>
  </mat-expansion-panel>
</mat-accordion>
