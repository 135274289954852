import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExternalWidgetComponent } from './components/external-widget/external-widget.component';
import { EmbedWidgetOverviewComponent } from './components/embed-widget-overview/embed-widget-overview.component';

@NgModule({
  declarations: [ExternalWidgetComponent, EmbedWidgetOverviewComponent],
  imports: [CommonModule],
})
export class ExternalModule {}
