import { Component, Input } from '@angular/core';

@Component({
  selector: 'xos-maintenance-error',
  templateUrl: './maintenance-error.component.html',
  styleUrls: ['./maintenance-error.component.scss'],
})
export class MaintenanceErrorComponent {
  @Input() message: string = '';
}
