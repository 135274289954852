<div class="embed-wrapper" [ngClass]="getClasses()">
  <div class="headline">We are making an impact with Xos Electric Trucks.</div>
  <div class="value miles">{{ data?.distanceDriven | number: '1.0-0' }}</div>
  <div class="units miles">Electric vehicle {{ unit }} driven</div>
  <div class="value co2">{{ data?.co2Savings | number: '1.0-0' }}</div>
  <div class="units co2">Lbs of emissions prevented</div>
  <a href="https://xostrucks.com" target="_blank" rel="noopener noreferrer">
    <div class="logo" [ngClass]="theme"></div>
  </a>
</div>
