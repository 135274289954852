<mat-form-field appearance="fill" class="chips-autocomplete">
  <mat-chip-list #optionsList>
    <mat-chip *ngFor="let option of currentOptions" (removed)="remove(option)">
      {{ option }}
      <button *ngIf="currentOptions.length > 1" matChipRemove>
        <i class="xos-icon icon-close-white"></i>
      </button>
    </mat-chip>
  </mat-chip-list>
  <div class="separator"></div>
  <div *ngFor="let option of availableToSelect" (click)="selected(option)" class="option">
    <span>{{ option }}</span>
  </div>
</mat-form-field>
