import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatLegacyTooltip as MatTooltip } from '@angular/material/legacy-tooltip';
import { Validators as CustomValidators } from '../../../shared/utils/validators';
import { Errors, ValidationMessages } from 'src/app/shared/models/validation-messages';
import { UserService } from '../../services/auth/user.service';
import { KnowledgeCenterService } from 'src/app/knowledge-center/services/knowledge-center.service';
import { SpinnerVariant } from 'src/app/shared/models/spinner-variant.enum';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatLegacyButton as MatButton } from '@angular/material/legacy-button';
import { catchError, takeUntil } from 'rxjs/operators';
import { EMPTY, Subject } from 'rxjs';
import { AnalyticsService } from '../../services/analytics.service';
import { MembershipService } from '../../services/membership/membership.service';
import { PermissionType } from '../../models/permissions.enum';
import { Auth } from 'aws-amplify';
import { MfaView } from '../../models/mfa.enum';
import { ImpersonationService } from 'src/app/admin/services/impersonation.service';
import { ImpersonatedUser } from 'src/app/admin/models/user.interface';

export const MFA_ACTIVE = 'SOFTWARE_TOKEN_MFA';

@Component({
  selector: 'xos-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  animations: [
    trigger('startLoading', [
      state(
        'loading',
        style({
          opacity: 1,
          display: 'visible',
        }),
      ),
      state(
        'preloading',
        style({
          opacity: 0,
          visibility: 'hidden',
        }),
      ),
      transition('preloading => loading', [animate('100ms')]),
      transition('loading => preloading', [animate('200ms')]),
    ]),
  ],
})
export class LoginFormComponent implements OnInit, OnDestroy {
  @Output() mfaDisplayed: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(MatTooltip) tooltip!: MatTooltip;
  @ViewChild(MatButton) submitBtn!: MatButton;
  loginForm!: UntypedFormGroup;
  validationMessages = ValidationMessages;
  errors = Errors;
  loading: boolean = false;
  spinnerVariant: SpinnerVariant = SpinnerVariant.White;
  returnUrl: string = '/';
  isMfaVisible: boolean = false;
  setupQRCode!: string;
  cognitoUser!: any;
  mfaView: MfaView = MfaView.Start;
  wrongMfaCode: boolean = false;
  private unsubscribe$: Subject<void> = new Subject();
  impersonatedUser: ImpersonatedUser | null = null;
  isImpersonating = false;

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private userService: UserService,
    private impersonationService: ImpersonationService,
    private knowledgeCenterService: KnowledgeCenterService,
    private router: Router,
    private route: ActivatedRoute,
    private analytics: AnalyticsService,
    private membershipService: MembershipService,
    private cd: ChangeDetectorRef,
  ) {}

  get f() {
    return this.loginForm.controls;
  }

  ngOnInit(): void {
    if (this.isImpersonating) {
      this.authService.logOut();
    }

    if (this.route.snapshot.queryParams['mfa']) {
      this.showMfa();
      this.cognitoUser = this.authService.mfaUser;
      this.setupMfa();
    } else {
      this.impersonatedUser = this.impersonationService.getCurrentImpersonatedUser();
      this.isImpersonating = !!this.impersonatedUser;
      this.analytics.trackEvent('Login');
      this.createForm(this.isImpersonating);
      this.subscribeToFormEdit();
    }
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  createForm(isImpersonating: boolean): void {
    if (isImpersonating) {
      this.loginForm = this.fb.group({
        password: [null, [Validators.required]],
      });
    } else {
      this.loginForm = this.fb.group({
        email: [null, [Validators.required, Validators.email, CustomValidators.removeSpaces]],
        password: [null, [Validators.required]],
        rememberMe: [false],
      });

      if (this.authService.savedRememberMe) {
        this.f.rememberMe.setValue(true);
        this.f.email.setValue(this.authService.lastUser);
      }
    }
  }

  login(): void {
    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched();
      return;
    }
    //if not focusing and bluring then if user will press enter instead of button click - there will be no animation cause input is focused
    this.submitBtn._getHostElement().focus();
    this.submitBtn._getHostElement().blur();
    this.loading = true;
    let { email, password, rememberMe } = this.loginForm.value;
    if (this.isImpersonating) {
      this.userService.forceReloadSettings();
      email = this.userService.CurrentUser?.email;
      rememberMe = false;
    }

    this.authService
      .logIn(email, password, rememberMe, this.impersonatedUser)
      .then(user => {
        this.cognitoUser = user;
        if (user?.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.router.navigate(['/create-password']);
        } else if (user?.challengeName === MFA_ACTIVE) {
          this.showMfa(true);
        } else {
          this.proceedLoggingIn();
        }
      })
      .catch(err => {
        this.analytics.trackEvent('Authentication.LoginFailure', { error: err });
        if (err.code === 'NotAuthorizedException') {
          this.f.password.setErrors({ emailOrPassword: true });
        }
        this.loading = false;
        this.cd.detectChanges();
      });
  }

  private showMfa(verify = false) {
    this.loading = false;
    verify && (this.mfaView = MfaView.Verify);
    this.isMfaVisible = true;
    this.mfaDisplayed.emit();
  }

  private proceedLoggingIn(withRedirect = false): void {
    this.userService
      .getCurrentUser()
      .pipe(
        catchError(err => {
          this.loading = false;
          console.error(err.message);

          if (err.status === 401 || err.status === 403) {
            this.f.password.setErrors({ emailOrPassword: true });
          }
          return EMPTY;
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(user => {
        if (this.cognitoUser?.challengeName === 'MFA_SETUP' || (user.isMfaEnabled && !this.cognitoUser?.challengeName)) {
          this.showMfa();
          this.setupMfa();
        } else if (!user.isMfaEnabled) {
          withRedirect = true;
        }
        this.userService.setCurrentUserName(user.fullName);
        this.analytics.trackEvent('Authentication.LoginSuccess');

        this.membershipService.reloadPermissions().subscribe(_ => {
          this.membershipService.checkPermissionAsync(PermissionType.OpenKnowledgeCenterWebWidget).subscribe(allowed => {
            if (allowed) {
              this.knowledgeCenterService.authenticateWidget();
            }
          });
          withRedirect && this.router.navigateByUrl(this.returnUrl);
        });
        //don't need to set loading false cause we are redirected
      });
  }

  private setupMfa() {
    const { email } = this.cognitoUser.attributes;
    Auth.setupTOTP(this.cognitoUser).then(
      code => (this.setupQRCode = `otpauth://totp/xostrucks.com:${email}?secret=${code}&issuer=Xos%20Trucks`),
    );
  }

  verifyCode(code: number) {
    this.loading = true;
    this.cd.detectChanges();
    this.wrongMfaCode && (this.wrongMfaCode = false);
    if (this.cognitoUser.challengeName === MFA_ACTIVE) {
      Auth.confirmSignIn(this.cognitoUser, String(code), MFA_ACTIVE)
        .then(() => {
          this.isMfaVisible = false;
          this.proceedLoggingIn(true);
        })
        .catch(() => this.setWrongCode());
    } else {
      Auth.verifyTotpToken(this.cognitoUser, String(code))
        .then(() => {
          Auth.setPreferredMFA(this.cognitoUser, 'TOTP').then(() => this.router.navigateByUrl(this.returnUrl));
        })
        .catch(() => this.setWrongCode());
    }
  }

  private setWrongCode() {
    this.wrongMfaCode = true;
    this.loading = false;
    this.cd.detectChanges();
  }

  private resetPasswordError() {
    this.f.password.setErrors({ emailOrPassword: false });
    this.f.password.updateValueAndValidity();
  }

  private subscribeToFormEdit() {
    this.loginForm
      .get('email')
      ?.valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe(_ => this.resetPasswordError());
  }

  onBlur() {
    this.loginForm.controls.email.patchValue(this.loginForm.value.email.trim());
  }
}
