<div class="external-wrapper">
  <div>
    <h2>{{ data?.distanceDriven | number: '1.0-0' }}</h2>
    <div class="units">Miles</div>
    <div class="info-text">Total Miles Driven</div>
  </div>
  <div>
    <h2>{{ data?.co2Savings | number: '1.0-0' }}</h2>
    <div class="units">Lbs</div>
    <div class="info-text">Total CO<sub>2</sub> Prevented</div>
  </div>
</div>
