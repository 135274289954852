import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, of, Subject } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Charger, Connector } from '../models/charger.interface';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { UtilityPoliciesService } from './utility-policies.service';
import { ActivePolicyRanges, TimeRange } from '../models/utility-policies.models';
import * as moment from 'moment';
import { ConnectorState } from '../models/connector-state.enum';
import { DateTimeService } from 'src/app/shared/services/date-time.service';
import { TimezonesService } from 'src/app/core/services/timezones.service';
import { NicknameItem } from '../../settings/models/nickname.interface';
import {
  AssignChargingScheduleError,
  AssignChargingScheduleRequest,
  ChargingSchedule,
} from '../models/connector-charging-schedule.interface';
import { MembershipService } from 'src/app/core/services/membership/membership.service';
import { PermissionType } from 'src/app/core/models/permissions.enum';
import { ChargingStatus } from 'src/app/fleet/models/telematics.interface';
import { BasicChargingSchedule } from 'src/app/shared/modules/charging-schedule/models/charging-schedule.interface';

@Injectable({
  providedIn: 'root',
})
export class ChargersService {
  chargers$: Observable<Charger[]>;
  activePolicyRanges$: Observable<ActivePolicyRanges>;
  private selectedCharger$: Subject<Charger | null> = new Subject();
  private selectedConnector$: Subject<Connector | null> = new Subject();
  private updateConnector$: Subject<number> = new Subject();
  private isFilterModeActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  activePolicyRanges!: ActivePolicyRanges;
  private copiedSchedule$ = new BehaviorSubject<ChargingSchedule | null>(null);

  private currentSelectedCharger: Charger | null = null;
  private currentSelectedConnector: Connector | null = null;

  chargersTelematics$: Subject<{ chargers: Charger[]; firstUpdate?: boolean }> = new Subject<{
    chargers: Charger[];
    firstUpdate?: boolean;
  }>();

  constructor(
    private httpClient: HttpClient,
    private utilityPoliciesService: UtilityPoliciesService,
    private dateTimeService: DateTimeService,
    private timezonesService: TimezonesService,
    private membershipService: MembershipService,
  ) {
    this.activePolicyRanges$ = utilityPoliciesService.getActivePolicyRanges().pipe(tap(response => (this.activePolicyRanges = response)));
    if (membershipService.isPermissionAvailable(PermissionType.ChargersManagement)) {
      this.activePolicyRanges$.subscribe();
    }

    this.chargers$ = this.getSRChargers();
  }

  getCurrentSelectedCharger(): Charger | null {
    return this.currentSelectedCharger;
  }

  getCurrentSelectedConnector(): Connector | null {
    return this.currentSelectedConnector;
  }

  getIsFilterModeActive(): boolean {
    return this.isFilterModeActive.value;
  }

  resetFilterMode(): void {
    this.isFilterModeActive.next(false);
  }

  toggleFilterMode(): void {
    this.isFilterModeActive.next(!this.getIsFilterModeActive());
  }

  isConnectorFilterred(connector: Connector): boolean {
    const now = this.dateTimeService.now();
    const gmtTime = moment(now).subtract(this.timezonesService.getTimezoneOffset(), 'minutes');
    let isConnectorChargingDuringPeak = false;
    if (this.activePolicyRanges) {
      const isPeakNow = this.utilityPoliciesService.isPeakNow(gmtTime, this.activePolicyRanges);
      const nextPeak = this.utilityPoliciesService.getNextPeak(gmtTime, this.activePolicyRanges);
      if (isPeakNow && connector.state === ConnectorState.Charging) {
        // connectors which are charging during peak period
        isConnectorChargingDuringPeak = true;
      } else if (connector.state === ConnectorState.Charging && nextPeak) {
        // connector which are charging and will not finish until peak starts
        isConnectorChargingDuringPeak = true;
      } else if (connector.state === ConnectorState.Connected) {
        // connector which is connected and will start or end or continue charging during peak
        if (connector.chargingSchedule && nextPeak) {
          isConnectorChargingDuringPeak = connector?.chargingSchedule?.some(el => this.isScheduleDuringPeak(el.start, el.end));
        }
      }
    }

    return isConnectorChargingDuringPeak;
  }

  isChargerChargingInPeakHours(charger: Charger): boolean {
    return charger.connectors?.some(connector => this.isConnectorFilterred(connector));
  }

  isAnyChargersChargingInPeakHours(chargers: Charger[] | null): boolean {
    return chargers?.some(charger => this.isChargerChargingInPeakHours(charger)) || false;
  }

  setSelectedCharger(charger: Charger | null): void {
    this.currentSelectedCharger = charger;
    this.selectedCharger$.next(charger);
  }

  getSelectedCharger(): Observable<Charger | null> {
    return this.selectedCharger$.asObservable();
  }

  setSelectedConnector(connector: Connector | null): void {
    this.currentSelectedConnector = connector;
    this.selectedConnector$.next(connector);
  }

  getSelectedConnector(): Observable<Connector | null> {
    return this.selectedConnector$.asObservable();
  }

  setUpdateConnector(id: number): void {
    this.updateConnector$.next(id);
  }

  getUpdateConnector(): Observable<number> {
    return this.updateConnector$.asObservable();
  }

  getChargers(): Observable<Charger[]> {
    return this.httpClient.get<Charger[]>(`${environment.apiUrl}/ChargersTelematics`).pipe(
      catchError((error: Error) => {
        console.error(error.message);
        return EMPTY;
      }),
      map(chargers => {
        return chargers.map(charger => {
          charger.connectors = charger.connectors?.map(connector => {
            if (!connector.chargingProfile || !connector.chargingProfile.activeRanges) return connector;
            this.adaptEndTimeSpanResponse(connector.chargingProfile);
            connector.chargingSchedule = connector.chargingProfile.activeRanges;
            return connector;
          });
          return charger;
        });
      }),
    );
  }

  getConnector(chargerId: number, connectorId: number): Observable<Connector> {
    return this.httpClient.get<Connector>(`${environment.apiUrl}/ChargersTelematics/${chargerId}/connectors/${connectorId}`).pipe(
      catchError((error: Error) => {
        console.error(error.message);
        return EMPTY;
      }),
      map(connector => {
        if (!connector || !connector.chargingProfile || !connector.chargingProfile.activeRanges) return connector;
        this.adaptEndTimeSpanResponse(connector.chargingProfile);
        connector.chargingSchedule = connector.chargingProfile.activeRanges.map(el => ({
          start: el.start,
          end: el.end,
        }));
        return connector;
      }),
    );
  }

  connectorStartStop(chargerId: number, connectorId: number, action: 'start' | 'stop'): Observable<boolean> {
    return this.httpClient
      .patch<boolean>(`${environment.apiUrl}/ChargersTelematics/${chargerId}/connectors/${connectorId}/${action}`, {})
      .pipe(
        catchError((error: Error) => {
          console.error(error.message);
          return EMPTY;
        }),
      );
  }

  getChargingProfilesCount(): Observable<number> {
    return this.httpClient.get<number>(`${environment.apiUrl}/ChargingProfiles/count`).pipe(
      catchError((error: Error) => {
        console.error(error.message);
        return EMPTY;
      }),
    );
  }

  adaptEndTimeSpanRequest(scheduleRequest: AssignChargingScheduleRequest) {
    scheduleRequest.activeRanges.forEach(range => {
      if (range.end === '24:00:00') {
        range.end = '23:59:59';
      }
    });
  }

  adaptEndTimeSpanResponse(scheduleResponse: ChargingSchedule) {
    scheduleResponse.activeRanges.forEach(range => {
      if (range.end === '23:59:59') {
        range.end = '24:00:00';
      }
    });
  }

  applyConnectorSchedule(
    chargerId: number,
    connectorId: number,
    scheduleRequest: AssignChargingScheduleRequest,
  ): Observable<boolean | AssignChargingScheduleError> {
    this.adaptEndTimeSpanRequest(scheduleRequest);
    return this.httpClient
      .put<boolean | AssignChargingScheduleError>(
        `${environment.apiUrl}/ChargingProfiles/charger/${chargerId}/connector/${connectorId}`,
        scheduleRequest,
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status == HttpStatusCode.BadRequest) {
            const err: AssignChargingScheduleError = error.error;
            return of(err);
          } else {
            console.error(error.message);
            return EMPTY;
          }
        }),
      );
  }

  clearConnectorSchedule(chargerId: number, connectorId: number): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${environment.apiUrl}/ChargingProfiles/charger/${chargerId}/connector/${connectorId}`).pipe(
      catchError((error: Error) => {
        console.error(error.message);
        return EMPTY;
      }),
    );
  }

  isScheduleDuringPeak(start: string, end: string | null): boolean {
    if (this.activePolicyRanges) {
      const highPeriod = this.utilityPoliciesService.getHighPeriods(this.activePolicyRanges);
      return highPeriod.some(range => {
        const peakStart = moment(range.start, moment.HTML5_FMT.TIME_SECONDS);
        const peakEnd = moment(range.end, moment.HTML5_FMT.TIME_SECONDS);
        const startTime = moment(start, moment.HTML5_FMT.TIME_SECONDS);
        const endTime = end ? moment(end, moment.HTML5_FMT.TIME_SECONDS) : null;
        return (
          (endTime
            ? endTime.isBetween(peakStart, peakEnd) || startTime.isBetween(peakStart, peakEnd)
            : startTime.isBetween(peakStart, peakEnd)) ||
          (endTime ? peakStart.isBetween(startTime, endTime) || peakEnd.isBetween(startTime, endTime) : false)
        );
      });
    } else {
      return false;
    }
  }

  getHighPeriodRanges(): TimeRange[] | null {
    return this.activePolicyRanges ? this.utilityPoliciesService.getHighPeriods(this.activePolicyRanges) || null : null;
  }

  getSRChargers(): Observable<Charger[]> {
    return this.httpClient.get<Charger[]>(`${environment.apiUrl}/Chargers`).pipe(
      shareReplay(),
      catchError((error: Error) => {
        console.error(error.message);
        return EMPTY;
      }),
    );
  }

  saveChargerNicknames(chargers: NicknameItem[]): Observable<boolean> {
    return this.httpClient.post<boolean>(`${environment.apiUrl}/Chargers`, chargers).pipe(
      catchError((error: Error) => {
        console.error(error.message);
        return EMPTY;
      }),
    );
  }

  setCopiedSchedule(schedule: ChargingSchedule): void {
    this.copiedSchedule$.next(schedule);
  }

  getCopiedScheduleObservable(): Observable<ChargingSchedule | null> {
    return this.copiedSchedule$.asObservable();
  }

  getCopiedSchedule(): ChargingSchedule | null {
    return this.copiedSchedule$.value;
  }

  getTimeToStartMessage(connector: Connector, isCard = false): string {
    const now = moment(this.dateTimeService.now());
    if (connector?.chargingSchedule?.length) {
      const starts = connector.chargingSchedule.map(el => moment(el.start, moment.HTML5_FMT.TIME_SECONDS));
      const laterToday = starts.find(el => el.isAfter(now));
      let chargingStart = laterToday && starts.length !== 1 ? laterToday : starts[0];
      if (chargingStart.isBefore(now)) {
        chargingStart.add(1, 'day');
      }
      return `${isCard ? 'Vehicle is connected. ' : ''}Charging is scheduled to begin in ${now.to(
        chargingStart,
        true,
      )}. Press "Start" on your charger's display if you haven't already`;
    } else {
      return 'Vehicle is connected. Begin charging manually or Apply charging schedule below';
    }
  }

  groupByState(charger: Charger): any[] {
    if (charger) {
      const { connectors } = charger;
      const states = [...new Set(connectors.map(item => item.state))];
      return states.map(state => {
        const number = connectors.filter(el => el.state === state).length;
        return { id: state, name: `${number} ${Object.values(ConnectorState)[state]}` };
      });
    } else {
      return [];
    }
  }

  groupByStateString(charger: Charger): string {
    const connectors = this.groupByState(charger);
    return connectors.reduce((acc, cur, i) => acc + cur.name + (i === connectors.length - 1 ? '' : ', '), '');
  }

  startChargerCharging(chargerId: number): Observable<boolean> {
    return this.httpClient.post<boolean>(`${environment.apiUrl}/CommandManager/charger/StartChargerCharging/${chargerId}`, { chargerId });
  }

  stopChargerCharging(chargerId: number): Observable<boolean> {
    return this.httpClient.post<boolean>(`${environment.apiUrl}/CommandManager/charger/StopChargerCharging/${chargerId}`, { chargerId });
  }

  getChargerChargingStatus(chargerId: number): Observable<ChargingStatus> {
    return this.httpClient.get<ChargingStatus>(`${environment.apiUrl}/CommandManager/charger/GetChargerChargingStatus/${chargerId}`).pipe(
      catchError((error: Error) => {
        console.error(error.message);
        return EMPTY;
      }),
    );
  }

  getChargerChargingSchedule(chargerId: number): Observable<BasicChargingSchedule> {
    return this.timezonesService.getTimeZoneOffsetObservable().pipe(
      switchMap(offset =>
        this.httpClient
          .get<ChargingSchedule>(`${environment.apiUrl}/CommandManager/charger/GetChargerChargingSchedule/${offset}/${chargerId}`)
          .pipe(
            map(schedule =>
              schedule
                ? { activeRanges: schedule.activeRanges.map(range => (range.end === '23:59:59' ? { ...range, end: '24:00:00' } : range)) }
                : schedule,
            ),
            catchError((error: Error) => {
              console.error(error.message);
              return EMPTY;
            }),
          ),
      ),
    );
  }

  setChargerChargingSchedule(schedule: BasicChargingSchedule, chargerId: number): Observable<boolean> {
    const adaptedSchedule = {
      activeRanges: schedule.activeRanges.map(range => (range.end === '24:00:00' ? { ...range, end: '23:59:59' } : range)),
    };
    return this.timezonesService
      .getTimeZoneOffsetObservable()
      .pipe(
        switchMap(offset =>
          this.httpClient.put<boolean>(
            `${environment.apiUrl}/CommandManager/charger/SetChargerChargingSchedule/${offset}/${chargerId}`,
            adaptedSchedule,
          ),
        ),
      );
  }

  clearChargerChargingSchedule(chargerId: number): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${environment.apiUrl}/CommandManager/DeleteChargerChargingSchedule/${chargerId}`);
  }
}
