import * as moment from 'moment';
import { Moment } from 'moment/moment';
import { findKey, values } from 'lodash/fp';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DisplayPeaks, HourPrice, Period, PriceType } from '../../../chargers/models/utility-policies.models';
import { TimezonesService } from '../../../core/services/timezones.service';
import { UtilityPolicyService } from '../../../settings/services/utility-policy.service';

@Component({
  selector: 'xos-period-form',
  templateUrl: './period-form.component.html',
  styleUrls: ['./period-form.component.scss'],
})
export class PeriodFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() selectedPeriod!: Period | null;
  @Input() hourPrices: HourPrice[] = [];
  @Input() selectedPeak!: { peak: any; label: string; priceType: PriceType } | null;
  @Output() selectPeak: EventEmitter<{ peak: any; label: string; priceType: PriceType }> = new EventEmitter<{
    peak: any;
    label: string;
    priceType: PriceType;
  }>();
  @Input() selectedDates!: { startDay: number | null; startMonth: number | null };
  @Input() viewMode = false;
  @Output() formFilled: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() dateChanged: EventEmitter<{ startDay: number | null; startMonth: number | null }> = new EventEmitter<{
    startDay: number | null;
    startMonth: number | null;
  }>();
  periodForm!: UntypedFormGroup;
  selectedDate!: Moment | null;
  toggledIndex: number | null = null;
  peaksList: any[] = values(DisplayPeaks).filter(el => typeof el === 'number');
  displayHourPrices: HourPrice[] = [];
  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    private fb: UntypedFormBuilder,
    private timezonesService: TimezonesService,
    private utilityPolicyService: UtilityPolicyService,
  ) {}

  ngOnInit(): void {
    this.subscribeToPeakSelect();
  }

  ngOnChanges(changes: SimpleChanges) {
    !this.periodForm && this.createForm();
    if (changes?.selectedPeriod?.currentValue && this.selectedPeriod) {
      const { startDay, startMonth } = this.selectedPeriod;
      this.setDate({ startDay, startMonth });
    }
    if (changes?.selectedPeriod?.currentValue === null) {
      this.periodForm.patchValue({ startDay: null, startMonth: null });
      this.selectedDate = null;
    }
    changes?.selectedPeak?.currentValue === null && this.setPeaks();
    changes?.selectedDates && this.setDate(this.selectedDates);
    if (changes?.hourPrices?.currentValue) {
      this.setPeaks();
      this.formFilled.emit(this.isCompletedChart() && this.periodForm.valid);
      this.displayHourPrices = this.isCompletedChart() ? this.hourPrices : this.utilityPolicyService.getAllHourPrices(this.hourPrices);
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get peaks(): UntypedFormArray {
    return this.periodForm?.controls['peaks'] as UntypedFormArray;
  }

  getLabel(peak: any): string {
    return findKey(el => el === peak?.value?.priceType || el === peak?.priceType, DisplayPeaks) || '';
  }

  togglePeak(i: number): void {
    this.toggledIndex = i;
  }

  dateChange(date: Moment | null) {
    //backend considers startMonth starting from 1, but moment months are zero indexed
    const updatedDates = { startDay: date ? date.date() : null, startMonth: date ? date.month() + 1 : null };
    this.periodForm.patchValue(updatedDates);
    this.dateChanged.emit(updatedDates);
    this.formFilled.emit(this.isCompletedChart() && this.periodForm.valid);
  }

  openPeakDetails(_i: number, peak: any): void {
    this.selectPeak.emit({ peak: peak?.value, label: this.getLabel(peak), priceType: peak?.value?.priceType });
  }

  getDisplayRanges(ranges: { from: number; to: number; type: PriceType }[]): string {
    return ranges
      .map(item => `${this.timezonesService.getAMPM(item.from)} - ${this.timezonesService.getAMPM(item.to + 1)}`)
      .join(this.viewMode ? ' ' : '; ');
  }

  private createForm(): void {
    this.periodForm = this.fb.group({
      startDay: [null, [Validators.required]],
      startMonth: [null, [Validators.required]],
      peaks: this.fb.array(
        this.peaksList.map((peak: any) =>
          this.fb.group({
            priceType: peak as number,
            ranges: [],
            enabled: false,
          }),
        ),
      ),
    });
  }

  private subscribeToPeakSelect(): void {
    if (this.periodForm) {
      this.periodForm
        .get('peaks')
        ?.valueChanges.pipe(takeUntil(this.unsubscribe$))
        .subscribe(value => {
          if (this.toggledIndex !== null) {
            const peak = value[this.toggledIndex];
            this.selectPeak.emit(
              peak?.enabled
                ? { peak, label: this.getLabel(peak), priceType: peak?.priceType }
                : { peak: null, label: this.getLabel(peak), priceType: peak?.priceType },
            );
          }
        });
    }
  }

  private setPeaks(): void {
    this.toggledIndex = null;
    const ranges = this.peaksList.map(type => {
      const existingPeaks = this.hourPrices.filter(el => el.type === type) || [];
      return existingPeaks.length
        ? { priceType: type, enabled: true, ranges: existingPeaks }
        : { priceType: type, enabled: false, ranges: [] };
    });
    this.peaks.patchValue(ranges);
  }

  private setDate(dates: { startDay: number | null; startMonth: number | null }): void {
    this.periodForm.patchValue(dates);
    //backend considers startMonth starting from 1, but moment months are zero indexed
    this.selectedDate =
      dates?.startDay && dates?.startMonth
        ? moment()
            .set('month', dates.startMonth - 1)
            .set('date', dates.startDay)
        : null;
  }

  isCompletedChart(): boolean {
    return this.hourPrices.reduce((acc: any, cur: HourPrice) => acc + (cur.to - cur.from + 1), 0) === 24;
  }
}
