import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { CustomerWidgetData, WidgetData } from '../models/overview-widget.interface';

export const EXTERNAL_API = 'Widgets/report/customers';
export const EXTERNAL_API_WIDGET_ID = 'Widgets/report/customer/vehicles/';

@Injectable({ providedIn: 'root' })
export class ExternalService {
  constructor(private httpClient: HttpClient) {}

  getWidgetData(): Observable<WidgetData> {
    return this.httpClient.get<WidgetData>(`${environment.apiUrl}/${EXTERNAL_API}/vehicles`);
  }

  getCustomerWidgetData(widgetId?: string): Observable<CustomerWidgetData> {
    return this.httpClient.get<CustomerWidgetData>(`${environment.apiUrl}/Widgets/report/customer/vehicles/${widgetId}`);
  }
}
