import { reject, sortBy } from 'lodash/fp';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil, switchMap } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Vehicle } from '../../../maintenance/models/vehicle.interface';
import { Charger } from '../../../chargers/models/charger.interface';
import { groupBy } from '../../utils/array-utils';

@Component({
  selector: 'xos-chips-autocomplete',
  templateUrl: './chips-autocomplete.component.html',
  styleUrls: ['./chips-autocomplete.component.scss'],
})
export class ChipsAutocompleteComponent implements OnChanges {
  @ViewChild('optionInput') optionInput!: ElementRef<HTMLInputElement>;
  @Input() options!: any[];
  @Input() placeholder: string = 'Search by';
  @Input() isVehicle: boolean = true;
  @Input() showNicknames!: boolean;
  @Input() selectedAssets: any[] = [];
  @Input() showNonElectricLabel: boolean = false;
  @Output() selectionUpdated: EventEmitter<any[]> = new EventEmitter<any[]>();
  filteredOptions$!: Observable<any[]>;
  selectedOptions: any[] = [];
  optionControl = new UntypedFormControl();
  optionsList: any[] = [];
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  private unsubscribe$: Subject<void> = new Subject();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.options?.currentValue) {
      this.optionsList = sortBy(this.showNicknames ? ['nickName'] : ['vin', 'serial'], [...this.options]);
      this.setFilterOptions();
    }
    if (changes?.selectedAssets?.currentValue) {
      this.selectedOptions = [...this.selectedAssets];
      this.optionsList = this.optionsList.filter(item => !this.selectedOptions.some(el => el.id === item.id));
      this.optionControl.setValue('');
    }
  }

  selected(option: Charger | Vehicle): void {
    this.selectedOptions = [...this.selectedOptions, option];
    this.selectionUpdated.emit(this.selectedOptions);
    this.optionControl.setValue(option);
    this.optionsList = reject(option, this.optionsList);
    this.optionInput.nativeElement.value = '';
  }

  remove(option: Charger | Vehicle) {
    this.selectedOptions = reject(option, this.selectedOptions);
    this.selectionUpdated.emit(this.selectedOptions);
    this.optionsList = sortBy(this.showNicknames ? ['nickName'] : ['vin', 'serial'], [...this.optionsList, option]);
    this.optionControl.setValue('');
  }

  private setFilterOptions(): void {
    this.filteredOptions$ = this.optionControl.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      startWith(''),
      map(value => {
        const filterValue = (option: string) => option?.toLowerCase().includes(value.toLowerCase());
        return typeof value === 'string'
          ? this.optionsList.filter((item: any) =>
              this.showNicknames
                ? filterValue(item.nickName) || filterValue(item?.serial) || filterValue(item?.vin)
                : filterValue(item?.serial) || filterValue(item?.vin),
            )
          : this.optionsList.filter(el => el.id !== value?.id);
      }),
    );

    if (this.isVehicle && this.showNonElectricLabel) {
      this.filteredOptions$ = this.filteredOptions$.pipe(
        map((options: any[]) => {
          var grouped = groupBy(options, (item: any) => item.isElectric);
          return Object.keys(grouped).map(key => ({ key: key == 'true', value: grouped[key] }));
        }),
      );
    }
  }
}
