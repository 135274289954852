import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[xosTogglePassword]',
})
export class TogglePasswordDirective implements AfterViewInit {
  private shown: boolean = false;
  private icons = {
    hide: `<i class="xos-icon icon-eye"></i>`,
    show: `<i class="xos-icon icon-eye-open"></i>`,
  };

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    this.setup();
  }

  toggle(span: HTMLElement): void {
    this.shown = !this.shown;
    if (this.shown) {
      this.el.nativeElement.setAttribute('type', 'text');
      span.innerHTML = this.icons.show;
    } else {
      this.el.nativeElement.setAttribute('type', 'password');
      span.innerHTML = this.icons.hide;
    }
  }

  setup(): void {
    const parent = this.el.nativeElement.parentNode;
    const span = document.createElement('span');
    span.className = 'type-toggle';
    span.innerHTML = this.icons.hide;
    span.addEventListener('click', () => {
      this.toggle(span);
    });
    parent.appendChild(span);
  }
}
