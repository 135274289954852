import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { UserService } from './auth/user.service';
import { Moment } from 'moment';
import { map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class TimezonesService {
  private timeZoneOffset!: number;
  private timeZoneOffsetObservable$!: Observable<number>;

  constructor(private userService: UserService) {
    this.updateFromPreferences();
  }

  updateFromPreferences() {
    this.timeZoneOffsetObservable$ = this.userService
      .getCurrentUserSettings()
      .pipe(
        map(userSettings => {
          var timezoneOffset = this.getBrowserTimezoneOffset();
          if (!(userSettings.timezoneOffset === null || userSettings.useBrowserTimeZone)) {
            timezoneOffset = userSettings.timezoneOffset;
          }
          return timezoneOffset;
        }),
      )
      .pipe(shareReplay(1));
    this.timeZoneOffsetObservable$.subscribe(offset => {
      this.timeZoneOffset = offset;
    });
  }

  getTimeZoneOffsetObservable() {
    return this.timeZoneOffsetObservable$;
  }

  getTimezoneOffset() {
    return this.timeZoneOffset;
  }

  getBrowserTimezoneOffset() {
    return -new Date().getTimezoneOffset();
  }

  static MOMENT_TIME_FORMAT = 'h:mm A';
  static MOMENT_DATE_FORMAT = 'YYYY-MM-DD';
  static MOMENT_FULL_FORMAT = 'YYYY-MM-DDTHH:mm:ss[Z]';

  mergeDateTime(date: string | Date, time: string): string | Date {
    let merged = moment(date);
    const timeMoment = moment(time, TimezonesService.MOMENT_TIME_FORMAT);
    merged.add(timeMoment.hours(), 'hours');
    merged.add(timeMoment.minutes(), 'minutes');
    return this.dateTimeLocalToUtc(merged.toDate());
  }

  getLocalDateTime(dateTime: string | Date): Date {
    return moment(dateTime).subtract(this.getBrowserTimezoneOffset(), 'minutes').add(this.getTimezoneOffset(), 'minutes').toDate();
  }

  getLocalDate(dateTime: string | Date): string {
    return moment(dateTime)
      .subtract(this.getBrowserTimezoneOffset(), 'minutes')
      .add(this.getTimezoneOffset(), 'minutes')
      .format(TimezonesService.MOMENT_DATE_FORMAT);
  }

  getCurrentLocalDate(): Moment {
    return moment(new Date()).subtract(this.getBrowserTimezoneOffset(), 'minutes').add(this.getTimezoneOffset(), 'minutes');
  }

  getLocalTime(d: string | Date): string {
    let dateMoment = moment(d);
    dateMoment.minutes(Math.round(dateMoment.minutes() / 30) * 30);
    return dateMoment
      .subtract(this.getBrowserTimezoneOffset(), 'minutes')
      .add(this.getTimezoneOffset(), 'minutes')
      .format(TimezonesService.MOMENT_TIME_FORMAT);
  }

  getLocalTimeFromTimespan(timeSpan: string, format: string = moment.HTML5_FMT.TIME_SECONDS): string {
    let timeMoment = moment(timeSpan, format);

    return timeMoment.add(this.getTimezoneOffset(), 'minutes').format(format);
  }

  getUTCTimeFromTimespan(timeSpan: string, format: string = moment.HTML5_FMT.TIME_SECONDS): string {
    let timeMoment = moment(timeSpan, format);
    timeMoment.subtract(this.getTimezoneOffset(), 'minutes');
    return timeMoment.format(format);
  }

  getAMPM(item: number, short: boolean = false): string {
    return item < 12
      ? `${item === 0 ? 12 : item}${short ? '' : ':00'} AM`
      : `${item === 12 ? item : item - 12}${short ? '' : ':00'} ${item === 24 ? 'A' : 'P'}M`;
  }

  dateUtcToLocal(dateTime: string | Date): string | Date {
    // there we are receiving time in format "2021-11-12T00:00:00Z"
    // If user has GMT+2 time zone, the time becomes "2021-11-11TT22:00:00Z" which equals "2021-11-12T00:00:00 GMT+2"
    return moment(dateTime).subtract(this.getBrowserTimezoneOffset(), 'minutes').format(TimezonesService.MOMENT_FULL_FORMAT);
  }

  dateTimeLocalToUtc(dateTime: string | Date): Date {
    return moment(dateTime).subtract(this.getTimezoneOffset(), 'minutes').add(this.getBrowserTimezoneOffset(), 'minutes').toDate();
  }

  dateTimeToUtcString(dateTime: string | Date): string {
    return moment(dateTime).subtract(this.getTimezoneOffset(), 'minutes').format(TimezonesService.MOMENT_FULL_FORMAT);
  }

  dateTimeToLocalString(dateTime: string | Date): string {
    return moment(dateTime).add(this.getTimezoneOffset(), 'minutes').format(TimezonesService.MOMENT_FULL_FORMAT);
  }

  dateOnly(dateTime: string | Date, includeTime: boolean = false): string {
    // example:
    // Wed Nov 03 2021 00:00:00 GMT+0200 (Eastern European Standard Time) =>
    // '2021-11-03T00:00:00.000Z' => '2021-11-03'
    const dateWithoutTime = moment(dateTime).add(this.getBrowserTimezoneOffset(), 'minutes').toDate().toISOString();
    return includeTime ? dateWithoutTime : dateWithoutTime.split('T')[0];
  }
}
