import { Component } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'xos-make-read',
  templateUrl: './make-read.component.html',
  styleUrls: ['../clear-filter/clear-filter.component.scss'],
})
export class MakeReadComponent extends Toast {
  constructor(protected toastrService: ToastrService, public toastPackage: ToastPackage) {
    super(toastrService, toastPackage);
  }

  action() {
    this.toastPackage.triggerAction(true);
  }

  close(): void {
    this.toastrService.clear();
  }
}
