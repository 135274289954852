import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { EMPTY, Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  RangeMoment,
  UtilityPolicy,
  ActivePolicyRanges,
  PriceType,
  TimeRange,
  PeriodicityType,
  AdminUtilityPolicy,
} from '../models/utility-policies.models';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { findKey, startCase } from 'lodash/fp';

@Injectable({
  providedIn: 'root',
})
export class UtilityPoliciesService {
  private refreshPolicies$ = new BehaviorSubject<void>(undefined);
  private getPolicies$: Observable<UtilityPolicy[]> | null = null;
  private getAdminPolicies$: Observable<AdminUtilityPolicy[]> | null = null;
  private activePolicyRanges$: Observable<ActivePolicyRanges> | null = null;

  constructor(private httpClient: HttpClient) {}

  getPolicies(): Observable<UtilityPolicy[]> {
    return (this.getPolicies$ ??= this.refreshPolicies$.pipe(
      switchMap(() => this.httpClient.get<UtilityPolicy[]>(`${environment.apiUrl}/UtilityPolicies`)),
      map(policies => policies.map(item => this.setDisplayPeriod(item))),
      shareReplay(1),
      catchError((error: Error) => {
        console.error(error.message);
        return EMPTY;
      }),
    ));
  }

  getAdminPolicies(): Observable<AdminUtilityPolicy[]> {
    return this.httpClient.get<AdminUtilityPolicy[]>(`${environment.apiUrl}/admin/utilitypolicies`).pipe(
      map(policies => policies.map(item => this.setDisplayPeriod(item) as AdminUtilityPolicy)),
      catchError((error: Error) => {
        console.error(error.message);
        return EMPTY;
      }),
    );
  }

  getAdminPolicy(id: number): Observable<AdminUtilityPolicy> {
    return this.httpClient.get<AdminUtilityPolicy>(`${environment.apiUrl}/admin/utilitypolicies/${id}`).pipe(
      map(policy => this.setDisplayPeriod(policy) as AdminUtilityPolicy),
      catchError((error: Error) => {
        console.error(error.message);
        return EMPTY;
      }),
    );
  }

  getActivePolicyRanges(): Observable<ActivePolicyRanges> {
    return (this.activePolicyRanges$ ??= this.refreshPolicies$.pipe(
      switchMap(() => this.httpClient.get<ActivePolicyRanges>(`${environment.apiUrl}/UtilityPolicies/ActivePolicyRanges`)),
      shareReplay(1),
      catchError((error: Error) => {
        console.error(error.message);
        return EMPTY;
      }),
    ));
  }

  savePolicy(policy: UtilityPolicy): Observable<UtilityPolicy> {
    return this.httpClient.put<UtilityPolicy>(`${environment.apiUrl}/UtilityPolicies`, policy).pipe(
      catchError((error: Error) => {
        console.error(error.message);
        return EMPTY;
      }),
      tap(() => this.refreshPolicies$.next()),
    );
  }

  updatePolicy(id: number, policy: Partial<UtilityPolicy>): Observable<boolean> {
    return this.httpClient.patch<boolean>(`${environment.apiUrl}/UtilityPolicies/${id}`, policy).pipe(
      catchError((error: Error) => {
        console.error(error.message);
        return EMPTY;
      }),
      tap(() => this.refreshPolicies$.next()),
    );
  }

  saveAdminPolicy(tenantId: number, policy: Partial<AdminUtilityPolicy>): Observable<number> {
    return this.httpClient.put<number>(`${environment.apiUrl}/admin/utilitypolicies/tenants/${tenantId}`, policy).pipe(
      catchError((error: Error) => {
        console.error(error.message);
        return EMPTY;
      }),
      tap(() => this.refreshPolicies$.next()),
    );
  }

  updateAdminPolicy(tenantId: number, policy: Partial<AdminUtilityPolicy>, id: number): Observable<boolean> {
    return this.httpClient.patch<boolean>(`${environment.apiUrl}/admin/utilitypolicies/tenants/${tenantId}/policy/${id}`, policy).pipe(
      catchError((error: Error) => {
        console.error(error.message);
        return EMPTY;
      }),
      tap(() => this.refreshPolicies$.next()),
    );
  }

  deleteAdminPolicy(id: number): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${environment.apiUrl}/admin/utilitypolicies/${id}`).pipe(
      catchError((error: Error) => {
        console.error(error.message);
        return EMPTY;
      }),
      tap(() => this.refreshPolicies$.next()),
    );
  }

  // the function inputs/outputs are in UTC time zone
  // internal ranges are in Utility Policy time zone
  getNextPeak(gmtTime: moment.Moment, ranges: ActivePolicyRanges): RangeMoment | undefined {
    const offset = Number(ranges.offset) ?? 0;
    let nowMoment = gmtTime;
    nowMoment.add(offset, 'minutes');

    const highPeriods = ranges.ranges.filter(range => range.priceType === PriceType.On);

    if (highPeriods.length > 0) {
      const nextRanges = highPeriods.filter(range => moment(range.start, moment.HTML5_FMT.TIME_SECONDS).isAfter(nowMoment));
      if (nextRanges.length > 0) {
        const nextPeakStart = moment(nextRanges[0].start, moment.HTML5_FMT.TIME_SECONDS).subtract(offset, 'minutes');
        const nextPeakEnd = moment(nextRanges[0].end, moment.HTML5_FMT.TIME_SECONDS).subtract(offset, 'minutes');
        return { start: nextPeakStart, end: nextPeakEnd };
      }
    }

    return undefined;
  }

  // the function inputs are in UTC time zone
  getCurrentPriceType(gmtTime: moment.Moment, ranges: ActivePolicyRanges): PriceType {
    const offset = Number(ranges.offset) ?? 0;
    let nowMoment = gmtTime;
    nowMoment.add(offset, 'minutes');

    const matchedPeriods = ranges.ranges.filter(
      range =>
        nowMoment.isSameOrAfter(moment(range.start, moment.HTML5_FMT.TIME_SECONDS)) &&
        nowMoment.isSameOrBefore(moment(range.end, moment.HTML5_FMT.TIME_SECONDS)),
    );

    if (matchedPeriods.length > 0) {
      return matchedPeriods[0].priceType;
    } else {
      return PriceType.Undefined;
    }
  }

  isPeakNow(gmtTime: moment.Moment, ranges: ActivePolicyRanges): boolean {
    return this.getCurrentPriceType(gmtTime, ranges) === PriceType.On;
  }

  getHighPeriods(ranges: ActivePolicyRanges): TimeRange[] {
    return ranges.ranges.filter(range => range.priceType === PriceType.On);
  }

  rangeDuration(x: { start: moment.Moment; end: moment.Moment }): number {
    return moment.duration(x.end.diff(x.start)).asHours();
  }

  getLongestRange(ranges: ActivePolicyRanges) {
    return ranges?.ranges
      .filter(range => range.priceType === PriceType.Off)
      .map(r => {
        return {
          start: moment(r.start, ['HH:mm:ss', moment.ISO_8601]),
          end: moment(r.end, ['HH:mm:ss', moment.ISO_8601]).add(1, 'second'),
        };
      })
      .map(r => {
        return { ...r, duration: this.rangeDuration(r) };
      })
      .sort((a, b) => b.duration - a.duration)[0];
  }

  // this function does not care about time zones
  getRecommendedRange(ranges: ActivePolicyRanges): number[] {
    const undefinedRange = { start: 0, end: 23 };
    const businessHours = { start: 9, end: 17 };
    const minimumContinuousHours = 6;
    const longestLow = this.getLongestRange(ranges);
    if (longestLow) {
      if (longestLow.end.hour() - longestLow.start.hour() >= minimumContinuousHours) {
        // if there are more green hours in period than minimum required
        if (longestLow.end.hour() < businessHours.start) {
          // if period is outside business hours, return entire period
          return [longestLow.start.hour(), longestLow.end.hour()];
        } else {
          // otherwise return period until biz hours
          return [longestLow.start.hour(), businessHours.start];
        }
        // minimal continuous period
      } else if (longestLow.start.hour() + minimumContinuousHours <= 23) {
        // if start is more than minimumContinuousHours till the end of the period
        return [longestLow.start.hour(), longestLow.start.hour() + minimumContinuousHours];
      } else {
        // end is less than minimumContinuousHours till the end of the period
        return [
          longestLow.end.hour() === 0 ? 23 - minimumContinuousHours : longestLow.end.hour() - minimumContinuousHours,
          longestLow.end.hour() === 0 ? 23 : longestLow.end.hour(),
        ];
      }
    } else {
      return [undefinedRange.start, undefinedRange.end];
    }
  }

  setDisplayPeriod(policy: UtilityPolicy | AdminUtilityPolicy): UtilityPolicy | AdminUtilityPolicy {
    return {
      ...policy,
      displayPeriod: startCase(findKey(el => el === policy.periodicity, PeriodicityType) || '')
        .split(' ')
        .join('/'),
    };
  }
}
