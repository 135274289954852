export enum ConnectorState {
  Charging,
  Connected,
  Idle,
  Issue,
  Unavailable,
}

export enum ConnectorActionState {
  None,
  Preparing,
  Finishing,
  Disabled,
}
