import { Component } from '@angular/core';
import { SidenavService } from '../../services/sidenav.service';
import { SidenavState } from '../../../overview/models/sidenav-state.enum';
import { Router } from '@angular/router';

@Component({
  selector: 'xos-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  constructor(private sidenavService: SidenavService, private router: Router) {}

  toggleSidenav(): void {
    const isSettingsRoute = this.router.routerState.snapshot.url.search('settings') > -1;
    this.sidenavService.toggleMainSideNav(SidenavState.Auto, isSettingsRoute);
  }

  getHoverText(): string {
    return this.sidenavService.isMainSideNavOpen.getValue() === SidenavState.Expanded ? 'Collapse nav' : 'Expand nav';
  }
}
