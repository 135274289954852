import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'xos-chips-select',
  templateUrl: './chips-select.component.html',
  styleUrls: ['./chips-select.component.scss'],
})
export class ChipsSelectComponent implements OnChanges {
  @Input() options!: string[];
  @Input() selectedOptions: string[] = [];
  @Output() selectionUpdated: EventEmitter<string[]> = new EventEmitter<string[]>();
  currentOptions: string[] = [];
  availableOptions: string[] = [];

  get availableToSelect(): string[] {
    return this.availableOptions.filter(option => !this.currentOptions.find(currentOpt => currentOpt === option));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.options?.currentValue && changes?.options?.firstChange) {
      this.availableOptions = [...this.options];
    }
    if (changes?.selectedOptions?.currentValue && changes?.options?.firstChange) {
      this.currentOptions = [...this.selectedOptions];
    }
  }

  selected(option: string): void {
    this.currentOptions = [...this.currentOptions, option];
    this.selectionUpdated.emit(this.currentOptions);
  }

  remove(option: string) {
    this.currentOptions = [...this.currentOptions.filter(opt => opt != option)];
    this.selectionUpdated.emit(this.currentOptions);
  }
}
