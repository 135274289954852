import { Component, Input } from '@angular/core';
import { AnalyticsService } from 'src/app/core/services/analytics.service';
import { KnowledgeCenterService } from 'src/app/knowledge-center/services/knowledge-center.service';

@Component({
  selector: 'xos-screen-message',
  templateUrl: './screen-message.component.html',
  styleUrls: ['./screen-message.component.scss'],
})
export class ScreenMessageComponent {
  isOpen: boolean = true;

  @Input() title!: string;
  @Input() mainText!: string;

  constructor(private analytics: AnalyticsService, private knowledgeCenterService: KnowledgeCenterService) {}

  toggleWebWidget(): void {
    this.analytics.trackEvent('OpenKnowledgeCenter', { source: 'ChargerMessage' });
    this.knowledgeCenterService.toggleKnowledgeCenterWidget();
  }

  close() {
    this.isOpen = false;
  }
}
