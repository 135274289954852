<div class="error-box">
  <div class="error-title">
  <span *ngIf="title">
    {{ title }}
  </span>
  <span class="error-icon">⚠</span>
</div>
  <div *ngIf="description" class="error-description">
    {{ description }}
  </div>
</div>
