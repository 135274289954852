<div *ngIf="!loading && !filteredVehicles?.length" class="empty-state">
  <div class="empty-icon"></div>
  <ng-container *ngIf="noVehicles; else filterApplied">
    <h2 class="no-vehicles">No {{ dataName }}</h2>
    <h3>Here will be list of your {{ dataName }}.</h3>
  </ng-container>
  <ng-template #filterApplied>
    <h3>Search or filter applied, some items are hidden!</h3>
    <a id="clear-filters" (click)="clearSearchAndFilters()">Clear filters</a>
  </ng-template>
</div>
