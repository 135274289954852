import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Validators } from './validators';

export function PasswordStrengthValidator(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {

    const value = control.value;

    if (!value) {
      return null;
    }

    const hasUpperCase = Validators.upperCase.test(value);
    const hasLowerCase = Validators.lowerCase.test(value);
    const hasSpecialCharacters = Validators.specialCharacters.test(value);
    const sizeFit = value.length >= Validators.minLength;

    return !hasUpperCase || !hasLowerCase || !hasSpecialCharacters || !sizeFit ? {passwordStrength: true}: null;
  }
}
