import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SidenavState } from 'src/app/overview/models/sidenav-state.enum';
import { SidenavService } from 'src/app/shared/services/sidenav.service';
import { AnalyticsService } from './analytics.service';

declare var pendo: any;

@Injectable({
  providedIn: 'root',
})
export class GuidesService {
  constructor(private analytics: AnalyticsService, private sidenavService: SidenavService, private router: Router) {}

  private GUIDE_PER_LOCATION = [
    ['/overview', 'SCrmRL0uMPdDAlbXWg0nUzquGtg'],
    ['/reports?tab=chargers', ''],
    ['/reports', 'X3Am8jrfWgqHrLzQIKLFZamAp3o'],
    ['/maintenance?tab=active', 'QnPlOd3n866U9TmxY1gt1Ru_PA4'],
    ['/maintenance?tab=preventative', 'I35FBJk9RVgDgqRD1VSooTazj64'],
    ['/maintenance?tab=history', ''],
    ['/maintenance?tab=warranty', ''],
    ['/maintenance?tab=inspections', ''],
    ['/maintenance/spare-parts', ''],
    ['/maintenance', 'QnPlOd3n866U9TmxY1gt1Ru_PA4'],
    ['/settings/', 'yAYKYJRCB3PO1kg9BDtca501HJc'],
    ['/chargers?tab=sessions', ''],
    ['/chargers?tab=hubs', ''],
    ['/chargers', 'LlCTkeG0CSMUGVpkb22554STkMs'],
    ['/fleet?tab=xosids', ''],
    ['/fleet', '8ETp48C94wl__7Yyw1c5TfjGG3E'],
  ];

  getGuideByUrl(url: string) {
    const loc = this.GUIDE_PER_LOCATION.find(g => url.includes(g[0]));
    return loc?.[1];
  }

  isGuideAvailableForCurrentUrl(): boolean {
    const url = this.router.url.toLowerCase();
    return !!this.getGuideByUrl(url);
  }

  private showGuide(guideId: string) {
    pendo.loadGuides().then(() => {
      const guide = pendo.findGuideById(guideId);
      if (guide) {
        guide.show();
      } else {
        console.warn(`Guide ${guideId} is not available`);
      }
    });
  }

  launchGuide(): void {
    this.analytics.trackEvent('HeaderNavigation.ShowGuide');

    const url = this.router.url.toLowerCase();
    const guideId = this.getGuideByUrl(url);
    if (url.includes('overview')) {
      if (this.sidenavService.isMainSideNavOpen.getValue() === SidenavState.Closed) {
        this.sidenavService.toggleMainSideNav();
      }
    }

    if (guideId) {
      if (url.includes('settings')) {
        this.router.navigateByUrl('/settings/profile');
        setTimeout(() => this.showGuide(guideId), 1000);
      } else {
        this.showGuide(guideId);
      }
    }
  }
}
