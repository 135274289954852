import { Pipe, PipeTransform } from '@angular/core';
import { round } from 'lodash';

@Pipe({
  name: 'thousandsToK',
})
export class ThousandsToKPipe implements PipeTransform {
  transform(value: number): string {
    if (value > 999 && value < 1000000) {
      return `${round(value / 1000, 1)}K`;
    } else if (value > 1000000 && value < 1000000000) {
      return `${round(value / 1000000, 1)}M`;
    } else if (value > 1000000000) {
      return `${round(value / 1000000000, 1)}G`;
    } else {
      return `${round(value)}`;
    }
  }
}
