import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'xos-multiple-search',
  templateUrl: './multiple-search.component.html',
  styleUrls: ['./multiple-search.component.scss'],
})
export class MultipleSearchComponent {
  @Input() assetCount: number = 0;
  @Input() isVehicle: boolean = false;
  @Input() options!: any[];
  @Input() placeholder: string = 'Search by';
  @Input() selectedAssets: any[] = [];
  @Input() showLabel: boolean = false;
  @Input() showNicknames!: boolean;
  @Input() showNonElectricLabel: boolean = false;
  @Output() selectionUpdated: EventEmitter<any[]> = new EventEmitter<any[]>();
  isOpen: boolean = false;

  togglePanel(): void {
    this.isOpen = !this.isOpen;
  }

  closePanel(): void {
    this.isOpen = false;
  }
}
