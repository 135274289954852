import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'xos-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
  @Input() value: string = '';
  @Input() placeholder: string = 'Search';
  @Output() filter = new EventEmitter<KeyboardEvent>();
  @Output() clear = new EventEmitter<void>();

  clearValue() {
    this.value = '';
    this.clear.emit();
  }
}
