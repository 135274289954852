export interface AWSError {
  name?: string;
  code?: string;
  message?: string;
}

export function FormatAWSError(err: AWSError): AWSError {
  switch (err.code) {
    case 'ExpiredCodeException':
      err.message = 'Invalid verification code, please request a new code.';
      break;
    case 'LimitExceededException':
      err.message = 'Password reset attempt limit exceeded, please try again in a few hours.';
      break;
    default:
      break;
  }
  return err;
}
