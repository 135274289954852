import { Injectable } from '@angular/core';
import { StorageService } from '../models/storage.interface';

@Injectable()
export class SessionStorageService implements StorageService {
  setItem<T>(key: string, value: T): void {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  getItem<T>(key: string): T {
    return JSON.parse(sessionStorage.getItem(key) as string);
  }

  removeItem(key: string): void {
    sessionStorage.removeItem(key);
  }
}
