<div>
  <i class="xos-icon icon-cancel"></i>
  <ng-container *ngIf="title; else noTitleText">
    Service request <span>{{ message }}</span> for <span>{{ title }}</span> was canceled!
  </ng-container>
  <ng-template #noTitleText>
    Service request <span>{{ message }}</span> was canceled!
  </ng-template>
</div>
<button (click)="close()">
  <i class="xos-icon icon-close-white"></i>
</button>
