import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PriceType } from '../../../chargers/models/utility-policies.models';

@Component({
  selector: 'xos-energy-pricing-chart',
  templateUrl: './energy-pricing-chart.component.html',
  styleUrls: ['./energy-pricing-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnergyPricingChartComponent {
  @Input() ranges: Array<number[]> = [];
  @Input() hourPrices: { from: number; to: number; type: PriceType }[] = [];
  @Input() allActive = false;
  periodType = PriceType;

  isActiveClass(index: number, hours: { from: number; to: number; type: PriceType }): boolean {
    return !!this.ranges.find(range => {
      return index + hours.from >= range[0] && index + hours.from <= range[1];
    });
  }

  getHoursArray(from: number, to: number): any[] {
    return [...Array(to + 1 - from).keys()];
  }
}
