import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ChargerReportData, VehicleReportData } from '../models/vehicle-report-data.interface';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { EMPTY, Observable, of, Subject } from 'rxjs';
import { TimezonesService } from '../../core/services/timezones.service';
import { ChargerReport } from '../models/charger-report.interface';
import { round } from 'lodash/fp';
import { TelematicsData } from '../../fleet/models/telematics.interface';
import { FilesService } from 'src/app/shared/services/files.service';
import { AssetType } from '../../maintenance/models/tabs.enum';
import { ReportLabels, VehicleReportLabels } from '../../shared/models/report-labels';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  exportVehicleReport$: Subject<boolean> = new Subject<boolean>();
  exportChargerReport$: Subject<boolean> = new Subject<boolean>();
  clearReportFilters$: Subject<boolean> = new Subject<boolean>();

  constructor(private httpClient: HttpClient, private timezonesService: TimezonesService, private fileService: FilesService) {}

  generateVehicleRandomData(from: Date, to: Date, vehicleCount: number): Map<string, VehicleReportData> {
    const aggregationDays = this.getAggregationDays(from, to);
    const points = this.getAggregationDatePoints(moment(from), moment(to), aggregationDays);

    let results = new Map<string, VehicleReportData>();
    for (let point of points) {
      let milesDriven = 0;
      for (let i = 0; i < aggregationDays; ++i) {
        milesDriven += this.getRandom(0, 8) * vehicleCount;
      }
      milesDriven = parseFloat(milesDriven.toFixed(3));
      const emissionsSavings = parseFloat((milesDriven * 0.225).toFixed(3));
      const tcoSavings = parseFloat(((milesDriven / 9) * 3.241).toFixed(3));
      results.set(point, { TCOSavings: tcoSavings, Odo: milesDriven, CO2Savings: emissionsSavings });
    }

    return results;
  }

  generateChargersRandomData(from: Date, to: Date, chargersCount: number): Map<string, ChargerReportData> {
    const aggregationDays = this.getAggregationDays(from, to);
    const points = this.getAggregationDatePoints(moment(from), moment(to), aggregationDays);

    let results = new Map<string, ChargerReportData>();
    for (let point of points) {
      let powerDispensed = 0;
      for (let i = 0; i < aggregationDays * chargersCount; ++i) {
        powerDispensed += this.getRandom(0, 100);
      }
      powerDispensed = parseFloat(powerDispensed.toFixed(1));
      results.set(point, { powerDispensed });
    }

    return results;
  }

  private getAggregationDatePoints(fromMoment: moment.Moment, toMoment: moment.Moment, aggregationDays: number): string[] {
    const result: string[] = [fromMoment.format()];
    while (fromMoment <= toMoment) {
      fromMoment.add(aggregationDays, 'days');
      result.push(fromMoment.format());
    }
    return result;
  }

  private getRandom(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }

  private getAggregationDays(from: Date, to: Date): number {
    const fromMoment = moment(from);
    const toMoment = moment(to);
    const diffDays = toMoment.diff(fromMoment, 'days');
    if (diffDays <= 31) {
      return 1;
    } else if (diffDays <= 371) {
      return 7;
    } else {
      return 31;
    }
  }

  getReportByAssetType(
    assetType: AssetType,
    offset: number,
    endDate: string | Date,
    startDate?: string | Date,
    options?: any[],
  ): Observable<TelematicsData> {
    const to = this.timezonesService.dateTimeToUtcString(endDate);
    const from = startDate ? { from: this.timezonesService.dateTimeToUtcString(startDate) } : null;
    return assetType === AssetType.Charger
      ? this.getChargersReport(offset, to, from, options)
      : this.getVehiclesReport(offset, to, from, options);
  }

  getChargersReport(offset: number, to: string | Date, from?: any, options?: any[]): Observable<TelematicsData> {
    const chargerId = options?.length ? { chargerId: options.map(el => el.id) } : null;
    return this.httpClient
      .get<ChargerReport[]>(`${environment.apiUrl}/ChargingSessions/report/${offset}?`, { params: { to, ...from, ...chargerId } })
      .pipe(
        map(data => ({
          dataPoints: data.map(item => ({
            dateTime: item.date ? this.timezonesService.dateUtcToLocal(item.date) : '',
            power: item.kwhCharged,
          })),
          co2Savings: 0,
          tcoSavings: 0,
          timeInService: 0,
          socUsedTotal: 0,
          socChargedTotal: 0,
          avgSocUsed: 0,
          avgSocCharged: 0,
          chargeTime: data.reduce((prev: number, cur: ChargerReport) => (cur?.duration ? prev + cur.duration : prev), 0) / 3600,
          powerDispensed: data.reduce((prev: number, cur: ChargerReport) => (cur?.kwhCharged ? prev + cur.kwhCharged : prev), 0),
        })),
        catchError((error: Error) => {
          console.error(error.message);
          return EMPTY;
        }),
      );
  }

  getVehiclesReport(offset: number, to: string | Date, from?: any, options?: any[]): Observable<TelematicsData> {
    const vehicleId = options?.length ? { vehicleId: options.map(el => el.id) } : null;
    let params = new HttpParams({ fromObject: { to, ...from, ...vehicleId } });
    VehicleReportLabels.forEach(l => (params = params.append('data', l.toString())));
    return this.httpClient.get<any>(`${environment.apiUrl}/VehiclesTelematics/report/${offset}?`, { params }).pipe(
      map(data => {
        const dataPoints = data.map((item: any) => ({
          ...item.data,
          dateTime: item.date ? this.timezonesService.dateUtcToLocal(item.date) : '',
        }));
        return {
          dataPoints,
          co2Savings: dataPoints.reduce((prev: any, cur: any) => prev + cur.CO2Savings, 0),
          tcoSavings: dataPoints.reduce((prev: any, cur: any) => prev + cur.TCOSavings, 0),
          timeInService: dataPoints.reduce((prev: any, cur: any) => prev + cur.TimeInService, 0),
          socUsedTotal: dataPoints.reduce((prev: any, cur: any) => prev + cur.SocUsed, 0),
          socChargedTotal: dataPoints.reduce((prev: any, cur: any) => prev + cur.SocCharged, 0),
          avgSocUsed: dataPoints.reduce((prev: any, cur: any) => prev + cur.SocUsed, 0) / (dataPoints.length || 1),
          avgSocCharged: dataPoints.reduce((prev: any, cur: any) => prev + cur.SocCharged, 0) / (dataPoints.length || 1),
          chargeTime: 0,
          powerDispensed: 0,
        };
      }),
      catchError((error: Error) => {
        console.error(error.message);
        return EMPTY;
      }),
    );
  }

  exportVehicleData(
    offset: number,
    propertyNames: ReportLabels[],
    endDate: string,
    startDate?: string,
    entities?: any[],
  ): Observable<boolean> {
    let httpParams = new HttpParams();
    propertyNames.forEach(l => (httpParams = httpParams.append('data', l.toString())));
    const to = this.timezonesService.dateTimeToUtcString(endDate);
    const from = startDate ? this.timezonesService.dateTimeToUtcString(startDate) : null;
    if (from) httpParams = httpParams.append('from', from);
    if (to) httpParams = httpParams.append('to', to);
    if (entities) {
      entities.forEach(element => {
        httpParams = httpParams.append('vehicleId', element.id);
      });
    }
    this.fileService.downloadFileByUrl(
      `${environment.apiUrl}/VehiclesTelematics/report/pervehicle/${offset}/export?${httpParams.toString()}`,
    );
    return of(true);
  }

  exportChargerData(offset: number, propertyName: string, endDate: string, startDate?: string, entities?: any[]): Observable<boolean> {
    let httpParams = new HttpParams();
    const to = this.timezonesService.dateTimeToUtcString(endDate);
    const from = startDate ? this.timezonesService.dateTimeToUtcString(startDate) : null;
    if (from) httpParams = httpParams.append('from', from);
    if (to) httpParams = httpParams.append('to', to);
    if (entities) {
      entities.forEach(element => {
        httpParams = httpParams.append('chargerId', element.id);
      });
    }

    this.fileService.downloadFileByUrl(
      `${environment.apiUrl}/ChargingSessions/report/percharger/${offset}/export/${propertyName}?${httpParams.toString()}`,
    );
    return of(true);
  }

  getExportActive(isVehicle: boolean): Observable<boolean> {
    return isVehicle ? this.exportVehicleReport$.asObservable() : this.exportChargerReport$.asObservable();
  }
}
