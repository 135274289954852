<div class="error-wrapper">
  <div class="icon-wrapper">
    <i class="xos-icon icon-warning"></i>
  </div>
  <div class="error-main">
    <h4>We're improving the Xosphere!</h4>
    <div>
      {{ message }}
    </div>
  </div>
</div>
