import { ChangeDetectionStrategy, Input, Component } from '@angular/core';
import { SpinnerVariant } from '../../models/spinner-variant.enum';

@Component({
  selector: 'xos-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinnerComponent {
  @Input() visible : boolean = false;
  @Input() variant : SpinnerVariant = SpinnerVariant.Grey;
}
