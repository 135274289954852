import { VehicleStatus } from './telematics.interface';

export const VehicleStatuses: VehicleStatus[] = [
  {
    id: 1,
    name: 'Active',
    class: 'active',
  },
  {
    id: 2,
    name: 'Charging',
    class: 'charging',
  },
  {
    id: 3,
    name: 'Low charge',
    class: 'need-charge',
  },
  {
    id: 4,
    name: 'Ready to go',
    class: 'ready-to-go',
  },
  {
    id: 5,
    name: 'In-service',
    class: 'in-service',
  },
  {
    id: 6,
    name: 'Inactive',
    class: 'inactive',
  },
];
