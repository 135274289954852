import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatLegacyTabChangeEvent as MatTabChangeEvent, MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';

@Component({
  selector: 'xos-switcher',
  templateUrl: './switcher.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitcherComponent implements OnChanges, AfterViewInit {
  @Input() isStepper: boolean = false;
  @Input() tabs!: any[];
  @Input() disabledTabs: any[] = [];
  @Input() selectedTab: number = 0;
  @Output() selectionChanged: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild('tabGroup') tabGroup!: MatTabGroup;
  selectedTabIndex: number = 0;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.selectedTab && this.selectedTab !== this.selectedTabIndex) {
      this.tabGroup.selectedIndex = this.selectedTab;
    }
  }

  ngAfterViewInit() {
    this.isStepper && this.addTabArrows();
  }

  onTabChange(tab: MatTabChangeEvent): void {
    this.selectedTabIndex = tab.index;
    this.selectionChanged.emit(this.selectedTabIndex);
  }

  isTabDisabled(tab: string): boolean {
    return !!this.disabledTabs.find(el => el === tab);
  }

  private addTabArrows(): void {
    const tabLabels = Array.from(Array(this.tabs.length - 1));
    tabLabels.forEach((_item, index) => {
      const tab = document.querySelector(`.arrow-tabs .mat-tab-labels div[id$="${index + 1}"]`);
      const i = document.createElement('i');
      i.className = 'xos-icon icon-arrow-right';
      const parentNode = tab && tab.parentNode;
      tab && parentNode && parentNode.insertBefore(i, tab);
    });
    this.cdr.detectChanges();
  }
}
