import { Options } from '@angular-slider/ngx-slider';

export class RangeSlider {
  option: Options;
  value: number;
  highValue: number;

  constructor(value: number, highValue: number, minLimit?: number, maxLimit?: number) {
    this.value = value;
    this.highValue = highValue;
    this.option = {
      floor: 0,
      ceil: 23,
      minLimit: minLimit,
      maxLimit: maxLimit,
    };
  }
}
