import { isObject } from 'lodash/fp';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export class Validators {
  static upperCase = /[A-Z]+/;
  static lowerCase = /[a-z]+/;
  static specialCharacters = /[!@#$%^&*()]+/;
  static minLength = 8;

  static hasAllValuesInObj(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const obj = control.value;
      if (!obj) {
        return { hasEmptyField: true };
      }
      const exists = Object.keys(obj).every((key: string) => obj[key] !== null && obj[key] !== undefined && obj[key] !== '');

      return !exists ? { hasEmptyValues: true } : null;
    };
  }

  static warrantyRegistrationCompanyFormValidator(): ValidatorFn {
    return Validators.hasAllValuesInObjWithException(['address2'], ['businessEmail']);
  }

  static warrantyRegistrationDomiciledCompanyFormValidator(formGroup: UntypedFormGroup): ValidationErrors | null {
    if (formGroup.value.isDomiciled) {
      return Validators.hasAllValuesInObjWithException(
        ['address2'],
        ['businessEmail'],
      )(formGroup.get('customerDomicileForm') as UntypedFormControl);
    }

    return null;
  }

  static hasAllValuesInObjWithException(exceptFields: string[], validateEmailFields?: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const obj = control.value;
      if (!obj) {
        return { hasEmptyField: true };
      }

      let invalidFields;
      if (validateEmailFields) {
        invalidFields = validateEmailFields.find(
          (key: string) => Object.keys(obj).find(el => key === el) && !obj[key]?.match('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        );
      }

      const emptyFields = Object.keys(obj).find(
        (key: string) => !exceptFields.find(el => key === el) && (obj[key] === null || obj[key] === undefined || obj[key] === ''),
      );

      return invalidFields || emptyFields ? { hasEmptyOrInvalidValues: true } : null;
    };
  }

  static someRequiredFields(names: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const obj = control.value;
      if (!obj || !names.some(name => obj[name]?.id)) {
        return { requiredFieldMissing: true };
      }

      return !names.some(name => obj[name]?.id) ? { requiredFieldMissing: true } : null;
    };
  }

  static whiteSpace(control: UntypedFormControl): ValidationErrors | null {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  static customerValidation(control: UntypedFormControl): ValidationErrors | null {
    const isValid = isObject(control.value) && control.value.hasOwnProperty('id') && control.value.hasOwnProperty('customerId');
    return isValid ? null : { notCustomer: true };
  }

  static notesConditionallyRequired(formGroup: UntypedFormGroup): ValidationErrors | null {
    if (formGroup.value.additionalInformation.customization) {
      return Validators.whiteSpace(formGroup.get('additionalInformation.notes') as UntypedFormControl) ? { whiteSpace: true } : null;
    }

    return null;
  }

  static hasSelectedPermission(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const selectedPermissions = control.value
        .filter((item: { checked: boolean; [key: string]: boolean }) => item.checked && Object.keys(item).length > 1)
        .map((item: any) => {
          const { checked, ...rest } = item;
          return item[Object.keys(rest)[0]];
        })
        .flat()
        .filter((item: { [key: string]: boolean }) => item[Object.keys(item)[0]]);

      return selectedPermissions.length ? null : { noSelectedPermission: true };
    };
  }

  static autoCompleteRequireSelectOption(control: AbstractControl): ValidationErrors | null {
    const selection: any = control.value;
    if (typeof selection === 'string') {
      return { selectionRequired: true };
    }
    return null;
  }

  static greaterThan0(control: UntypedFormControl): ValidationErrors | null {
    if (control?.value === null || control.value === undefined) return null;

    return Number(control?.value) > 0 ? null : { greaterThan0: true };
  }

  static hasAllValuesOrNoneInObj(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const obj = control.value?.formValue;
      if (!obj) {
        return null;
      }
      const valid = control.value?.valid;
      const noneValues = Object.keys(obj).every((key: string) => obj[key] === null || obj[key] === undefined || obj[key] === '');
      const allValues = Object.keys(obj).every((key: string) => obj[key] !== null && obj[key] !== undefined && obj[key] !== '');
      return (noneValues || allValues) && valid ? null : { hasSomeValues: true };
    };
  }

  static removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
    }
    return null;
  }
}
