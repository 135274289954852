<mat-accordion>
  <div *ngIf="showLabel" class="xos-field-label">{{ isVehicle ? 'Fleet' : 'Chargers' }}</div>
  <mat-expansion-panel
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    class="asset-chips-filter"
    [ngClass]="{ opened: isOpen, filled: assetCount > 0 && !isOpen }"
    [expanded]="isOpen"
    (click)="togglePanel()"
  >
    <mat-expansion-panel-header class="asset-header">
      <div>
        {{ isVehicle ? 'Fleet' : 'Chargers' }} <span *ngIf="assetCount > 0">({{ assetCount }})</span>
      </div>
    </mat-expansion-panel-header>
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="isOpen"
      cdkConnectedOverlayPanelClass="chips-overlay"
      (overlayOutsideClick)="closePanel()"
    >
      <xos-chips-autocomplete
        [placeholder]="placeholder"
        [isVehicle]="isVehicle"
        [options]="options"
        [showNicknames]="showNicknames"
        [selectedAssets]="selectedAssets"
        [showNonElectricLabel]="showNonElectricLabel"
        (selectionUpdated)="selectionUpdated.emit($event)"
      ></xos-chips-autocomplete>
    </ng-template>
  </mat-expansion-panel>
</mat-accordion>
