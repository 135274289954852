import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'xos-user-icon-preview',
  templateUrl: './user-icon-preview.component.html',
  styleUrls: ['./user-icon-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserIconPreviewComponent implements OnChanges {
  @Input() userName: string = '';
  @Input() icon: unknown = '';
  @Input() avatarUrl: string = '';
  @Input() showMailIcon: boolean = false;
  @Input() tableView: boolean = false;
  defaultIcon: string = '';

  ngOnChanges(): void {
    this.defaultIcon = this.getDefaultIcon();
  }

  getDefaultIcon(): string {
    if (!this.userName) {
      return '';
    }
    const userNameArr = this.userName.split(' ');
    if (userNameArr.length === 1) {
      return userNameArr[0].charAt(0).toUpperCase();
    } else {
      return userNameArr[0].charAt(0).toUpperCase() + userNameArr[1].charAt(0).toUpperCase();
    }
  }
}
