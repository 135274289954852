import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ArcElement, ChartConfiguration, ChartItem, PointStyle } from 'chart.js';
import Chart from 'chart.js/auto';
import { DoughnutChartMode } from '../../models/doughnut-chart.enum';

@Component({
  selector: 'xos-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
})
export class DoughnutChartComponent implements OnChanges {
  @Input() isFreePlan: boolean = false;
  @Input() data: number[] = [];
  @Input() mode: DoughnutChartMode = DoughnutChartMode.Vehicles;
  @Input() explainText: string = '';
  @Input() backgroundColors: string[] = [];
  @Input() mergedSlices: (number | undefined)[] = [0, 0];
  @Input() hoverColors!: Array<number[]>;
  @Input() radius: number = 86;

  chartElement: ArcElement | null = null;
  chart: Chart<'doughnut', number[], unknown> | null = null;

  get diameter(): number {
    return 2 * this.radius;
  }

  private get defaultInnerRadius(): number {
    return this.radius - 6;
  }

  private get hoveredInnerRadius(): number {
    return this.radius - 34;
  }

  onMouseOut() {
    //@ts-ignore
    if (this.chartElement) {
      this.chartElement.innerRadius = this.defaultInnerRadius;
    }
    this.chartElement = null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data?.currentValue) {
      if (this.chart) {
        this.chart.data.datasets[0].data = changes.data.currentValue;
        this.chart.update('none');
      } else {
        setTimeout(() => this.initChart(), 500);
      }
    }
  }

  initChart(): void {
    const canvasEl = document.getElementById('chart');
    const canvas: ChartItem = canvasEl as ChartItem;

    if (this.chart) {
      this.chart!.destroy();
      //@ts-ignore
      canvasEl.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
      //@ts-ignore
      canvasEl.getContext('2d').restore();
    }

    const el = document.createElement('img');
    el.style.display = 'none';

    const config: ChartConfiguration<'doughnut'> = {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: this.data,
            backgroundColor: this.backgroundColors,
            hoverBackgroundColor: this.hoverColors.map(color => this.createGradient(canvasEl, color)),
            borderColor: 'transparent',
            hoverOffset: 2,
            borderAlign: 'inner',
          },
        ],
      },
      options: {
        cutout: '92%',
        radius: this.radius,
        responsive: false,
        aspectRatio: 2,
        animation: {
          animateScale: true,
        },
        layout: {
          padding: -4,
        },
        //@ts-ignore
        onHover: (_evt, activeElements) => {
          if (!activeElements || !activeElements.length) {
            if (this.chartElement != null) {
              //@ts-ignore
              this.chartElement.innerRadius = this.defaultInnerRadius;
            }
            this.chartElement = null;
            return;
          }

          //@ts-ignore
          if (this.chartElement != null) {
            this.chartElement.innerRadius = this.defaultInnerRadius;
          }
          //@ts-ignore
          this.chartElement = activeElements[0].element;
          if (this.chartElement != null) {
            this.chartElement.innerRadius = this.hoveredInnerRadius;
          }
        },
        plugins: {
          tooltip: {
            padding: {
              top: 9,
              left: 0,
              right: 12,
              bottom: 9,
            },
            bodyFont: {
              size: 11,
            },
            usePointStyle: true,
            position: 'nearest',
            callbacks: {
              labelPointStyle(): { pointStyle: PointStyle; rotation: number } {
                return {
                  pointStyle: el,
                  rotation: 0,
                };
              },
              labelColor: function () {
                return {
                  borderColor: '#070707',
                  backgroundColor: '#070707',
                  borderRadius: 2,
                };
              },
              labelTextColor: function () {
                return '#ffffff';
              },
              label: (tooltipItem: any): string | string[] => {
                return this.getTooltipByIndex(tooltipItem.dataIndex, tooltipItem.parsed);
              },
            },
          },
        },
      },
      plugins: [
        {
          id: 'text',
          beforeDraw: (chart: Chart): boolean | void => {
            const width = chart.width,
              height = chart.height,
              ctx = chart.ctx;

            ctx.restore();
            ctx.font = '400 40px "Montserrat", sans-serif';
            ctx.textBaseline = 'middle';
            const text = this.data.reduce((acc, item) => acc + item, 0).toString();
            const textX = Math.round((width - ctx.measureText(text).width) / 2),
              textY = height / 2;
            ctx.fillStyle = '#ffffff';
            ctx.fillText(text, textX, textY);

            ctx.font = '500 12px "Montserrat", sans-serif';
            const explainText = this.explainText,
              explainTextX = Math.round((width - ctx.measureText(explainText).width) / 2),
              explainTextY = height / 2 + 30;
            ctx.fillStyle = '#898989';
            ctx.fillText(explainText, explainTextX, explainTextY);

            ctx.save();
          },
        },
      ],
    };
    if (canvas && config) {
      this.chart = new Chart(canvas, config);
    }
  }

  createGradient(canvasEl: any, color: number[]): any {
    //@ts-ignore
    const ctx = canvasEl?.getContext('2d');
    //@ts-ignore
    const gradient = ctx?.createRadialGradient(
      //@ts-ignore
      canvasEl.width / 2,
      //@ts-ignore
      canvasEl.height / 2 + 5,
      //@ts-ignore
      canvasEl.height / 4,
      //@ts-ignore
      canvasEl.width / 2,
      //@ts-ignore
      canvasEl.height / 2 + 5,
      //@ts-ignore
      canvasEl.height / 2,
    );
    const innerColor = `rgba(${color[0]},${color[1]},${color[2]},50%)`;
    const mainColor = `rgb(${color[0]},${color[1]},${color[2]})`;
    gradient?.addColorStop(0.68, innerColor);
    gradient?.addColorStop(0.681, mainColor);

    return gradient;
  }

  getTooltipByIndex(index: number, count: number): string {
    switch (this.mode) {
      case DoughnutChartMode.Vehicles:
        return this.getTooltipsForVehicles(index, count);
      case DoughnutChartMode.Chargers:
        return this.getTooltipsForChargers(index, count);
    }
  }

  getTooltipsForVehicles(index: number, count: number): string {
    switch (index) {
      case 0:
        return `Unknown status • ${count}`;
      case 1:
        return `Active • ${count}`;
      case 2:
        return `Charging • ${this.mergedSlices[0]}; Ready to go • ${this.mergedSlices[1]}`;
      case 3:
        return `Low charge • ${count}`;
      case 4:
        return `Inactive • ${count}`;
      case 5:
        return `In-service • ${count}`;
      default:
        return '';
    }
  }

  getTooltipsForChargers(index: number, count: number): string {
    switch (index) {
      case 0:
        return `Connected • ${this.mergedSlices[0]}; Charging • ${this.mergedSlices[1]}`;
      case 1:
        return `Idle • ${count}`;
      case 2:
        return `Issue • ${this.mergedSlices[2]}; Unavailable • ${this.mergedSlices[3]}`;
      default:
        return '';
    }
  }
}
