import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'xos-user-icon',
  templateUrl: './user-icon.component.html',
  styleUrls: ['./user-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserIconComponent implements OnChanges {
  @Input() userName: string = '';
  @Input() icon: unknown = '';
  @Input() avatarUrl: string = '';
  defaultIcon: string = '';

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.icon?.firstChange && changes.userName?.firstChange) {
      this.defaultIcon = this.getDefaultIcon();
    }
  }

  getDefaultIcon(): string {
    const userNameArr = this.userName.split(' ');
    if (userNameArr.length === 1) {
      return userNameArr[0].charAt(0).toUpperCase();
    } else {
      return userNameArr[0].charAt(0).toUpperCase() + userNameArr[1].charAt(0).toUpperCase();
    }
  }
}
