import * as moment from 'moment';

export enum PriceType {
  Undefined = -1,
  Off = 0,
  Med = 1,
  On = 2,
}

export interface RangeMoment {
  start: moment.Moment;
  end: moment.Moment;
}

export enum PeriodicityType {
  SummerWinter = 0,
  Annual = 1,
}

export enum PeriodType {
  Summer = 0,
  Winter = 1,
  Annual = 2,
}

export enum WeekPartType {
  Weekend = 0,
  Workdays = 1,
  Week = 2,
}

export interface TimeRange {
  start: string;
  end: string;
  priceType: PriceType;
}

export interface WeekPart {
  weekPart: WeekPartType;
  ranges: TimeRange[];
}

export interface Period {
  startDay: number;
  startMonth: number;
  periodType: PeriodType;
  weekParts: WeekPart[];
}

export interface UtilityPolicy {
  created?: string;
  id?: number;
  name: string;
  timezoneId: string;
  timezoneName?: string;
  offset?: number;
  periodicity: PeriodicityType;
  periods: Period[];
  displayPeriod?: string;
}

export interface AdminUtilityPolicy extends UtilityPolicy {
  customerId: string;
  customerInternalId: number;
  customerName: string;
  tenantId: number;
}

export interface ActivePolicyRanges {
  id?: number;
  name: string;
  timezoneId: string;
  timezoneName: string;
  offset?: number;
  ranges: TimeRange[];
}

export enum DisplayPeaks {
  Off = PriceType.Off,
  Mid = PriceType.Med,
  On = PriceType.On,
}

export interface UpdatedRange {
  ranges: Array<number[]>;
  type: PriceType;
}

export interface HourPrice {
  from: number;
  to: number;
  type: PriceType;
}
