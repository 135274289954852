<h1>Forgot your password?</h1>
<p class="subheading">We will email you instructions on how to reset your password.</p>
<form [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPassword()" class="login-form responsive-form">
  <xos-error-box *ngIf="lastError.message" [errorMessage]="lastError"></xos-error-box>
  <mat-form-field appearance="fill">
    <label for="email">Email</label>
    <input type="email" id="email" matInput placeholder="Email" formControlName="email" (blur)="onBlur()" />
    <xos-error-tooltip [errors]="f.email.errors" *ngIf="f.email?.errors && f.email?.touched"></xos-error-tooltip>
    <mat-error *ngIf="f.email.hasError('email')">Please enter a valid email address</mat-error>
  </mat-form-field>

  <button type="submit" [disabled]="loading" mat-flat-button color="primary" class="width-100">
    Send Reset Instructions
    <ng-container *ngIf="loading">
      <mat-spinner strokeWidth="1" diameter="18"></mat-spinner>
    </ng-container>
  </button>
  <p class="subheading">Do you remember the password?</p>
  <a mat-stroked-button color="link" class="width-100 sign-in-button" routerLink="/login">Sign In</a>
</form>
