<div class="header">
  <span class="month-label">{{ periodLabel }}</span>
  <div class="actions">
    <span class="today-link" (click)="todayDay()">Today</span>
    <button mat-icon-button (click)="previousMonth()">
      <i class="xos-icon icon-arrow-left"></i>
    </button>
    <button mat-icon-button (click)="nextMonth()">
      <i class="xos-icon icon-arrow-right"></i>
    </button>
  </div>
</div>
