<button
  matTooltip="Export data in .csv"
  matTooltipPosition="above"
  matTooltipClass="xos-tooltip export-tooltip"
  color="primary"
  mat-button
  (click)="export.emit()"
  [disabled]="disabled"
>
  <i class="xos-icon icon-download"></i>
  <span>Export to CSV</span>
</button>
