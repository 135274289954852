import * as moment from 'moment';
import { isNil } from 'lodash/fp';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';

import { AssetType } from '../../../maintenance/models/tabs.enum';
import { ChargerDataTypes, FiltersView, ReportIntervals, VehicleDataTypes } from '../../models/asset-filters.enum';
import { Charger } from '../../../chargers/models/charger.interface';
import { Vehicle } from '../../../maintenance/models/vehicle.interface';
import { ReportFilter } from '../../models/filters.interface';
import { TelematicsPeriod } from '../../../fleet/models/telematics.interface';
import { getCollection } from 'src/app/shared/utils/enum-utils';
import { AnalyticsService } from 'src/app/core/services/analytics.service';
import { UserInfo } from '../../../core/services/auth/user.service';
import { ReportsService } from '../../../reports/services/reports.service';

@Component({
  selector: 'xos-report-filters',
  templateUrl: './report-filters.component.html',
  styleUrls: ['../../../maintenance/components/asset-filter/asset-filter.component.scss', './report-filters.component.scss'],
})
export class ReportFiltersComponent implements OnDestroy, OnChanges {
  @ViewChild(MatAccordion) accordion!: MatAccordion;
  @Input() isVehicle!: boolean;
  @Input() assetsList!: Charger[] | Vehicle[];
  @Input() showNicknames!: boolean;
  @Input() view: FiltersView = FiltersView.Full;
  @Input() userInfo!: UserInfo | null;
  @Input() isMobile: boolean = false;
  @Input() isFreePlan: boolean = false;
  @Input() showNonElectricLabel: boolean = false;
  @Input() filters: ReportFilter | null = null;
  @Output() filterChanged: EventEmitter<ReportFilter> = new EventEmitter<ReportFilter>();
  assetForm!: UntypedFormGroup;
  assetType = AssetType;
  assetIntervals: { name: any; value: number }[] = getCollection(ReportIntervals);
  dataTypes: { name: any; value: number }[] = [];
  assetNames!: { name: string; type: AssetType }[];
  openDatePicker: boolean = false;
  showDatePicker: boolean = false;
  assetCount: number = 0;
  currentFilters!: ReportFilter;
  filtersView = FiltersView;
  private unsubscribe$: Subject<void> = new Subject();

  constructor(private fb: UntypedFormBuilder, private analytics: AnalyticsService, private reportsService: ReportsService) {}

  get f() {
    return this.assetForm.controls;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.assetForm) {
      const form = this.createForm();
      this.assetChange();
      this.assetNames = [
        ...(this.isVehicle ? [{ name: 'Vehicles', type: AssetType.Vehicle }] : [{ name: 'Chargers', type: AssetType.Charger }]),
      ];
      this.view === FiltersView.DateOnly && (this.assetIntervals = this.rearrangeIntervals());
      this.subscribeToClearAllFilters();
    }
    if (changes?.isFreePlan) {
      this.isFreePlan ? this.f['interval'].disable() : this.f['interval'].enable();
    }

    if (changes?.filters) {
      if (this.filters) {
        if (
          this.filters.from &&
          this.filters.to &&
          (this.filters.reportIntervals == ReportIntervals['All time'] || this.filters.reportIntervals == ReportIntervals['Custom'])
        ) {
          this.filters.reportIntervals = ReportIntervals['Custom'];
          this.assetForm.patchValue({
            startDate: this.filters.from,
            endDate: this.filters.to,
          });
          this.showDatePicker = true;
        }
        this.assetForm.patchValue({
          dataTypes: this.filters.dataTypes ?? [1],
          interval: this.filters.reportIntervals,
        });
        this.assetCount = this.filters.selectedAssets.length;
        this.currentFilters = {
          ...this.currentFilters,
          ...this.filters,
        };
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get reportTypes(): string[] {
    return [...this.dataTypes.map(x => x.name)];
  }

  get currentReportTypes(): string[] {
    return this.dataTypes.filter(dt => this.currentFilters.dataTypes.find(x => x == dt.value)).map(x => x.name);
  }

  private getDataTypes() {
    return this.isVehicle
      ? getCollection(VehicleDataTypes).map(x => {
          return { ...x, name: x.name === 'Miles driven' && this.userInfo?.useMetricSystem ? 'Kilometers driven' : x.name };
        })
      : getCollection(ChargerDataTypes);
  }

  private assetChange(): void {
    this.analytics.trackEvent('Report.Filter.ChangeAsset');
    this.dataTypes = this.getDataTypes();
    this.assetForm.patchValue({ dataType: 1 });
    this.currentFilters = {
      assetType: this.getAssetType(),
      from: '',
      to: new Date().toISOString(),
      selectedAssets: [],
      reportIntervals: this.view === FiltersView.Full ? ReportIntervals['All time'] : ReportIntervals['Last 30 days'],
      periodType: this.view === FiltersView.Full ? TelematicsPeriod.All : TelematicsPeriod.Month,
      dataTypes: [1],
    };
    if (this.view !== FiltersView.Full) {
      this.assetForm.patchValue({
        interval: this.view === FiltersView.DateOnly ? TelematicsPeriod.Month : TelematicsPeriod.Month.toString(),
      });
      this.selectInterval({ value: TelematicsPeriod.Month });
    }
  }

  intervalToPeriod(interval: ReportIntervals): TelematicsPeriod {
    switch (interval) {
      case ReportIntervals['All time']:
        return TelematicsPeriod.All;
      case ReportIntervals['Last 30 days']:
        return TelematicsPeriod.Month;
      case ReportIntervals['Last year']:
        return TelematicsPeriod.Year;
      case ReportIntervals['Previous month']:
        return TelematicsPeriod.Month;
      case ReportIntervals['Previous quarter']:
        return TelematicsPeriod.Year;
      case ReportIntervals['Previous year']:
        return TelematicsPeriod.Year;
      case ReportIntervals.Custom:
        return TelematicsPeriod.Custom;
    }
  }

  selectInterval(e: MatSelectChange | { value: ReportIntervals | TelematicsPeriod }) {
    this.analytics.trackEvent('Report.Filter.SelectInterval');
    const { value } = e;
    const interval = parseInt(value);
    if (interval !== ReportIntervals.Custom && !isNil(interval) && !isNaN(interval)) {
      this.currentFilters = {
        ...this.currentFilters,
        reportIntervals: interval,
        periodType: this.intervalToPeriod(interval),
        from: '',
        to: '',
      };
      const { from, to } = this.getFromToValue(interval);
      if (from) this.currentFilters = { ...this.currentFilters, from };
      if (to) this.currentFilters = { ...this.currentFilters, to };
      this.filterChanged.emit(this.currentFilters);
    } else {
      this.openDatePicker = this.showDatePicker = true;
    }
  }

  private getFromToValue(interval: ReportIntervals): { from: string; to: string } {
    let from: moment.Moment | null;
    let to: moment.Moment | null;
    switch (interval) {
      case ReportIntervals['Last 30 days']:
        from = moment().subtract(30, 'days');
        to = moment();
        break;
      case ReportIntervals['Last year']:
        from = moment().subtract(1, 'year');
        to = moment();
        break;
      case ReportIntervals['Previous month']:
        from = moment().startOf('month').subtract(1, 'month');
        to = moment().startOf('month');
        break;
      case ReportIntervals['Previous quarter']:
        from = moment().startOf('quarter').subtract(1, 'quarter');
        to = moment().startOf('quarter');
        break;
      case ReportIntervals['Previous year']:
        from = moment().startOf('year').subtract(1, 'year');
        to = moment().startOf('year');
        break;
      default:
        from = null;
        to = moment();
        break;
    }

    return {
      from: from ? from.format() : '',
      to: to ? to.format() : '',
    };
  }

  selectDates(dates: string[]): void {
    this.analytics.trackEvent('Report.Filter.SelectIntervalDates');
    this.assetForm.patchValue({
      startDate: dates?.[0] || null,
      endDate: dates?.[1] || null,
    });
    const { startDate, endDate } = this.assetForm.value;
    const to = endDate ? moment(endDate?.toISOString()).add({ day: 1 }).toDate() : new Date();
    const from = startDate ? startDate.toISOString() : '';
    const mileageMode = moment(to).diff(moment(from), 'years') >= 1 ? TelematicsPeriod.CustomLong : TelematicsPeriod.Custom;
    this.currentFilters = { ...this.currentFilters, from, to: to?.toISOString(), periodType: mileageMode };
    this.filterChanged.emit(this.currentFilters);
  }

  clearInterval(emit = true): void {
    this.analytics.trackEvent('Report.Filter.ClearInverval');
    this.openDatePicker = this.showDatePicker = false;
    this.assetForm.patchValue({ interval: 0 });
    this.currentFilters = {
      ...this.currentFilters,
      from: '',
      to: new Date().toISOString(),
      periodType: TelematicsPeriod.All,
      reportIntervals: 0,
    };
    emit && this.filterChanged.emit(this.currentFilters);
  }

  updateSelection(selectedAssets: any[], emit = true): void {
    this.assetCount = selectedAssets.length;
    this.currentFilters = { ...this.currentFilters, selectedAssets };
    emit && this.filterChanged.emit(this.currentFilters);
  }

  selectDataType(options: string[]): void {
    const dataTypes = options.map(eventName => this.dataTypes.find(dt => dt.name === eventName)?.value ?? 0);
    this.analytics.trackEvent(`Reports.SelectType.${options.join(',')}`);
    this.currentFilters = { ...this.currentFilters, dataTypes };
    this.filterChanged.emit(this.currentFilters);
  }

  ignoreClick(e: Event) {
    e.stopPropagation();
  }

  private createForm(): UntypedFormGroup {
    this.assetForm = this.fb.group({
      assetType: [this.getAssetType()],
      interval: [this.view === FiltersView.Full ? TelematicsPeriod.All : TelematicsPeriod.Month],
      dataTypes: [[1]],
      startDate: [null],
      endDate: [null],
      assetIds: [],
    });
    return this.assetForm;
  }

  private rearrangeIntervals(): { name: any; value: number }[] {
    const first = this.assetIntervals[0];
    return [...this.assetIntervals.filter(el => el.value !== ReportIntervals['All time'] && el.value !== ReportIntervals.Custom), first];
  }

  private subscribeToClearAllFilters() {
    this.reportsService.clearReportFilters$
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(isVehicle => isVehicle === this.isVehicle),
      )
      .subscribe(() => {
        this.clearInterval(false);
        this.updateSelection([], !this.isVehicle);
        if (this.isVehicle) {
          this.assetForm.patchValue({ dataType: 1 });
          this.selectDataType(['TCO savings']);
        }
      });
  }

  private getAssetType(): AssetType {
    return this.isVehicle ? AssetType.Vehicle : AssetType.Charger;
  }
}
