import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, EMPTY, Observable, of, Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { tap, catchError, shareReplay, takeUntil, switchMap } from 'rxjs/operators';
import { PermissionType } from '../../models/permissions.enum';
import { Store } from '@ngrx/store';
import { removeUserSettings } from '../../state/core.actions';
import { Alert } from '../../../admin/models/alert.interface';
import { selectAdminAlert } from '../../../admin/state/admin.selectors';

export interface UserInfo {
  alert: Alert | null;
  id: number;
  email: string;
  roleFlag: number;
  roleName: string;
  fullName: string;
  customerName: string;
  isSystemAdmin: boolean;
  isDeviceProvisioner: boolean;
  isTestUser: boolean;
  permissions: PermissionType[];
  useMetricSystem: boolean;
  isMfaEnabled: boolean;
  profilePictureId?: number;
  profilePictureThumbnailUrl?: string;
  profilePictureUrl?: string;
  msaFlag: boolean;
  laborRate: number;
}

export enum NotificationTypeFlags {
  ServiceRequestScheduled = 1,
  ServiceRequestInProgress = 2,
  ServiceRequestCompleted = 4,
  ServiceRequestSubmitted = 8,
  QuoteSubmitted = 16,
  QuoteConfirmed = 32,
  PreventativeMaintenance = 64,
  WarrantyClaims = 128,
  ImpactReport = 256,
  WarrantyRegistrationSubmitted = 512,
  QuotePendingCustomerApproval = 1024,
  LowChargeEvent = 2048,
  ServiceRequestOnHold = 4096,
}

export interface TimeZoneInfo {
  id: string;
  displayName: string;
  offset: number;
}

export interface Holiday {
  start_date: Date;
  end_date: Date;
}

export interface UserSettings {
  emailNotificationSetting: number;
  inAppNotificationSetting: number;
  browserNotificationSetting: number;
  timezoneOffset: number | null;
  timezoneId: string | null;
  useChargerName: boolean;
  useVehicleName: boolean;
  useBrowserTimeZone: boolean;
  holidays: Holiday[];
  pmsrRemindersActive: boolean;
}

const EmptyUserSettings: UserSettings = {
  emailNotificationSetting: 0,
  inAppNotificationSetting: 0,
  browserNotificationSetting: 0,
  timezoneOffset: null,
  timezoneId: null,
  useBrowserTimeZone: true,
  useChargerName: false,
  useVehicleName: false,
  holidays: [],
  pmsrRemindersActive: false,
};

@Injectable({
  providedIn: 'root',
})
@Injectable()
export class UserService {
  constructor(private httpClient: HttpClient, private store: Store) {}

  private currentSettings: BehaviorSubject<UserSettings> = new BehaviorSubject<UserSettings>(EmptyUserSettings);
  private currentUserName$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private cachedSettings$: Observable<UserSettings> | null = null;
  private cachedUserInfo$: Observable<UserInfo> | null = null;

  userInfo$: BehaviorSubject<UserInfo | null> = new BehaviorSubject<UserInfo | null>(null);
  private reloadSettings$ = new Subject<void>();

  public get CurrentSettings() {
    return this.currentSettings.getValue();
  }

  public get CurrentUser() {
    return this.userInfo$.getValue();
  }

  public getCurrentUser(): Observable<UserInfo> {
    if (!this.cachedUserInfo$) {
      this.cachedUserInfo$ = this.httpClient.get<UserInfo>(`${environment.apiUrl}/Users/Me`).pipe(
        tap(userInfo => {
          this.userInfo$.next(userInfo);
        }),
        takeUntil(this.reloadSettings$),
        shareReplay(1),
        catchError((error: Error) => {
          console.error(error.message);
          return EMPTY;
        }),
      );
    }
    return this.cachedUserInfo$;
  }

  public getCurrentUserSettings(): Observable<UserSettings> {
    if (!this.cachedSettings$) {
      this.cachedSettings$ = this.getUserSettings().pipe(
        tap(settings => {
          this.currentSettings.next(settings);
        }),
        shareReplay(1),
        catchError((error: Error) => {
          console.error(error.message);
          throw error;
        }),
      );
    }
    return this.cachedSettings$;
  }

  getUserSettings(): Observable<UserSettings> {
    return this.httpClient.get<UserSettings>(`${environment.apiUrl}/UserSettings`);
  }

  forceReloadSettings() {
    // Calling next will complete the current cache instance
    this.reloadSettings$.next();

    // Setting the cache to null will create a new cache the
    // next time service is called
    this.cachedSettings$ = null;
    this.cachedUserInfo$ = null;
  }

  public patchCurrentUser(userInfo: any): Observable<boolean> {
    this.forceReloadSettings();
    return this.httpClient.patch<boolean>(`${environment.apiUrl}/Users`, userInfo);
  }

  setCurrentUserName(fullName: string): void {
    this.currentUserName$.next(fullName.split(' ')[0]);
  }

  getCurrentUserName$(): Observable<string> {
    return this.currentUserName$.asObservable();
  }

  public getAvailableTimezones(): Observable<TimeZoneInfo[]> {
    return this.httpClient.get<TimeZoneInfo[]>(`${environment.apiUrl}/UserSettings/GetAvailableTimezones`);
  }

  public putCurrentUserSettings(userSettings: UserSettings): Observable<boolean> {
    this.forceReloadSettings();
    return this.httpClient.put<boolean>(`${environment.apiUrl}/UserSettings`, userSettings).pipe(
      tap(result => {
        if (result) {
          this.currentSettings.next(userSettings);
          this.store.dispatch(removeUserSettings());
        }
      }),
      catchError((error: Error) => {
        console.error(error.message);
        return EMPTY;
      }),
    );
  }

  public resetOrphanPassword(userEmail: string): Observable<boolean> {
    return this.httpClient
      .post<boolean>(`${environment.apiUrl}/Users/ResetOrphanPassword`, { userEmail: userEmail })
      .pipe(catchError(() => of(false)));
  }

  getUserAlert(): Observable<Alert | null> {
    return this.getCurrentUser().pipe(
      switchMap(userInfo => {
        if (userInfo.isSystemAdmin) {
          return this.store.select(selectAdminAlert);
        } else {
          return of(userInfo.alert);
        }
      }),
    );
  }
}
