<div *ngIf="view === views.Start">
  <h1 class="title">To Setup 2 Factor Authentication</h1>
  <p>MFA has been enabled for your account and needs to be set up.</p>
  <p>
    Before you can begin and add your account, you must download and install an Authenticator app. If you haven't done that yet, you can
    find supported apps and follow step-by-step instructions in the
    <a href="../assets/docs/MFA Article Content - How to Setup Account and Use MFA - Google Docs.pdf" target="_blank"
      >install the app article</a
    >.
  </p>
  <p>If you already have an app downloaded, let's set up your account.</p>
</div>
<div *ngIf="view === views.Setup">
  <h1 class="title">Scan QR code using authentication app</h1>
  <div class="code-wrapper">
    <qrcode *ngIf="!!qrCode" [qrdata]="qrCode" [width]="132" errorCorrectionLevel="M"></qrcode>
  </div>
  <p>
    When you enable Multi-Factor Authentication, you will need to provide additional verification code when you sign in to Xosphere. To
    activate Multi-Factor Authentication, scan QR code and enter 6 digits code.
  </p>
</div>
<div *ngIf="view === views.Verify">
  <h1 class="title">Enter your authentication code</h1>
  <p>Enter the code you see in your authentication app.</p>
</div>
<form *ngIf="view !== views.Start" [formGroup]="mfaForm" (keyup.enter)="clicked()">
  <mat-form-field appearance="fill" class="password-wrapper">
    <label>Multi-Factor Authentication code</label>
    <input
      type="text"
      matInput
      placeholder="Enter your code"
      formControlName="code"
      autocomplete="off"
      maxlength="6"
      minlength="6"
      (input)="filterAllowedValues()"
    />
    <xos-error-tooltip [errors]="f.code.errors" *ngIf="f.code?.errors && f.code?.touched"> </xos-error-tooltip>
  </mat-form-field>
</form>
<button
  type="submit"
  mat-flat-button
  color="primary"
  class="mfa-button"
  (click)="clicked()"
  [disabled]="view !== views.Start && mfaForm?.invalid"
>
  {{ view === views.Start ? 'NEXT' : view === views.Setup ? 'SET UP' : 'VERIFY' }}
</button>
