import { Subject } from 'rxjs';
import { Component, ElementRef, Inject, Input, OnDestroy, Optional, Self, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, NgControl, Validators } from '@angular/forms';
import {
  MAT_LEGACY_FORM_FIELD as MAT_FORM_FIELD,
  MatLegacyFormField as MatFormField,
  MatLegacyFormFieldControl as MatFormFieldControl,
} from '@angular/material/legacy-form-field';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { FocusMonitor } from '@angular/cdk/a11y';

export class PhoneNumber {
  constructor(public area: string, public exchange: string, public subscriber: string) {}
}

@Component({
  selector: 'xos-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: PhoneInputComponent }],
})
export class PhoneInputComponent implements ControlValueAccessor, MatFormFieldControl<PhoneNumber>, OnDestroy {
  static nextId = 0;
  @ViewChild('area') areaInput!: HTMLInputElement;
  @ViewChild('exchange') exchangeInput!: HTMLInputElement;
  @ViewChild('subscriber') subscriberInput!: HTMLInputElement;

  parts: UntypedFormGroup;
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  controlType = 'xos-phone-input';
  id = `xos-phone-input-${PhoneInputComponent.nextId++}`;
  onChange = (_: any) => {};
  onTouched = () => {};

  get empty() {
    const {
      value: { area, exchange, subscriber },
    } = this.parts;

    return !area && !exchange && !subscriber;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input() userAriaDescribedBy!: string;

  @Input()
  get placeholder(): string {
    return this.controlPlaceholder;
  }
  set placeholder(value: string) {
    this.controlPlaceholder = value;
    this.stateChanges.next();
  }
  private controlPlaceholder!: string;

  @Input()
  get required(): boolean {
    return this.controlRequired;
  }
  set required(value: boolean) {
    this.controlRequired = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private controlRequired = false;

  @Input()
  get disabled(): boolean {
    return this.controlDisabled;
  }
  set disabled(value: boolean) {
    this.controlDisabled = coerceBooleanProperty(value);
    this.controlDisabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }
  private controlDisabled = false;

  @Input()
  get value(): PhoneNumber | null {
    if (this.parts.valid) {
      const {
        value: { area, exchange, subscriber },
      } = this.parts;
      return new PhoneNumber(area, exchange, subscriber);
    }
    return null;
  }
  set value(tel: PhoneNumber | null) {
    const { area, exchange, subscriber } = tel || new PhoneNumber('', '', '');
    this.parts.setValue({ area, exchange, subscriber });
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return this.parts.invalid && this.touched;
  }

  constructor(
    formBuilder: UntypedFormBuilder,
    private focusMonitor: FocusMonitor,
    private elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    this.parts = formBuilder.group({
      area: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(3)]],
      exchange: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(3)]],
      subscriber: [null, [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef);
  }

  onFocusIn() {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this.elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  autoFocusNext(control: AbstractControl, nextElement?: HTMLInputElement): void {
    if (!control.errors && nextElement) {
      this.focusMonitor.focusVia(nextElement, 'program');
    }
  }

  autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
    if (control.value.length < 1) {
      this.focusMonitor.focusVia(prevElement, 'program');
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this.elementRef.nativeElement.querySelector('.phone-container')!;
    this.userAriaDescribedBy && controlElement.setAttribute(this.userAriaDescribedBy, ids.join(' '));
  }

  onContainerClick() {
    if (this.parts.controls.subscriber.valid) {
      this.focusMonitor.focusVia(this.subscriberInput, 'program');
    } else if (this.parts.controls.exchange.valid) {
      this.focusMonitor.focusVia(this.subscriberInput, 'program');
    } else if (this.parts.controls.area.valid) {
      this.focusMonitor.focusVia(this.exchangeInput, 'program');
    } else {
      this.focusMonitor.focusVia(this.areaInput, 'program');
    }
  }

  writeValue(tel: PhoneNumber | null): void {
    this.value = tel;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  handleInput(control: AbstractControl, nextElement?: HTMLInputElement): void {
    this.autoFocusNext(control, nextElement);
    this.onChange(this.value);
  }

  filterAllowedValues(controlName: string) {
    const allowedValue = this.parts.value[controlName].replace(/[^\d]/, '');
    this.parts.patchValue({ ...this.parts.value, [controlName]: allowedValue });
  }
}
