import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreatePasswordComponent } from './core/components/create-password/create-password.component';
import { ForgotPasswordConfirmationComponent } from './core/components/forgot-password-confirmation/forgot-password-confirmation.component';
import { ForgotPasswordComponent } from './core/components/forgot-password/forgot-password.component';
import { LoginComponent } from './core/components/login/login.component';
import { AuthGuard } from './core/guards/guards/auth.guard';
import { LoginGuard } from './core/guards/guards/loginGuard.guard';
import { ExternalWidgetComponent } from './external/components/external-widget/external-widget.component';
import { EmbedWidgetOverviewComponent } from './external/components/embed-widget-overview/embed-widget-overview.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'impersonate',
    component: LoginComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'create-password',
    component: CreatePasswordComponent,
    // canActivate: [AuthGuard],
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    // canActivate: [AuthGuard],
  },
  {
    path: 'forgot-password-confirmation',
    component: ForgotPasswordConfirmationComponent,
    // canActivate: [AuthGuard],
  },
  {
    path: 'embed/overview',
    component: ExternalWidgetComponent,
  },
  {
    path: 'embed/overview/:id',
    component: EmbedWidgetOverviewComponent,
  },
  {
    path: '',
    loadChildren: () => import('./main/main.module').then(m => m.MainModule),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
