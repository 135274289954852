import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DateTimeService {
  now(): number {
    // this service is useful for testing / debugging of time-related functions
    // return new Date('2022-01-17T16:02:02.222').valueOf();

    return Date.now();
  }
}
