import { Component } from '@angular/core';
import { SieveService } from '../../../maintenance/services/sieve.service';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'xos-clear-filter',
  templateUrl: './clear-filter.component.html',
  styleUrls: ['./clear-filter.component.scss'],
})
export class ClearFilterComponent extends Toast {
  constructor(private sieveService: SieveService, protected toastrService: ToastrService, public toastPackage: ToastPackage) {
    super(toastrService, toastPackage);
  }

  clearFilter(): void {
    this.sieveService.clearAllFilters();
  }

  close(): void {
    this.toastrService.clear();
  }
}
