<div class="xos-field-label" *ngIf="title">
  <span>{{ title }}</span>
  <i *ngIf="icon" [matTooltip]="iconTooltip" matTooltipPosition="above" matTooltipClass="xos-tooltip" [class]="'xos-icon ' + icon"></i>
</div>
<mat-form-field class="xos-datepicker" appearance="fill">
  <input matInput disabled [matDatepicker]="dp" [value]="selectedDate" (dateChange)="dateChanged($event)" />
  <mat-datepicker-toggle *ngIf="!disabled" matSuffix [for]="dp"></mat-datepicker-toggle>
  <button *ngIf="selectedDate && !disabled" matSuffix mat-icon-button class="clear-suffix" (click)="clear()">
    <i class="xos-icon icon-close-circle"></i>
  </button>
  <mat-datepicker
    [dateClass]="dateClass"
    [disabled]="false"
    startView="year"
    #dp
    [calendarHeaderComponent]="datepickerMomentHeaderComponent"
  ></mat-datepicker>
</mat-form-field>
