import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'xos-not-found-requests-state',
  templateUrl: './not-found-requests-state.component.html',
  styleUrls: [
    './not-found-requests-state.component.scss',
    '../../../maintenance/components/requests-empty-state/requests-empty-state.component.scss',
  ],
})
export class NotFoundRequestsStateComponent {
  @Output() clearFilter: EventEmitter<any> = new EventEmitter<any>();
}
