import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'xos-premium-feature-screen',
  templateUrl: './premium-feature-screen.component.html',
  styleUrls: ['./premium-feature-screen.component.scss'],
})
export class PremiumFeatureScreenComponent {
  @Input() title: string = 'You’ve discovered an Advanced feature! Upgrade your Xosphere access to unlock it.';
  @Input() message: string = 'Use Charging to monitor your chargers, minimize your energy costs, and integrate your utility policy.';
  @Input() imageClass: string = 'chargers-preview';
  @Input() buttonText: string = 'Contact sales';
  @Input() mailToEnabled: boolean = true;
  @Output() buttonClicked: EventEmitter<any> = new EventEmitter<any>();

  contactSales(): void {
    this.mailToEnabled ? (window.location.href = 'mailto:sales@xostrucks.com') : this.buttonClicked.emit();
  }
}
