<div>
  <i class="xos-icon icon-trash"></i>
  <span>Attachment</span>
  <ng-container *ngIf="title">
    for <span>{{title}}</span>
  </ng-container>
  was deleted!
</div>
<button (click)="close()">
  <i class="xos-icon icon-close-white"></i>
</button>
