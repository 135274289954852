<xos-burger-menu
  (click)="toggleSidenav()"
  [matTooltip]="getHoverText()"
  matTooltipPosition="after"
  matTooltipClass="xos-tooltip burger-menu-tooltip"
  class="burger-menu"
></xos-burger-menu>
<xos-header-menu></xos-header-menu>
<div class="logo-wrapper">
  <a class="logo" routerLink="/">
    <img src="../../../../assets/images/logo-white.svg" class="header-logo" alt="logo" />
  </a>
</div>
