import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatCalendar, MatDatepicker } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { TimezonesService } from '../../../core/services/timezones.service';

@Component({
  selector: 'xos-datepicker-header',
  templateUrl: './datepicker-header.component.html',
  styleUrls: ['./datepicker-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerHeaderComponent<D> implements OnInit {
  get periodLabel(): string {
    return this.dateAdapter.format(this.calendar.activeDate, this.dateFormats.display.monthYearLabel);
  }

  constructor(
    private calendar: MatCalendar<D>,
    private dateAdapter: DateAdapter<D>,
    private datePicker: MatDatepicker<D>,
    private cdr: ChangeDetectorRef,
    private timezonesService: TimezonesService,
    @Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats,
  ) {}

  ngOnInit() {
    this.calendar.activeDate = this.getCurrentTZDate();
  }

  todayDay(): void {
    this.calendar.activeDate = this.getCurrentTZDate();
    this.calendar._goToDateInView(this.getCurrentTZDate(), 'month');
    this.datePicker.select(this.getCurrentTZDate());
    this.datePicker.close();
  }

  previousMonth(): void {
    this.calendar.activeDate = this.dateAdapter.addCalendarMonths(this.calendar.activeDate, -1);
  }

  nextMonth(): void {
    this.calendar.activeDate = this.dateAdapter.addCalendarMonths(this.calendar.activeDate, 1);
  }

  private getCurrentTZDate(): D {
    const currentTZDate = this.timezonesService.getCurrentLocalDate();
    return this.dateAdapter.createDate(currentTZDate.year(), currentTZDate.month(), currentTZDate.date());
  }
}
