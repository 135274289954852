<div class="login-form-wrapper" [ngClass]="{ centered: hideDetails }">
  <img src="./assets/images/logo.svg" class="logo" alt="logo" />
  <xos-login-form (mfaDisplayed)="mfaChanged()"></xos-login-form>
  <p *ngIf="!hideDetails" class="terms">
    Xosphere is a digital workspace for fleet management. By signing in you are accepting
    <a target="_blank" rel="noopener noreferrer" href="https://xostrucks.com/msa/"> Terms of service </a>
  </p>
</div>
<div *ngIf="!hideDetails" class="slider-wrapper">
  <div class="slider">
    <div class="images-wrapper">
      <img src="../../../../assets/images/map.png" alt="map" class="map" />
      <img src="../../../../assets/images/macbook.png" alt="macbook" class="macbook" />
    </div>
    <div class="slider-title">Your trusted partner in fleet management.</div>
    <div class="slider-sub-title">You focus on growing your business. We’ll handle the rest.</div>
  </div>
</div>
