<ngx-skeleton-loader *ngIf="!optionCategories" count="10" [theme]="formStyles"></ngx-skeleton-loader>
<div *ngIf="optionCategories" class="configuration">
  <h3>Weight & Dimensions</h3>
  <span>{{ truck?.weightDimension || 'None' }}</span>
  <h3>Battery</h3>
  <span>{{ truck?.battery || 'None' }}</span>
  <ng-container *ngFor="let category of optionCategories">
    <h3>{{ category.name }}</h3>
    <ng-container *ngIf="category.options.length; else none">
      <div *ngFor="let option of category.options" class="option-details">
        <span class="name">{{ option.name }}</span>
        <span class="price">(+ ${{ option.price | number }})</span>
      </div>
    </ng-container>
    <ng-template #none>
      <span class="name">None</span>
    </ng-template>
  </ng-container>
  <h3>Additional information</h3>
  <span>{{ truck?.additionalInformation?.notes || truck?.notes || 'None' }}</span>
  <h3>Quantity</h3>
  <span>{{ truck?.quantity }}</span>
  <ng-container *ngIf="!selected">
    <h3 class="price-estimation">
      Vehicle price estimate
      <i
        class="icon-info xos-icon"
        matTooltipPosition="above"
        matTooltipClass="xos-tooltip target-tooltip"
        matTooltip="Confirmed pricing will be provided once we've received and reviewed your quote."
      >
      </i>
    </h3>
    <ul>
      <li>
        <span class="price-title">Price estimate</span>
        <span class="price-full">
          $ <span>{{ price?.totalPrice | number }}</span>
        </span>
      </li>
      <li>
        <span class="price-title">Configuration base price</span>
        <span class="price">{{ price?.basicPrice | number }}</span>
      </li>
      <li>
        <span class="price-title">Add-ons</span>
        <span class="price">{{ price?.addOns | number }}</span>
      </li>
    </ul>
  </ng-container>
</div>
