<xos-spinner [@startLoading]="loading ? 'loading' : 'preloading'" [visible]="true" [variant]="spinnerVariant"></xos-spinner>
<div *ngIf="!isMfaVisible" class="login-wrapper">
  <h1>Sign in</h1>
  <form [formGroup]="loginForm" (ngSubmit)="login()" (keyup.enter)="login()" class="login-form responsive-form">
    <div class="label confirm-message" *ngIf="isImpersonating">
      Confirm your admin password to sign in as <b>{{ impersonatedUser?.email }}</b>
    </div>
    <mat-form-field appearance="fill" *ngIf="isImpersonating === false">
      <label for="email">Email</label>
      <input type="email" id="email" matInput placeholder="Email" formControlName="email" (blur)="onBlur()" />
      <xos-error-tooltip [errors]="f.email.errors" *ngIf="f.email?.errors && f.email?.touched"></xos-error-tooltip>
      <mat-error *ngIf="f.email.hasError('required')">Required field</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill" class="password-wrapper" [class.error]="f.password?.errors?.emailOrPassword">
      <label for="password">Password</label>
      <input
        type="password"
        id="password"
        matInput
        [placeholder]="isImpersonating ? 'Admin\'s password' : 'Password'"
        formControlName="password"
        xosTogglePassword
      />
      <xos-error-tooltip [errors]="f.password.errors" *ngIf="f.password?.errors && f.password?.touched"> </xos-error-tooltip>
      <mat-error *ngIf="f.password.hasError('required')">Required field</mat-error>
      <mat-error *ngIf="f.password.hasError('emailOrPassword')">
        Your email or password is incorrect. If you don't remember your password, reset it now. If that doesn't fix the issue, contact us at
        <span>support@xostrucks.com.</span>
      </mat-error>
    </mat-form-field>
    <div class="additional-buttons" *ngIf="isImpersonating === false">
      <mat-checkbox formControlName="rememberMe" class="xos-checkbox">Remember me </mat-checkbox>
      <a routerLink="/forgot-password" class="forgot-password">Forgot password?</a>
    </div>
    <button #submitBtn type="submit" mat-flat-button color="primary" class="width-100">Sign In</button>
  </form>
</div>
<xos-mfa
  *ngIf="isMfaVisible"
  [qrCode]="setupQRCode"
  [view]="mfaView"
  [wrongCode]="wrongMfaCode"
  (passedCode)="verifyCode($event)"
></xos-mfa>
