export enum Errors {
  emailOrPassword = 'emailOrPassword',
  emptyEmail = 'emptyEmail',
  emailValidation = 'emailValidation',
  emptyPassword = 'emptyPassword',
  oldPasswordEmpty = 'oldPasswordEmpty',
  passwordValidationMatch = 'passwordValidationMatch',
  passwordStrength = 'passwordStrength',
  duplicateValue = 'duplicateValue',
  wrongCode = 'wrongCode',
}

export interface ErrorData {
  title: string;
  description?: string;
  messages?: string[];
}

export const ValidationMessages: Map<Errors, ErrorData> = new Map([
  [
    Errors.emailOrPassword,
    {
      title: 'Wrong password or email',
      description: `Your email or password is incorrect. If you don't remember your password, reset it now.\n
If that doesn't fix the issue, contact us at <span>support@xostrucks.com.</span>`,
    },
  ],
  [
    Errors.emptyEmail,
    {
      title: 'Required field.',
    },
  ],
  [
    Errors.emptyPassword,
    {
      title: 'Required field.',
    },
  ],
  [
    Errors.emailValidation,
    {
      title: 'Please enter a valid email address',
    },
  ],
  [
    Errors.oldPasswordEmpty,
    {
      title: 'Password validation',
      description: 'Old password cannot be empty when setting new password.',
    },
  ],
  [
    Errors.passwordValidationMatch,
    {
      title: 'Password validation',
      description: 'This field needs to match the one above it.',
    },
  ],
  [
    Errors.passwordStrength,
    {
      title: 'Password validation',
      messages: [
        'Use at least 8 characters',
        'Use a mixture of both uppercase and lowercase characters',
        'Use at least one special character, e.g. !@#$%^&*()',
      ],
    },
  ],
  [
    Errors.duplicateValue,
    {
      title: 'Nickname already exists.',
    },
  ],
  [
    Errors.wrongCode,
    {
      title: 'Wrong code',
    },
  ],
]);
