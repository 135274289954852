<ul class="time-ranges">
  <li *ngFor="let item of timeRanges; let i = index">
    <span>{{ item.range[0] }} - {{ item.range[1] }}</span>
    <button mat-icon-button (click)="delete(item.value, i)">
      <i class="xos-icon icon-close-white"></i>
    </button>
  </li>
</ul>

<xos-energy-pricing-chart [hourPrices]="hourPrices" [ranges]="ranges"></xos-energy-pricing-chart>

<div class="sliders-wrapper">
  <ngx-slider
    *ngFor="let slider of sliders; let i = index"
    [value]="slider.value"
    [highValue]="slider.highValue"
    [options]="slider.option"
    (userChange)="onSlideMove($event, i)"
    (userChangeEnd)="userChangeEnd()"
    [manualRefresh]="manualRefresh"
    [ngClass]="{ inactive: i < sliders.length - 1 }"
  ></ngx-slider>
</div>

<button mat-button class="add-range" [disabled]="!emptyRanges.length" (click)="addRange()">+ Add time range</button>
