export enum ReportLabels {
  Odo = 1, // Odometer data
  TimeInService = 2, // Time in service
  SocUsed = 3, // Soc used
  SocCharged = 4, // Soc charged
  EnergyConsumed = 5, // Energy consumed
  EnergyCharged = 6, // Energy charged
  EnergyDriven = 7, // Energy driven
  EnergyIdled = 8, // Energy idled
  EnergyRecovered = 9, // Energy recovered
  EnergyUsed = 10, // Energy used
  TCOSavings = 100, // TCO Savings
  CO2Savings = 101, // CO2Savings
}

export const VehicleReportLabels = [
  ReportLabels.Odo,
  ReportLabels.TCOSavings,
  ReportLabels.CO2Savings,
  ReportLabels.TimeInService,
  ReportLabels.SocUsed,
  ReportLabels.SocCharged,
  ReportLabels.EnergyUsed,
  ReportLabels.EnergyCharged,
];
