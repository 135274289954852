import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, Subject } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { TimezonesService } from 'src/app/core/services/timezones.service';
import { VehicleReportLabels } from 'src/app/shared/models/report-labels';
import { environment } from 'src/environments/environment';
import {
  ExtendedVehicleInfo,
  TelematicsPeriod,
  TelematicsData,
  TelematicsVehicleInfo,
  TotalMilesDrivenData,
  VehicleStatus,
  ChargingStatus,
  VehicleVersionInfo,
} from '../models/telematics.interface';
import { VehicleStatuses } from '../models/vehicle-statuses.constant';
import { BasicChargingSchedule } from '../../shared/modules/charging-schedule/models/charging-schedule.interface';

@Injectable({
  providedIn: 'root',
})
export class TelematicsService {
  private filterByValue$: Subject<string> = new Subject<string>();
  private filterByStatus$: Subject<VehicleStatus> = new Subject<VehicleStatus>();
  private clearAllFilters$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private hoveredVehicle$: Subject<{ vehicle: TelematicsVehicleInfo | null; index: number }> = new Subject<{
    vehicle: TelematicsVehicleInfo | null;
    index: number;
  }>();
  private selectedVehicle$: Subject<{ target: TelematicsVehicleInfo | null; center?: boolean }> = new Subject<{
    target: TelematicsVehicleInfo | null;
    center?: boolean;
  }>();
  vehicles$: Subject<{ vehicles: TelematicsVehicleInfo[]; firstUpdate?: boolean }> = new Subject<{
    vehicles: TelematicsVehicleInfo[];
    firstUpdate?: boolean;
  }>();
  telematicsUpdateNeeded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private httpClient: HttpClient, private timezonesService: TimezonesService) {}

  selectVehicle(value: { target: TelematicsVehicleInfo | null; center?: boolean }): void {
    this.selectedVehicle$.next(value);
  }

  hoverVehicle(vehicle: TelematicsVehicleInfo | null, index: number): void {
    this.hoveredVehicle$.next({ vehicle, index });
  }

  setFilterByValue(value: string): void {
    this.filterByValue$.next(value);
  }

  setFilterByStatus(value: VehicleStatus): void {
    this.filterByStatus$.next(value);
  }

  setClearAll(): void {
    this.clearAllFilters$.next(true);
  }

  getClearAll(): Observable<boolean> {
    return this.clearAllFilters$.asObservable();
  }

  getSelectedVehicleObservable$(): Observable<{ target: TelematicsVehicleInfo | null; center?: boolean }> {
    return this.selectedVehicle$.asObservable();
  }

  getHoveredVehicleObservable$(): Observable<{ vehicle: TelematicsVehicleInfo | null; index: number }> {
    return this.hoveredVehicle$.asObservable();
  }

  getFilterByValueObservable$(): Observable<string> {
    return this.filterByValue$.asObservable();
  }

  getFilterByStatusObservable$(): Observable<VehicleStatus> {
    return this.filterByStatus$.asObservable();
  }

  getMileage(mode: TelematicsPeriod): Observable<TelematicsData> {
    return this.timezonesService.getTimeZoneOffsetObservable().pipe(
      switchMap(offset => {
        return this.httpClient.get<TelematicsData>(`${environment.apiUrl}/VehiclesTelematics/${mode}/${offset}`);
      }),
    );
  }

  getTotalVehicleReport(): Observable<TotalMilesDrivenData> {
    return this.timezonesService.getTimeZoneOffsetObservable().pipe(
      switchMap(offset => {
        const labels = VehicleReportLabels.map(l => 'data=' + l).join('&');
        return this.httpClient.get<TotalMilesDrivenData>(`${environment.apiUrl}/VehiclesTelematics/report/total/${offset}?${labels}`);
      }),
    );
  }

  getCurrentVehiclesStatus(): Observable<TelematicsVehicleInfo[]> {
    return this.timezonesService.getTimeZoneOffsetObservable().pipe(
      switchMap(offset => {
        return this.httpClient
          .get<TelematicsVehicleInfo[]>(`${environment.apiUrl}/VehiclesTelematics/${offset}`)
          .pipe(map(vehicles => vehicles.map(item => ({ ...item, status: VehicleStatuses.find(el => el.id === item.telematics.state) }))));
      }),
    );
  }

  getDailyDistance(vehicleId: number): Observable<number> {
    return this.timezonesService
      .getTimeZoneOffsetObservable()
      .pipe(
        switchMap(offset => this.httpClient.get<number>(`${environment.apiUrl}/VehiclesTelematics/${offset}/${vehicleId}/dailyDistance`)),
      );
  }

  filterVehicles<T>(vehicles: T[], term: string, nestedKey?: string): T[] {
    const getFilter = (el: any, field: string) => el[field].toLowerCase().includes(term.toLowerCase());
    // @ts-ignore
    return vehicles.filter(item => getFilter(item[nestedKey] || item, 'nickName') || getFilter(item[nestedKey] || item, 'vin'));
  }

  getVehicleLocationAddress(latitude: any, longitude: any): Observable<string> {
    return this.httpClient
      .get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${environment.mapbox.accessToken}`)
      .pipe(
        map((data: any) => data.features.find((item: any) => item.id.startsWith('address') || item.id.startsWith('poi'))?.place_name || ''),
        catchError((error: Error) => {
          console.error(error.message);
          return EMPTY;
        }),
      );
  }

  getVehicleInfoById(id: number): Observable<ExtendedVehicleInfo> {
    return this.httpClient.get<ExtendedVehicleInfo>(`${environment.apiUrl}/Vehicles/${id}`).pipe(
      catchError((error: Error) => {
        console.error(error.message);
        return EMPTY;
      }),
    );
  }

  getVehicleVersionById(id: number): Observable<VehicleVersionInfo> {
    return this.httpClient.get<VehicleVersionInfo>(`${environment.apiUrl}/VehiclesTelematics/GetVersionById/${id}`).pipe(
      catchError((error: Error) => {
        console.error(error.message);
        return EMPTY;
      }),
    );
  }

  orderVehiclesByStatusThenName(list: TelematicsVehicleInfo[], useVehicleNickName: boolean = false): TelematicsVehicleInfo[] {
    const statusOrder = [3, 1, 2, 4, 5, 6, 0];
    return statusOrder.reduce(
      (acc: any, cur: number) => [
        ...acc,
        ...list
          .filter(el => (el.status?.id || 0) === cur)
          .sort((el1, el2) => {
            const displayNameEl1 = useVehicleNickName ? el1.nickName || el1.vin : el1.vin;
            const displayNameEl2 = useVehicleNickName ? el2.nickName || el2.vin : el2.vin;
            return displayNameEl1.localeCompare(displayNameEl2);
          }),
      ],
      [],
    );
  }

  startVehicleCharging(vehicleId: number): Observable<boolean> {
    return this.httpClient.post<boolean>(`${environment.apiUrl}/CommandManager/StartCharging/${vehicleId}`, { vehicleId });
  }

  stopVehicleCharging(vehicleId: number): Observable<boolean> {
    return this.httpClient.post<boolean>(`${environment.apiUrl}/CommandManager/StopCharging/${vehicleId}`, { vehicleId });
  }

  getVehicleChargingStatus(vehicleId: number): Observable<ChargingStatus> {
    return this.httpClient.get<ChargingStatus>(`${environment.apiUrl}/CommandManager/GetVehicleChargingStatus/${vehicleId}`).pipe(
      catchError((error: Error) => {
        console.error(error.message);
        return EMPTY;
      }),
    );
  }

  getVehicleChargingSchedule(vehicleId: number, timezoneOffset: number): Observable<BasicChargingSchedule> {
    return this.httpClient
      .get<BasicChargingSchedule>(`${environment.apiUrl}/CommandManager/GetChargingSchedule/${timezoneOffset}/${vehicleId}`)
      .pipe(
        map(schedule =>
          schedule
            ? { activeRanges: schedule.activeRanges.map(range => (range.end === '23:59:59' ? { ...range, end: '24:00:00' } : range)) }
            : schedule,
        ),
        catchError((error: Error) => {
          console.error(error.message);
          return EMPTY;
        }),
      );
  }

  setVehicleChargingSchedule(schedule: BasicChargingSchedule, vehicleId: number): Observable<boolean> {
    const adaptedSchedule = {
      activeRanges: schedule.activeRanges.map(range => (range.end === '24:00:00' ? { ...range, end: '23:59:59' } : range)),
    };
    return this.timezonesService
      .getTimeZoneOffsetObservable()
      .pipe(
        switchMap(offset =>
          this.httpClient.put<boolean>(`${environment.apiUrl}/CommandManager/SetChargingSchedule/${offset}/${vehicleId}`, adaptedSchedule),
        ),
      );
  }

  clearVehicleChargingSchedule(vehicleId: number): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${environment.apiUrl}/CommandManager/DeleteChargingSchedule/${vehicleId}`);
  }
}
