<h1>Password recovery</h1>
<p class="subheading">If we found user with the email address you provided in this workspace, you will receive an email from us shortly.</p>
<p class="subheading">If you do not receive an email within a few minutes, please check your spam folder or email us at support@xostrucks.com.</p>
<p class="subheading">Unsure which email you used for your Xosphere account? <a href="mailto:support@xostrucks.com">Contact Us</a></p>
<form [formGroup]="forgotPasswordConfirmationForm" (ngSubmit)="createPassword()" class="login-form">

  <p class="subheading">Do you remember the password?</p>
  <a mat-stroked-button color="link" class="width-100 sign-in-button" href="login">Sign In</a>

</form>
