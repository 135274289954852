<div
  role="group"
  class="phone-container"
  [formGroup]="parts"
  [attr.aria-labelledby]="formField?.getLabelId()"
  (focusin)="onFocusIn()"
  (focusout)="onFocusOut($event)"
  [ngClass]="{ disabled }"
>
  <input
    class="phone-element"
    formControlName="area"
    size="3"
    maxLength="3"
    aria-label="Area code"
    placeholder="000"
    matInput
    (input)="filterAllowedValues('area'); handleInput(parts.controls.area, exchange)"
    #area
  />
  <span class="phone-spacer">&ndash;</span>
  <input
    class="phone-element"
    formControlName="exchange"
    maxLength="3"
    size="3"
    aria-label="Exchange code"
    placeholder="000"
    matInput
    (input)="filterAllowedValues('exchange'); handleInput(parts.controls.exchange, subscriber)"
    (keyup.backspace)="autoFocusPrev(parts.controls.exchange, area)"
    #exchange
  />
  <span class="phone-spacer">&ndash;</span>
  <input
    class="phone-element"
    formControlName="subscriber"
    maxLength="4"
    size="4"
    aria-label="Subscriber number"
    placeholder="0000"
    matInput
    (input)="filterAllowedValues('subscriber'); handleInput(parts.controls.subscriber)"
    (keyup.backspace)="autoFocusPrev(parts.controls.subscriber, exchange)"
    #subscriber
  />
</div>
