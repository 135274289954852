import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { catchError, finalize, last, map, mergeMap, share, tap } from 'rxjs/operators';
import { PermissionType } from 'src/app/core/models/permissions.enum';
import { NotificationTypeFlags, UserService, UserSettings } from 'src/app/core/services/auth/user.service';
import { MembershipService } from 'src/app/core/services/membership/membership.service';
import { AssetType } from 'src/app/maintenance/models/tabs.enum';
import { SidenavService } from 'src/app/shared/services/sidenav.service';
import { startWithTap } from 'src/app/shared/utils/start-with-tap';
import { environment } from '../../../environments/environment';
import {
  NotificationDataImpactReport,
  NotificationDataLowCharge,
  NotificationDataQuote,
  NotificationDataReminder,
  NotificationDataServiceRequest,
  NotificationDataWarrantyRegistration,
  NotificationMessage,
  NotificationReminderType,
  NotificationState,
  NotificationType,
  NotificationTypeStrings,
  NotificationTypeSubtype,
  WarrantyClaimNotificationData,
  WCStatusMessages,
} from '../models/notifications.interface';
import { round } from 'lodash';

@Injectable()
export class NotificationsService {
  notificationsCountUnreadStore!: BehaviorSubject<number>;
  notificationsCountAllStore!: BehaviorSubject<number>;
  notificationsStore!: BehaviorSubject<NotificationMessage[]>;
  incomingNotification = new BehaviorSubject<NotificationMessage | null>(null);
  settings$!: Observable<UserSettings>;
  loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private notifications$!: Observable<NotificationMessage[]>;
  private notificationsCountAll$!: Observable<number>;
  private notificationsCountUnread$!: Observable<number>;

  get currentNotifications$(): Observable<NotificationMessage[]> {
    return this.notificationsStore.asObservable();
  }

  isServiceRequestUnread(id: number): boolean {
    const notificationsForSR = this.notificationsStore
      .getValue()
      .filter(
        x =>
          Number(x.notificationType === NotificationType.ServiceRequest && (x.data as NotificationDataServiceRequest).caseId) === id &&
          x.notificationState == NotificationState.New,
      );
    return notificationsForSR.length > 0;
  }

  get incomingNotificationObservable$(): Observable<NotificationMessage | null> {
    return this.incomingNotification.asObservable();
  }

  get currentNotificationsCountAll$(): Observable<number> {
    return this.notificationsCountAllStore;
  }

  get currentNotificationsCountUnread$(): Observable<number> {
    return this.notificationsCountUnreadStore;
  }

  constructor(
    private httpClient: HttpClient,
    private userService: UserService,
    private sidenavService: SidenavService,
    private membershipService: MembershipService,
  ) {}

  initialize() {
    this.notificationsStore = new BehaviorSubject<NotificationMessage[]>([]);
    this.notificationsCountUnreadStore = new BehaviorSubject<number>(0);
    this.notificationsCountAllStore = new BehaviorSubject<number>(0);
    this.notifications$ = this.getNotifications();

    this.notificationsCountAll$ = this.getNotificationsCountAll();
    this.notificationsCountUnread$ = this.getNotificationsCountUnread();

    this.settings$ = this.userService.getCurrentUserSettings();
    this.settings$.subscribe(_ => {
      this.notificationsCountUnread$.subscribe(count => {
        this.notificationsCountUnreadStore.next(count);
      });
      this.notificationsCountAll$.subscribe();
    });
  }

  updateNotifications(): void {
    this.notifications$.subscribe(notifications => {
      this.notificationsStore.next(notifications);

      const unreadNotifications = notifications.filter(n => n.notificationState === NotificationState.New);
      this.notificationsCountAllStore.next(notifications.length);
      this.notificationsCountUnreadStore.next(unreadNotifications.length);
    });
  }

  incrementCounters(): void {
    this.notificationsCountAllStore.next(this.notificationsCountAllStore.getValue() + 1);
    this.notificationsCountUnreadStore.next(this.notificationsCountUnreadStore.getValue() + 1);
  }

  resetCounters(): void {
    this.notificationsCountAllStore.next(0);
    this.notificationsCountUnreadStore.next(0);
  }

  openNotificationCenterSideNav(): void {
    this.updateNotifications();
    this.sidenavService.openNotificationSideNav();
  }

  closeNotificationCenterSideNav(): void {
    // mark all as read when closing notification center
    this.markAllRead().subscribe(result => {
      this.resetCounters();
      if (!result) {
        console.error('MarkNotificationAsRead returned false');
      } else {
        // if everything ok, update current notifications
        this.updateNotifications();
      }
    });

    this.sidenavService.closeNotificationSideNav();
    this.loading.next(false);
  }

  isNotificationCenterOpen(): boolean {
    return this.sidenavService.isNotificationSideNavOpen.getValue();
  }

  toggleNotificationCenterSideNav(): void {
    const isOpen = this.sidenavService.isNotificationSideNavOpen.getValue();
    isOpen ? this.closeNotificationCenterSideNav() : this.openNotificationCenterSideNav();
  }

  getIsNotificationCenterSideNavOpenObservable(): Observable<boolean> {
    return this.sidenavService.isNotificationSideNavOpen.asObservable();
  }

  pushNotification(notification: NotificationMessage) {
    this.incomingNotification.next(notification);
    this.updateNotifications();
  }

  getNotifications(): Observable<NotificationMessage[]> {
    return this.httpClient.get<NotificationMessage[]>(`${environment.apiUrl}/Notifications`).pipe(
      startWithTap(() => {
        this.loading.next(true);
      }),
      map(notifications =>
        notifications.filter(item => this.isNotificationAllowed(this.userService.CurrentSettings.inAppNotificationSetting, item)),
      ),
      share(),
      catchError((error: Error) => {
        console.error(error.message);
        return EMPTY;
      }),
      finalize(() => {
        this.loading.next(false);
      }),
    );
  }

  getNotificationsCountAll(): Observable<number> {
    return this.httpClient.get<number>(`${environment.apiUrl}/Notifications/GetAllCount`).pipe(
      catchError((error: Error) => {
        console.error(error.message);
        return EMPTY;
      }),
    );
  }

  getNotificationsCountUnread(): Observable<number> {
    return this.httpClient.get<number>(`${environment.apiUrl}/Notifications/GetUnreadCount`).pipe(
      catchError((error: Error) => {
        console.error(error.message);
        return EMPTY;
      }),
    );
  }

  markAsRead(notificationId: number): Observable<boolean> {
    return this.httpClient
      .put<boolean>(`${environment.apiUrl}/Notifications/MarkNotificationAsRead?notificationId=${notificationId}`, '')
      .pipe(
        catchError((error: Error) => {
          console.error(error.message);
          return EMPTY;
        }),
        tap(success => {
          const unreadCount = this.notificationsCountUnreadStore.getValue();
          if (success && unreadCount > 0) {
            this.notificationsCountUnreadStore.next(unreadCount - 1);
          }
        }),
      );
  }

  clearAll(): Observable<boolean> {
    return this.notifications$.pipe(
      last(),
      mergeMap(notifications => {
        const notificationIds = notifications.map(n => n.id);
        return this.httpClient
          .put<boolean>(`${environment.apiUrl}/Notifications/MarkNotificationsAsDeleted`, {
            notificationIds: notificationIds,
          })
          .pipe(
            catchError((error: Error) => {
              console.error(error.message);
              return EMPTY;
            }),
          );
      }),
    );
  }

  markAllRead(): Observable<boolean> {
    return this.notifications$.pipe(
      last(),
      mergeMap(notifications => {
        const unreadNotificationIds = notifications.filter(n => n.notificationState === NotificationState.New).map(n => n.id);
        return this.httpClient
          .put<boolean>(`${environment.apiUrl}/Notifications/MarkNotificationsAsRead`, {
            notificationIds: unreadNotificationIds,
          })
          .pipe(
            catchError((error: Error) => {
              console.error(error.message);
              return EMPTY;
            }),
          );
      }),
    );
  }

  private formatServiceRequestNotification(notification: NotificationMessage, isHtml: boolean = false, isBrowser: boolean = false): string {
    const strongBegin = isHtml ? '<strong>' : '';
    const strongEnd = isHtml ? '</strong>' : '';
    const browserStartingMessage = isBrowser ? 'Xos Trucks ' : '';
    const serviceMessage = browserStartingMessage ? 'service' : 'Service';
    const notificationData = notification.data as NotificationDataServiceRequest;
    const vehicle = this.userService.CurrentSettings.useVehicleName
      ? notificationData.vehicleNickName || notificationData.vehicleVin
      : notificationData.vehicleVin;
    const charger = this.userService.CurrentSettings.useChargerName
      ? notificationData.chargerShortName + ' - ' + notificationData.chargerNickname
      : notificationData.chargerShortName + ' ' + notificationData.chargerSerialNumber;
    return `${strongBegin}${browserStartingMessage}${serviceMessage} request #${
      notificationData.caseNumber
    }${strongEnd} for ${strongBegin}${notificationData.vehicleVin ? vehicle : charger}${strongEnd} ${NotificationTypeStrings.get(
      notification.notificationTypeSubtype,
    )}!`;
  }

  private formatWarrantyRegistrationNotification(
    notification: NotificationMessage,
    isHtml: boolean = false,
    isBrowser: boolean = false,
  ): string {
    const strongBegin = isHtml ? '<strong>' : '';
    const strongEnd = isHtml ? '</strong>' : '';
    const browserStartingMessage = isBrowser ? 'Xos Trucks ' : '';
    const wrMessage = browserStartingMessage ? 'warranty registration' : 'Warranty registration';
    const wr = notification.data as NotificationDataWarrantyRegistration;
    return `${strongBegin}${browserStartingMessage}${wrMessage} #${wr.displayId}${strongEnd} ${NotificationTypeStrings.get(
      notification.notificationTypeSubtype,
    )}!`;
  }

  private formatQuoteNotification(notification: NotificationMessage, isHtml: boolean = false, isBrowser: boolean = false): string {
    const strongBegin = isHtml ? '<strong>' : '';
    const strongEnd = isHtml ? '</strong>' : '';
    const browserStartingMessage = isBrowser ? 'Xos Trucks ' : '';
    const quoteMessage = browserStartingMessage ? 'quote' : 'Quote';
    const quote = notification.data as NotificationDataQuote;
    return `${strongBegin}${browserStartingMessage}${quoteMessage} #${quote.quoteNumber}${strongEnd} for ${strongBegin}${
      quote.truckType
    }${strongEnd} ${NotificationTypeStrings.get(notification.notificationTypeSubtype)}!`;
  }

  private formatPMSRNotification(notification: NotificationMessage, isHtml: boolean = false, isBrowser: boolean = false): string {
    const strongBegin = isHtml ? '<strong>' : '';
    const strongEnd = isHtml ? '</strong>' : '';
    const browserStartingMessage = isBrowser ? 'Xos Trucks ' : '';
    const pmsrMessage = browserStartingMessage ? 'preventative maintenance' : 'Preventative maintenance';
    const pmsr = notification.data as NotificationDataReminder;
    const vehicleName = this.userService.CurrentSettings.useVehicleName ? pmsr.vehicleNickName : pmsr.vehicleVin;
    const metric = this.userService.CurrentUser?.useMetricSystem ? 'kilometers' : 'miles';
    const dueText =
      pmsr.reminderType === NotificationReminderType.Miles ? `${round(pmsr.distanceRemaining, 1)} ${metric}` : `${pmsr.timeRemaining} days`;
    return `${strongBegin}${browserStartingMessage}${pmsrMessage}${strongEnd} for ${strongBegin}${vehicleName}${strongEnd} due in ${strongBegin}${dueText}${strongEnd}.`;
  }

  private formatWCNotification(notification: NotificationMessage, isHtml: boolean = false, isBrowser: boolean = false): string {
    const strongBegin = isHtml ? '<strong>' : '';
    const strongEnd = isHtml ? '</strong>' : '';
    const browserStartingMessage = isBrowser ? 'Xos Trucks ' : '';
    const wcMessage = browserStartingMessage ? 'warranty claim' : 'Warranty claim';
    const wcData = notification.data as WarrantyClaimNotificationData;
    const vin = wcData.vin;
    const statusText = WCStatusMessages.get(wcData.status);
    return `${strongBegin}${browserStartingMessage}${wcMessage} #${wcData.caseNumber}${strongEnd} for ${strongBegin}${vin}${strongEnd} ${statusText}`;
  }

  private formatImpactReportNotification(notification: NotificationMessage, isHtml: boolean = false): string {
    const strongBegin = isHtml ? '<strong>' : '';
    const strongEnd = isHtml ? '</strong>' : '';
    const data = notification.data as NotificationDataImpactReport;
    return `${strongBegin}Happy ${data.monthCount} months${strongEnd} with Xos! You've driven ${strongBegin}${data.distanceDriven} ${
      this.userService.CurrentUser?.useMetricSystem ? 'kilometers' : 'miles'
    }${strongEnd}, saved ${strongBegin}$${data.tcoSavings}${strongEnd}, avoided ${strongBegin}${
      data.cO2Savings
    } pounds${strongEnd} of emissions.`;
  }

  private formatLowChargeNotification(notification: NotificationMessage, isHtml: boolean = false, isBrowser: boolean = false): string {
    const strongBegin = isHtml ? '<strong>' : '';
    const strongEnd = isHtml ? '</strong>' : '';
    const browserStartingMessage = isBrowser ? 'Xos Trucks ' : '';
    const message = 'Critical Low SoC';
    const data = notification.data as NotificationDataLowCharge;
    const vehicleName = this.userService.CurrentSettings?.useVehicleName ? data.nickName : data.vin;
    return `${strongBegin}${browserStartingMessage}${message}${strongEnd}: ${vehicleName} has a low state of charge`;
  }

  showBrowserNotification(message: NotificationMessage) {
    // Let's check if the browser supports notifications
    const notificationText = this.getFormattedNotification(message, false, true);

    if (!('Notification' in window)) {
      console.warn('This browser does not support desktop notification');
    }

    // Let's check whether notification permissions have already been granted
    else if (Notification.permission === 'granted') {
      // If it's okay let's create a notification
      new Notification(notificationText);
    }

    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then(function (permission) {
        // If the user accepts, let's create a notification
        if (permission === 'granted') {
          new Notification(notificationText);
        }
      });
    }

    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them any more.
  }

  getFormattedNotification(notification: NotificationMessage, isHtml = true, isBrowser: boolean = false): string {
    let formattedNotification = '';
    switch (notification.notificationType) {
      case NotificationType.ServiceRequest:
        formattedNotification = this.formatServiceRequestNotification(notification, isHtml, isBrowser);
        break;
      case NotificationType.DealerRequest:
        formattedNotification = this.formatQuoteNotification(notification, isHtml, isBrowser);
        break;
      case NotificationType.PMSRReminder:
        formattedNotification = this.formatPMSRNotification(notification, isHtml, isBrowser);
        break;
      case NotificationType.WarrantyClaim:
        formattedNotification = this.formatWCNotification(notification, isHtml, isBrowser);
        break;
      case NotificationType.ImpactReport:
        formattedNotification = this.formatImpactReportNotification(notification, isHtml);
        break;
      case NotificationType.WarrantyRegistration:
        formattedNotification = this.formatWarrantyRegistrationNotification(notification, isHtml, isBrowser);
        break;
      case NotificationType.LowChargeEvent:
        formattedNotification = this.formatLowChargeNotification(notification, isHtml, isBrowser);
        break;
      default:
        formattedNotification = `Unknown notification: ${JSON.stringify(notification)}`;
    }
    return formattedNotification;
  }

  public isNotificationAllowed(settings: number, message: NotificationMessage): boolean {
    return (
      this.isNotificationAllowedByUser(message) &&
      this.isNotificationAllowedBySettings(settings, message) &&
      this.isNotificationAllowedByPermissions(message)
    );
  }

  public isNotificationAllowedByUser(message: NotificationMessage): boolean {
    if (message.notificationType === NotificationType.DealerRequest) {
      const contactEmail = (message.data as NotificationDataQuote).contactEmail;
      const userEmail = this.membershipService.userEmail;
      return !!contactEmail && !!userEmail && contactEmail === userEmail;
    } else if (message.notificationType === NotificationType.WarrantyClaim) {
      const contactEmail = (message.data as WarrantyClaimNotificationData).contactEmail;
      const userEmail = this.membershipService.userEmail;
      return !!contactEmail && !!userEmail && contactEmail === userEmail;
    } else {
      return true;
    }
  }

  public isNotificationAllowedBySettings(settings: number, message: NotificationMessage): boolean {
    let isAllowed = false;
    switch (message.notificationTypeSubtype) {
      case NotificationTypeSubtype.ServiceRequestScheduled:
        isAllowed = (settings & NotificationTypeFlags.ServiceRequestScheduled) != 0;
        break;
      case NotificationTypeSubtype.ServiceRequestInProgress:
        isAllowed = (settings & NotificationTypeFlags.ServiceRequestInProgress) != 0;
        break;
      case NotificationTypeSubtype.ServiceRequestCompleted:
        isAllowed = (settings & NotificationTypeFlags.ServiceRequestCompleted) != 0;
        break;
      case NotificationTypeSubtype.ServiceRequestSubmitted:
        isAllowed = (settings & NotificationTypeFlags.ServiceRequestSubmitted) != 0;
        break;
      case NotificationTypeSubtype.ServiceRequestOnHold:
        isAllowed = (settings & NotificationTypeFlags.ServiceRequestOnHold) != 0;
        break;
      case NotificationTypeSubtype.QuoteSubmitted:
        isAllowed = (settings & NotificationTypeFlags.QuoteSubmitted) != 0;
        break;
      case NotificationTypeSubtype.QuotePendingCustomerApproval:
        isAllowed = (settings & NotificationTypeFlags.QuotePendingCustomerApproval) != 0;
        break;
      case NotificationTypeSubtype.QuoteCompleted:
        isAllowed = (settings & NotificationTypeFlags.QuoteConfirmed) != 0;
        break;
      case NotificationTypeSubtype.PMSRReminder:
        isAllowed = (settings & NotificationTypeFlags.PreventativeMaintenance) != 0;
        break;
      case NotificationTypeSubtype.WarrantyClaimStatusChanged:
        isAllowed = (settings & NotificationTypeFlags.WarrantyClaims) != 0;
        break;
      case NotificationTypeSubtype.ImpactReportReceived:
        isAllowed = (settings & NotificationTypeFlags.ImpactReport) != 0;
        break;
      case NotificationTypeSubtype.WarrantyRegistrationSubmitted:
        isAllowed = (settings & NotificationTypeFlags.WarrantyRegistrationSubmitted) != 0;
        break;
      case NotificationTypeSubtype.LowChargeEvent:
        isAllowed = (settings & NotificationTypeFlags.LowChargeEvent) != 0;
        break;
    }
    return isAllowed;
  }

  public isNotificationAllowedByPermissions(message: NotificationMessage): boolean {
    const srAllowed = this.membershipService.isPermissionAvailable(PermissionType.ServiceRequests);
    const historyAllowed = this.membershipService.isPermissionAvailable(PermissionType.ServiceRequestsHistory);
    const quoteAllowed = this.membershipService.isPermissionAvailable(PermissionType.SubmitQuotes);
    const wcAllowed = this.membershipService.isPermissionAvailable(PermissionType.SubmitWarrantyClaims);
    const wrAllowed = this.membershipService.isPermissionAvailable(PermissionType.CreateWarrantyRegistration);
    const irAllowed = this.membershipService.isPermissionAvailable(PermissionType.ReceiveReportingEmails);
    const lowChargeAllowed = this.membershipService.isPermissionAvailable(PermissionType.VehiclesTelematics);
    const incomingType = message.notificationTypeSubtype;
    const assetType = (message.data as NotificationDataServiceRequest)?.assetType;

    const allowedByMessageType: boolean =
      (srAllowed && this.isSRAllowed(incomingType)) ||
      (historyAllowed && incomingType === NotificationTypeSubtype.ServiceRequestCompleted) ||
      (quoteAllowed && this.isQuoteAllowed(incomingType)) ||
      (wrAllowed && incomingType === NotificationTypeSubtype.WarrantyRegistrationSubmitted) ||
      (wcAllowed && incomingType === NotificationTypeSubtype.WarrantyClaimStatusChanged) ||
      (irAllowed && incomingType === NotificationTypeSubtype.ImpactReportReceived) ||
      (lowChargeAllowed && incomingType === NotificationTypeSubtype.LowChargeEvent);

    return this.isAllowedByAssetType(message, assetType) && allowedByMessageType;
  }

  private isSRAllowed(incomingType: NotificationTypeSubtype): boolean {
    return (
      incomingType === NotificationTypeSubtype.ServiceRequestSubmitted ||
      incomingType === NotificationTypeSubtype.ServiceRequestInProgress ||
      incomingType === NotificationTypeSubtype.ServiceRequestScheduled ||
      incomingType === NotificationTypeSubtype.PMSRReminder ||
      incomingType === NotificationTypeSubtype.ServiceRequestOnHold
    );
  }

  private isQuoteAllowed(incomingType: NotificationTypeSubtype): boolean {
    return (
      incomingType === NotificationTypeSubtype.QuoteSubmitted ||
      incomingType === NotificationTypeSubtype.QuoteCompleted ||
      incomingType === NotificationTypeSubtype.QuotePendingCustomerApproval
    );
  }

  private isAllowedByAssetType(message: NotificationMessage, assetType: AssetType): boolean {
    return (
      message.notificationType === NotificationType.DealerRequest ||
      message.notificationType === NotificationType.WarrantyClaim ||
      !assetType ||
      (assetType === AssetType.Vehicle &&
        (this.membershipService.isPermissionAvailable(PermissionType.VehiclesBaseInformation) ||
          this.membershipService.isPermissionAvailable(PermissionType.ViewPowertrainsBaseInformation))) ||
      (assetType === AssetType.Charger && this.membershipService.isPermissionAvailable(PermissionType.ChargersBaseInformation))
    );
  }
}
