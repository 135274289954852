export const environment = {
  production: false,
  apiUrl: 'https://api.dev.xostrucks.com/api',
  amplify: {
    Auth: {
      region: 'us-west-2',
      userPoolId: 'us-west-2_EVPxdbPwC',
      userPoolWebClientId: '434ktl28j2t1gv90lnof55v816',
    },
  },
  version: '1.3.0',
  knowledgeCenterUrl: 'https://development.xostrucks.com',
  hubspotEnabled: true,
  hubspotServiceRequestsEnabled: true,
  mapbox: {
    accessToken: 'pk.eyJ1IjoieG9zIiwiYSI6ImNrd2w1dzVsMzBxYmMzMW10MnNuZHpveGUifQ.1iqxsz-f1PFzkuIL43a6lg',
  },
};
