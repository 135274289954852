<mat-form-field appearance="fill" class="chips-autocomplete">
  <mat-chip-list #optionsList>
    <mat-chip *ngFor="let option of selectedOptions" (removed)="remove(option)">
      <div class="icon-wrapper">
        <i class="xos-icon" [ngClass]="isVehicle ? 'icon-vehicle-nickname-white' : 'icon-charger-nickname-white'"></i>
      </div>
      {{ showNicknames ? option?.nickName || option?.vin : option?.vin || option?.serial }}
      {{ isVehicle && showNonElectricLabel && !option.isElectric ? '(Non-Electric)' : '' }}
      <button matChipRemove>
        <i class="xos-icon icon-close-white"></i>
      </button>
    </mat-chip>
    <input
      #optionInput
      [placeholder]="placeholder"
      matInput
      [matChipInputFor]="optionsList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [formControl]="optionControl"
      class="search-input"
      autocomplete="off"
    />
  </mat-chip-list>
  <div *ngIf="isVehicle && showNonElectricLabel">
    <div *ngFor="let group of filteredOptions$ | async">
      <span class="electric-group-title">{{ !!group.key ? 'Electric' : 'Non-electric' }}</span>
      <div *ngFor="let option of group.value" (click)="selected(option)" class="option">
        <span *ngIf="!showNicknames">{{ option?.vin || option?.serial }}</span>
        <div *ngIf="showNicknames" class="option-with-nickname">
          <div>{{ option?.nickName }}</div>
          <div class="icon-wrapper"><i class="xos-icon" [ngClass]="isVehicle ? 'icon-vehicle-nickname' : 'icon-charger-nickname'"></i></div>
          <div class="vin">
            {{ option?.vin || option?.serial }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!(isVehicle && showNonElectricLabel)">
    <div *ngFor="let option of filteredOptions$ | async" (click)="selected(option)" class="option">
      <span *ngIf="!showNicknames">{{ option?.vin || option?.serial }}</span>
      <div *ngIf="showNicknames" class="option-with-nickname">
        <div>{{ option?.nickName }}</div>
        <div class="icon-wrapper"><i class="xos-icon" [ngClass]="isVehicle ? 'icon-vehicle-nickname' : 'icon-charger-nickname'"></i></div>
        <div class="vin">
          {{ option?.vin || option?.serial }}
        </div>
      </div>
    </div>
  </div>
</mat-form-field>
