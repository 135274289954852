import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AnalyticsService } from '../../core/services/analytics.service';
import { RequestStatus } from '../models/statuses.enum';
import { MaintenanceTab } from '../models/tabs.enum';

@Injectable({
  providedIn: 'root',
})
export class SieveService {
  private filterByValue$: BehaviorSubject<string> = new BehaviorSubject('');
  private filterByStatus$: BehaviorSubject<any> = new BehaviorSubject([]);
  private assetFilters$: BehaviorSubject<any> = new BehaviorSubject(null);
  private inspectionFilters$: BehaviorSubject<any> = new BehaviorSubject(null);
  private preventativeFilters$: Subject<any> = new Subject();
  selectedTab$: BehaviorSubject<MaintenanceTab> = new BehaviorSubject<MaintenanceTab>(MaintenanceTab.Active);
  showAssetFilter: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(private analytics: AnalyticsService) {}

  getFilterByValueObservable$(): Observable<string> {
    return this.filterByValue$.asObservable();
  }

  getFilterByStatusObservable$(): Observable<RequestStatus[]> {
    return this.filterByStatus$.asObservable();
  }

  setInspectionFilters(filters: any): void {
    this.inspectionFilters$.next(filters);
  }

  getInspectionFiltersObservable$(): Observable<any> {
    return this.inspectionFilters$.asObservable();
  }

  getAssetFilterObservable$(): Observable<any> {
    return this.assetFilters$.asObservable();
  }

  getPreventativeFiltersObservable$(): Observable<any> {
    return this.preventativeFilters$.asObservable();
  }

  setFilterByValue(value: string): void {
    this.analytics.trackEvent('ServiceRequest.SetValueFilter', { value: value });
    this.filterByValue$.next(value);
  }

  setFilterByStatus(status: RequestStatus[]): void {
    this.analytics.trackEvent('ServiceRequest.SetStatusFilter', { status: status });
    this.filterByStatus$.next(status);
  }

  setAssetFilter(filters: any): void {
    this.analytics.trackEvent('ServiceRequest.SetAssetFilter', { filters });
    this.assetFilters$.next(filters);
  }

  setPreventativeFilters(filters: any): void {
    this.analytics.trackEvent('ServiceRequest.SetPreventativeFilters', { filters });
    this.preventativeFilters$.next(filters);
  }

  clearAllFilters(): void {
    this.setFilterByStatus([]);
    this.setFilterByValue('');
    this.setAssetFilter(null);
    this.setPreventativeFilters(null);
    this.setInspectionFilters(null);
  }
}
