import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'xos-export-button',
  templateUrl: './export-button.component.html',
  styleUrls: ['./export-button.component.scss'],
})
export class ExportButtonComponent {
  @Input() disabled: boolean = false;
  @Output() export: EventEmitter<any> = new EventEmitter<any>();
}
