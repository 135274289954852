import * as moment from 'moment';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { DatepickerHeaderComponent } from '../datepicker-header/datepicker-header.component';
import { Subject } from 'rxjs';
import { MatCalendarCellClassFunction, MatDatepicker, MatDatepickerInputEvent, MatDateRangePicker } from '@angular/material/datepicker';

type DatePickerType = 'default' | 'range';

@Component({
  selector: 'xos-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent<D> implements AfterViewInit, OnDestroy, OnChanges, OnInit {
  @ViewChild(MatDatepicker) picker!: MatDatepicker<any> | MatDateRangePicker<any>;
  @ViewChild('pickerRange') pickerRange!: MatDateRangePicker<any>;
  @Input() title!: string;
  @Input() label!: string;
  @Input() disabled: boolean = false;
  @Input() selectedDate!: D;
  @Input() startDate!: any;
  @Input() endDate!: any;
  @Input() type: DatePickerType = 'default';
  @Input() icon: string = '';
  @Input() iconTooltip: string = '';
  @Input() validationFn!: (date: Date | any) => boolean;
  @Input() todayAllowed: boolean = true;
  @Input() autoOpen: boolean = false;
  @Input() weekInterval: boolean = false;
  @Output() dateChange: EventEmitter<D> = new EventEmitter<D>();
  @Output() datesChange: EventEmitter<[D?, D?]> = new EventEmitter<[D?, D?]>();
  @Output() rangeCleared: EventEmitter<any> = new EventEmitter();
  @Output() closedEmpty: EventEmitter<any> = new EventEmitter();

  isDatePickerOpened: boolean = false;
  datepickerHeaderComponent = DatepickerHeaderComponent;
  maxDate: Date = new Date();
  minDate!: Date | null;
  private unsubscribe$: Subject<void> = new Subject();

  ngOnInit(): void {
    if (!this.todayAllowed) {
      this.maxDate = new Date();
      this.maxDate.setDate(this.maxDate.getDate() - 1);
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    changes?.autoOpen?.currentValue && this.pickerRange.open();
    if (!changes?.todayAllowed?.currentValue) {
      this.maxDate = new Date();
      this.maxDate.setDate(this.maxDate.getDate() - 1);
    } else {
      this.maxDate = new Date();
    }
  }

  ngAfterViewInit(): void {
    this.subscribeToDatepickerState();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  dateClass: MatCalendarCellClassFunction<Date> = (cellDate: Date) => {
    const date = cellDate.getDay();
    const currentDate = new Date();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    if (!this.todayAllowed) {
      if (cellDate.toDateString() === currentDate.toDateString()) return 'today-disabled';
      if (
        this.validationFn &&
        this.validationFn(cellDate) === true &&
        (cellDate.toDateString() === yesterday.toDateString() || cellDate.toDateString() === tomorrow.toDateString())
      )
        return 'mat-calendar-body-active';
    }
    return date === 0 || date === 6 ? 'weekday' : '';
  };

  clear(range?: D[] | null[], e?: Event): void {
    if (range) {
      e?.stopPropagation();
      this.startDate = null;
      this.endDate = null;
      this.datesChange.next([undefined, undefined]);
      this.rangeCleared.emit();
      this.minDate = null;
    } else if (!this.disabled) {
      this.picker.select(null);
      this.picker.open();
    }
  }

  subscribeToDatepickerState(): void {
    this.picker?.openedStream.pipe(takeUntil(this.unsubscribe$)).subscribe(() => (this.isDatePickerOpened = true));
    this.picker?.closedStream.pipe(takeUntil(this.unsubscribe$)).subscribe(() => (this.isDatePickerOpened = false));
  }

  datesChanged(): void {
    this.datesChange.emit([this.startDate, this.endDate]);
    this.minDate = null;
  }

  dateChanged(e: MatDatepickerInputEvent<D>): void {
    this.selectedDate = e.value as D;
    this.dateChange.next(this.selectedDate);
    if (!e.value) {
      requestAnimationFrame(() => {
        e.targetElement.blur();
      });
    }
  }

  closed() {
    !this.startDate && !this.endDate && this.closedEmpty.emit();
  }

  startDateChange(): void {
    if (this.weekInterval) {
      this.minDate = moment(this.startDate).add({ day: 6 }).toDate();
    }
  }
}
