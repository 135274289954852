import { Injectable } from '@angular/core';

declare var pendo: any;

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  trackEvent(eventName: string, metadata?: any): void {
    pendo.track(eventName, metadata);
  }
}
