export function triggerDownload(blob: Blob, fileName?: string | null | undefined): void {
  const link = document.createElement('a');

  const url = URL.createObjectURL(blob);
  link.href = url;
  link.rel = 'noopener noreferrer';
  if (fileName) {
    link.download = fileName;
  } else {
    link.target = '_blank';
  }

  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
