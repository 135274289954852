import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ProductsService } from '../services/products.service';
import {
  BasicConfigStateItem,
  FeatureStateItem,
  loadBasicConfig,
  loadFeatures,
  loadVehicleTypes,
  retrievedBasicConfig,
  retrievedFeatures,
  retrievedVehicleTypes,
} from './products.actions';
import { selectBasicConfig, selectFeatures, selectVehicleTypes } from './products.selectors';
import { Store } from '@ngrx/store';

@Injectable()
export class ProductsEffects {
  constructor(private actions$: Actions, private productsService: ProductsService, private store: Store) {}

  loadFeatures$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFeatures),
      withLatestFrom(this.store.select(selectFeatures)),
      filter(([action, features]) => !features?.length || !features.find((el: FeatureStateItem) => el.typeId === action.typeId)),
      mergeMap(([action, _features]) => {
        const typeId = action.typeId;
        return this.productsService.getOptionalFeatures(typeId).pipe(
          map(optionalFeatures => retrievedFeatures({ typeId, optionalFeatures })),
          catchError((error: Error) => {
            console.error(error.message);
            return EMPTY;
          }),
        );
      }),
    ),
  );

  loadConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadBasicConfig),
      withLatestFrom(this.store.select(selectBasicConfig)),
      filter(
        ([action, basicConfig]) => !basicConfig?.length || !basicConfig.find((el: BasicConfigStateItem) => el.typeId === action.typeId),
      ),
      mergeMap(([action, _basicConfig]) => {
        const typeId = action.typeId;
        return this.productsService.getBasicConfigurations(typeId).pipe(
          map(config => retrievedBasicConfig({ typeId, config })),
          catchError((error: Error) => {
            console.error(error.message);
            return EMPTY;
          }),
        );
      }),
    ),
  );

  loadVehicleTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadVehicleTypes),
      withLatestFrom(this.store.select(selectVehicleTypes)),
      filter(([_action, vehicleTypes]) => !vehicleTypes?.length),
      mergeMap(() =>
        this.productsService.getVehicleTypes().pipe(
          map(vehicleTypes => retrievedVehicleTypes({ vehicleTypes })),
          catchError((error: Error) => {
            console.error(error.message);
            return EMPTY;
          }),
        ),
      ),
    ),
  );
}
