<div
  [formGroup]="assetForm"
  class="asset-filter-wrapper reports-filters"
  [ngClass]="{ 'date-buttons': view === filtersView.DateButtons, 'date-only': view === filtersView.DateOnly }"
>
  <xos-multiple-search
    *ngIf="view === filtersView.Full"
    [assetCount]="assetCount"
    [isVehicle]="isVehicle"
    [options]="assetsList"
    [placeholder]="'Search ' + (isVehicle ? 'Fleet' : 'charger')"
    [selectedAssets]="currentFilters?.selectedAssets || []"
    [showLabel]="isMobile"
    [showNicknames]="showNicknames"
    [showNonElectricLabel]="showNonElectricLabel"
    (selectionUpdated)="updateSelection($event)"
  ></xos-multiple-search>

  <div *ngIf="view === filtersView.Full || view === filtersView.DateOnly" class="xos-field-wrapper inverval-picker">
    <div *ngIf="isMobile" class="xos-field-label">Period</div>
    <mat-form-field
      *ngIf="!showDatePicker"
      appearance="fill"
      class="xos-select"
      [class.selected]="view === filtersView.Full && assetForm?.value?.interval"
    >
      <mat-select
        formControlName="interval"
        panelClass="xos-select-list"
        class="xos-select-panel"
        (selectionChange)="selectInterval($event)"
      >
        <mat-option *ngFor="let interval of assetIntervals" [value]="interval.value">{{ interval.name }}</mat-option>
      </mat-select>
    </mat-form-field>
    <xos-datepicker
      [class.hidden]="!showDatePicker"
      type="range"
      label="Select dates"
      [todayAllowed]="false"
      [class.selected]="assetForm?.value?.startDate && assetForm?.value?.endDate"
      [startDate]="assetForm?.value?.startDate"
      [endDate]="assetForm?.value?.endDate"
      [autoOpen]="openDatePicker"
      [weekInterval]="true"
      (datesChange)="selectDates($event)"
      (rangeCleared)="clearInterval()"
      (closedEmpty)="clearInterval()"
    ></xos-datepicker>
  </div>
  <div *ngIf="isVehicle && view === filtersView.Full" class="xos-field-wrapper">
    <xos-multiple-select
      *ngIf="view === filtersView.Full"
      [showLabel]="true"
      [label]="'Reports'"
      [optionsCount]="this.currentFilters.dataTypes.length"
      [options]="reportTypes"
      [selectedOptions]="currentReportTypes"
      [showLabel]="isMobile"
      (selectionUpdated)="selectDataType($event)"
    ></xos-multiple-select>
  </div>

  <mat-button-toggle-group
    *ngIf="view === filtersView.DateButtons"
    (click)="ignoreClick($event)"
    name="chartMode"
    aria-label="Chart Mode"
    class="mode-button-wrapper"
    formControlName="interval"
    (valueChange)="selectInterval({ value: $event })"
  >
    <mat-button-toggle value="1">Month</mat-button-toggle>
    <mat-button-toggle value="2">Year</mat-button-toggle>
    <mat-button-toggle value="0">All time</mat-button-toggle>
  </mat-button-toggle-group>
</div>
