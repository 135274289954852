import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TelematicsService } from '../../../fleet/services/telematics.service';
import { MembershipService } from 'src/app/core/services/membership/membership.service';
import { PermissionType } from 'src/app/core/models/permissions.enum';

@Component({
  selector: 'xos-vehicles-empty',
  templateUrl: './vehicles-empty.component.html',
  styleUrls: ['./vehicles-empty.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehiclesEmptyComponent {
  @Input() filteredVehicles: any[] = [];
  @Input() noVehicles: boolean = false;
  @Input() loading: boolean = true;
  @Input() dataName: string = this.isPowerTrain ? 'powertrains' : 'vehicles';
  @Output() clearFilter: EventEmitter<any> = new EventEmitter<any>();

  constructor(private telematicsService: TelematicsService, private membershipService: MembershipService) {}

  get isPowerTrain(): boolean {
    return this.membershipService.isPermissionAvailable(PermissionType.ViewPowertrainsBaseInformation);
  }

  clearSearchAndFilters(): void {
    this.telematicsService.setClearAll();
    this.clearFilter.emit();
  }
}
