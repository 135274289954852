export enum MaintenanceTab {
  Active,
  Preventative,
  Inspections,
  History,
  Warranty,
  SpareParts,
  Count,
}

export enum AssetType {
  AllAssets = 0,
  Charger = 1,
  Vehicle,
}

export enum WarrantyTabs {
  General,
  Parts,
  Operations,
  Attachments,
}

export enum SelectedParts {
  Failed,
  Replacement,
}
