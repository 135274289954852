import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CreateRequestMode } from 'src/app/overview/models/create-request-mode.enum';
import { SidenavState } from '../../overview/models/sidenav-state.enum';

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  isMainSideNavOpen: BehaviorSubject<SidenavState> = new BehaviorSubject<SidenavState>(SidenavState.Expanded);
  isCreateRequestSideNavOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isNotificationSideNavOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isQuoteSideNavOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isChargersSideNavOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isOrderSideNavOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isWarrantyRegistrationSideNavOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isVehicleSideNavOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isUserRoleSideNavOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isIntegrationSettingsSideNavOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isUtilitySidenavOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isWarrantySidenavOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isInspectionSidenavOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isAddVehicleSidenavOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showSettingsMenu: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isXosIdUserSidenavOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isReportsConfigSidenavOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isSessionSideNavOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private requestMode: BehaviorSubject<CreateRequestMode> = new BehaviorSubject<CreateRequestMode>(CreateRequestMode.Full);

  private closeIfOpen(sidenavFlag: BehaviorSubject<boolean>) {
    if (sidenavFlag.getValue()) {
      sidenavFlag.next(false);
    }
  }

  openNotificationSideNav(): void {
    this.isNotificationSideNavOpen.next(true);
    this.closeIfOpen(this.isCreateRequestSideNavOpen);
    this.closeIfOpen(this.isQuoteSideNavOpen);
    this.closeIfOpen(this.isChargersSideNavOpen);
    this.closeIfOpen(this.isSessionSideNavOpen);
    this.closeIfOpen(this.isOrderSideNavOpen);
    this.closeIfOpen(this.isWarrantyRegistrationSideNavOpen);
    this.closeIfOpen(this.isVehicleSideNavOpen);
    this.closeIfOpen(this.isUserRoleSideNavOpen);
    this.closeIfOpen(this.isIntegrationSettingsSideNavOpen);
    this.closeIfOpen(this.isUtilitySidenavOpen);
    this.closeIfOpen(this.isWarrantySidenavOpen);
    this.closeIfOpen(this.isInspectionSidenavOpen);
    this.closeIfOpen(this.isAddVehicleSidenavOpen);
    this.closeIfOpen(this.isReportsConfigSidenavOpen);
  }

  closeNotificationSideNav(): void {
    this.isNotificationSideNavOpen.next(false);
  }

  toggleMainSideNav(state: SidenavState = SidenavState.Auto, isSettingsRoute = false): void {
    this.toggleSettingsMenu(isSettingsRoute);
    const isTabletMobile = window.innerWidth < 769;
    const stateBasedOnMode = isSettingsRoute || isTabletMobile ? SidenavState.Expanded : this.isMainSideNavOpen.getValue() + 1;
    const nextState = this.isMainSideNavOpen.getValue() === SidenavState.Expanded ? SidenavState.Closed : stateBasedOnMode;
    this.isMainSideNavOpen.next(!!state ? state : nextState);
  }

  getIsOpenedMainSideNavObservable(): Observable<SidenavState> {
    return this.isMainSideNavOpen.asObservable();
  }

  getRequestModeObservable(): Observable<CreateRequestMode> {
    return this.requestMode.asObservable();
  }

  setRequestMode(requestMode: CreateRequestMode): void {
    this.requestMode.next(requestMode);
  }

  openCreateRequestSideNav(requestMode: CreateRequestMode | null): void {
    if (requestMode) {
      this.requestMode.next(requestMode);
    }
    this.isCreateRequestSideNavOpen.next(true);
    if (this.isNotificationSideNavOpen.getValue()) {
      this.isNotificationSideNavOpen.next(false);
    }
  }

  closeCreateRequestSideNav(): void {
    this.isCreateRequestSideNavOpen.next(false);
    this.requestMode.next(CreateRequestMode.Full);
  }

  toggleCreateRequestSideNav(): void {
    this.isCreateRequestSideNavOpen.next(!this.isCreateRequestSideNavOpen.getValue());
  }

  getIsCreateRequestSideNavOpenObservable(): Observable<boolean> {
    return this.isCreateRequestSideNavOpen.asObservable();
  }

  getIsQuoteSideNavOpen(): Observable<boolean> {
    return this.isQuoteSideNavOpen.asObservable();
  }

  toggleQuoteSideNav(state = false): void {
    this.isQuoteSideNavOpen.next(state);
  }

  getIsOrderSideNavOpen(): Observable<boolean> {
    return this.isOrderSideNavOpen.asObservable();
  }

  toggleOrderSideNav(state = false): void {
    this.isOrderSideNavOpen.next(state);
  }

  getIsWarrantyRegistrationSideNavOpen(): Observable<boolean> {
    return this.isWarrantyRegistrationSideNavOpen.asObservable();
  }

  toggleWarrantyRegistrationSideNav(state = false): void {
    this.isWarrantyRegistrationSideNavOpen.next(state);
  }

  getIsVehicleSidenavOpen(): Observable<boolean> {
    return this.isVehicleSideNavOpen.asObservable();
  }

  toggleVehicleSideNav(state = false): void {
    this.isVehicleSideNavOpen.next(state);
  }

  getIsChargersSideNavOpen(): Observable<boolean> {
    return this.isChargersSideNavOpen.asObservable();
  }

  toggleChargersSideNav(state = false): void {
    this.isChargersSideNavOpen.next(state);
  }

  getIsSessionSideNavOpen(): Observable<boolean> {
    return this.isSessionSideNavOpen.asObservable();
  }

  toggleSessionSideNav(state = false): void {
    this.isSessionSideNavOpen.next(state);
  }

  getIsUserRoleSideNavOpen(): Observable<boolean> {
    return this.isUserRoleSideNavOpen.asObservable();
  }

  toggleUserRoleSideNav(state = false): void {
    this.isUserRoleSideNavOpen.next(state);
  }

  getIsCustomerSideNavOpen(): Observable<boolean> {
    return this.isIntegrationSettingsSideNavOpen.asObservable();
  }

  toggleIntegrationSettingsSideNav(state = false): void {
    this.isIntegrationSettingsSideNavOpen.next(state);
  }

  get createRequestMode() {
    return this.requestMode.getValue();
  }

  getIsUtilitySidenavOpen(): Observable<boolean> {
    return this.isUtilitySidenavOpen.asObservable();
  }

  toggleUtilitySidenavOpen(state = false): void {
    this.isUtilitySidenavOpen.next(state);
  }

  getIsWarrantySidenavOpen(): Observable<boolean> {
    return this.isWarrantySidenavOpen.asObservable();
  }

  toggleWarrantySidenavOpen(state = false): void {
    this.isWarrantySidenavOpen.next(state);
  }

  getIsInspectionSidenavOpen(): Observable<boolean> {
    return this.isInspectionSidenavOpen.asObservable();
  }

  toggleInspectionSidenavOpen(state = false): void {
    this.isInspectionSidenavOpen.next(state);
  }

  getIsAddVehicleSidenavOpen(): Observable<boolean> {
    return this.isAddVehicleSidenavOpen.asObservable();
  }

  toggleAddVehicleSidenavOpen(state = false): void {
    this.isAddVehicleSidenavOpen.next(state);
  }

  toggleSettingsMenu(state = false) {
    this.showSettingsMenu.next(state);
  }

  toggleXosIdUserSidenav(state = false) {
    this.isXosIdUserSidenavOpen.next(state);
  }

  getXosIdUserSidenavOpen(): Observable<boolean> {
    return this.isXosIdUserSidenavOpen.asObservable();
  }

  toggleReportsConfigSidenav(state = false) {
    this.isReportsConfigSidenavOpen.next(state);
  }

  getReportsConfigSidenavOpen(): Observable<boolean> {
    return this.isReportsConfigSidenavOpen.asObservable();
  }
}
