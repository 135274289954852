import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { FileInfo, FileTokenInfo } from '../models/file-token-info.interface';
import { triggerDownload } from '../utils/trigger-download';
import { FileType } from '../models/file-type-enum';

@Injectable()
export class FilesService {
  constructor(protected httpClient: HttpClient) {}

  getFile(fileType: FileType, objectId: number, fileId: number): Observable<FileTokenInfo> {
    return this.httpClient.get<FileTokenInfo>(`${environment.apiUrl}/externalfiles/getfiletoken/${fileType}/${objectId}/${fileId}`);
  }

  getInternalFile(fileId: number): Observable<FileInfo> {
    return this.httpClient.get<FileInfo>(`${environment.apiUrl}/Files/${fileId}`);
  }

  getFileAsBlobByUrl(url: string): Observable<HttpResponse<Blob>> {
    return this.httpClient.get(url, {
      observe: 'response',
      responseType: 'blob',
    });
  }

  getFileAsBlob(fileType: FileType, objectId: number, fileId: number): Observable<HttpResponse<Blob>> {
    return this.getFileAsBlobByUrl(`${environment.apiUrl}/externalfiles/${fileType}/${objectId}/${fileId}`);
  }

  async openFile(fileType: FileType, objectId: number, fileId: number): Promise<void> {
    await this.getFile(fileType, objectId, fileId)
      .toPromise()
      .then(tokenInfo => {
        !!tokenInfo &&
          window.open(
            `${environment.apiUrl}/externalfiles/getfilebytoken/${tokenInfo.token}/${tokenInfo.fileName}`,
            'winname',
            'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no',
          );
      });
  }

  async openInternalFile(fileId: number): Promise<void> {
    await this.getInternalFile(fileId)
      .toPromise()
      .then(fileInfo => {
        !!fileInfo &&
          window.open(
            fileInfo.url,
            'winname',
            'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no',
          );
      });
  }

  async downloadFileByUrl(url: string): Promise<void> {
    var response = await this.getFileAsBlobByUrl(url).toPromise();
    !!response && this.downloadBlob(response);
  }

  async downloadFile(fileType: FileType, objectId: number, fileId: number): Promise<void> {
    var response = await this.getFileAsBlob(fileType, objectId, fileId).toPromise();
    !!response && this.downloadBlob(response);
  }

  downloadBlob(response: HttpResponse<Blob>) {
    var blob = response.body!;
    const contentDisposition = response.headers.get('content-disposition');
    const filename = contentDisposition?.split(';')[1].split('filename')[1].split('=')[1].trim();

    triggerDownload(blob, filename);
  }

  uploadFile(file: File, fileType: FileType): Observable<number> {
    const formData = new FormData();
    formData.append('file', file);

    return this.httpClient.put<number>(`${environment.apiUrl}/externalfiles/${fileType}`, formData);
  }

  deleteFile(id: number, fileType: FileType): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${environment.apiUrl}/externalfiles/${fileType}/${id}`);
  }
}
