import { ToastrModule } from 'ngx-toastr';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { ProductsEffects } from './products/state/products.effects';
import { productsReducer } from './products/state/products.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { coreReducer } from './core/state/core.reducer';
import { reportsReducer } from './reports/state/reports.reducer';
import { CoreEffects } from './core/state/core.effects';
import { chargersReducer } from './chargers/state/chargers.reducer';
import { ErrorNotifierInterceptor } from './core/interceptors/error-notifier.interceptor';
import { adminReducer } from './admin/state/admin.reducer';
import { ExternalModule } from './external/external.module';
import { sparePartsReducer } from './shared/modules/spare-parts/state/spare-parts.reducer';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    CoreModule,
    ExternalModule,
    HttpClientModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-left',
      preventDuplicates: true,
      maxOpened: 10,
      timeOut: 3000,
    }),
    StoreModule.forRoot({
      products: productsReducer,
      user: coreReducer,
      reports: reportsReducer,
      chargers: chargersReducer,
      admin: adminReducer,
      spareParts: sparePartsReducer,
    }),
    EffectsModule.forRoot([ProductsEffects, CoreEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
      autoPause: true,
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorNotifierInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
