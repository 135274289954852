import { findKey, isNumber, values } from 'lodash/fp';

export type NameValueArray = { name: any; value: number }[];

export function getCollection(collection: any): NameValueArray {
  return values(collection)
    .filter(el => isNumber(el))
    .map(value => ({ name: findKey(el => el === value, collection), value }));
}

export function getCollectionElementName(coll: NameValueArray, value: number, toLowerCase = true): string {
  const v = coll.find(el => el.value === value)?.name;
  const lc = toLowerCase ? v.toLowerCase() : v;
  return lc ?? '';
}

export function getCollectionElementValue(coll: NameValueArray, name: string): number {
  return name ? coll.find(el => el.name.toLowerCase() === name.toLowerCase())?.value ?? 0 : 0;
}
