import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { TooltipPosition } from '../../models/tooltip.type';

@Component({
  selector: 'xos-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent {
  @Input() position: TooltipPosition = 'right';
  @HostBinding('class') get positionClass() {
    return this.position;
  }
}
