import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { PermissionType } from '../../models/permissions.enum';
import { UserInfo, UserService } from '../auth/user.service';

@Injectable({
  providedIn: 'root',
})
export class MembershipService {
  myPermissions!: PermissionType[];
  isSysAdmin!: boolean;
  isTestUser!: boolean;
  isDeviceProvisioner!: boolean;
  permissionsLoaded$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  permissions$: ReplaySubject<PermissionType[]> = new ReplaySubject<PermissionType[]>();
  loaded: boolean = false;
  userEmail!: string;

  constructor(private userService: UserService) {
    this.reloadPermissions().subscribe();
  }

  reloadPermissions(): Observable<UserInfo> {
    return this.userService.getCurrentUser().pipe(
      map(userInfo => {
        this.myPermissions = userInfo.permissions;
        this.isSysAdmin = userInfo.isSystemAdmin;
        this.isTestUser = userInfo.isTestUser;
        this.isDeviceProvisioner = userInfo.isDeviceProvisioner;
        this.userEmail = userInfo.email;
        this.permissionsLoaded$.next(true);
        this.permissions$.next(userInfo.permissions);
        this.loaded = true;
        return userInfo;
      }),
    );
  }

  get permissionsLoaded(): Observable<boolean> {
    return this.permissionsLoaded$.asObservable();
  }

  get permissions(): Observable<PermissionType[]> {
    return this.permissions$.asObservable();
  }

  public checkPermissionAsync(permission: PermissionType): Observable<boolean> {
    return this.permissions.pipe(map(availablePermissions => availablePermissions.indexOf(permission) != -1));
  }

  public checkAnyPermissionAsync(permissions: PermissionType[]): Observable<boolean> {
    return this.permissions.pipe(map(availablePermissions => availablePermissions.some(p => permissions.indexOf(p) != -1)));
  }

  public isPermissionAvailable(permission: PermissionType): boolean {
    if (!!this.myPermissions) {
      return this.myPermissions?.indexOf(permission) !== -1;
    } else {
      console.error('Permissions not loaded while checked');
      return false;
    }
  }
}
