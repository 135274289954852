import * as _moment from 'moment';
import { Moment } from 'moment/moment';
// @ts-ignore
import { default as _rollupMoment } from 'moment';
const moment = _rollupMoment || _moment;
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DATE_FORMATS } from '../../models/date-formats';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatCalendarCellClassFunction, MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'xos-datepicker-moment-header',
  template: '<div class="moment-header"></div>',
})
export class DatepickerMomentHeaderComponent {}

@Component({
  selector: 'xos-moment-datepicker',
  templateUrl: './moment-datepicker.component.html',
  styleUrls: ['../datepicker/datepicker.component.scss', './moment-datepicker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ],
})
export class MomentDatepickerComponent {
  @ViewChild(MatDatepicker) dp!: MatDatepicker<any>;
  @Input() disabled: boolean = false;
  @Input() icon: string = '';
  @Input() iconTooltip: string = '';
  @Input() selectedDate!: Moment | null;
  @Input() title!: string;
  @Output() dateChange: EventEmitter<Moment | null> = new EventEmitter<Moment | null>();
  datepickerMomentHeaderComponent = DatepickerMomentHeaderComponent;

  dateChanged(e: MatDatepickerInputEvent<any>): void {
    this.selectedDate = e.value as Moment;
    this.dateChange.emit(this.selectedDate);
    if (!e.value) {
      requestAnimationFrame(() => {
        e.targetElement.blur();
      });
    }
  }

  clear(): void {
    this.dp.select(null);
    this.dp.open();
    this.selectedDate = null;
    this.dateChange.emit(null);
  }

  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    if (view === 'month') {
      const currentDateMidnight = moment(new Date()).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      return currentDateMidnight.isSame(cellDate) ? 'day-cell-active' : 'day-cell';
    }
    return '';
  };
}
