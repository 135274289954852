<nav>
  <ul *ngIf="(showSettingsMenu$ | async) === false || isCollapsed()">
    <li *ngIf="isOverviewAvailable()">
      <a
        routerLink="/overview"
        routerLinkActive="active"
        queryParamsHandling="merge"
        [queryParams]="resetQueryParams"
        class="overview white"
        matTooltip="Overview"
        matTooltipPosition="right"
        [matTooltipClass]="isCollapsed() ? 'xos-tooltip menu-tooltip' : 'hidden'"
        (click)="hideSettingsSubMenu()"
      >
        <i class="xos-icon icon-stroke-black"></i>
        <span *ngIf="isExpanded()">Overview</span>
      </a>
    </li>
    <li *ngIf="isOverviewAvailable()">
      <a
        routerLink="/fleet"
        routerLinkActive="active"
        queryParamsHandling="merge"
        [queryParams]="resetQueryParams"
        class="fleet white"
        matTooltip="Fleet"
        matTooltipPosition="right"
        [matTooltipClass]="isCollapsed() ? 'xos-tooltip menu-tooltip' : 'hidden'"
        (click)="hideSettingsSubMenu()"
      >
        <i class="xos-icon icon-vehicles-black"></i>
        <span *ngIf="isExpanded()">Fleet</span>
      </a>
    </li>
    <li *ngIf="!isKnowledgeCenterOnly()">
      <a
        routerLink="/chargers"
        routerLinkActive="active"
        queryParamsHandling="merge"
        [queryParams]="resetQueryParams"
        class="chargers white"
        matTooltip="Chargers"
        matTooltipPosition="right"
        [matTooltipClass]="isCollapsed() ? 'xos-tooltip menu-tooltip' : 'hidden'"
        (click)="hideSettingsSubMenu()"
      >
        <i class="xos-icon icon-chargers-black"></i>
        <span *ngIf="isExpanded()">Chargers</span>
      </a>
    </li>
    <li *ngIf="isMaintenanceAvailable()">
      <a
        routerLink="/maintenance"
        routerLinkActive="active"
        queryParamsHandling="merge"
        [queryParams]="resetQueryParams"
        class="maintenance white"
        matTooltip="Maintenance"
        matTooltipPosition="right"
        [matTooltipClass]="isCollapsed() ? 'xos-tooltip menu-tooltip' : 'hidden'"
        (click)="hideSettingsSubMenu()"
      >
        <i class="xos-icon icon-maintenance-black"></i>
        <span *ngIf="isExpanded()">Maintenance</span>
      </a>
    </li>
    <li *ngIf="isProductsAvailable()">
      <a
        routerLink="/products"
        routerLinkActive="active"
        queryParamsHandling="merge"
        [queryParams]="resetQueryParams"
        class="products white"
        matTooltip="Products"
        matTooltipPosition="right"
        [matTooltipClass]="isCollapsed() ? 'xos-tooltip menu-tooltip' : 'hidden'"
        (click)="hideSettingsSubMenu()"
      >
        <i class="xos-icon icon-cart-black"></i>
        <span *ngIf="isExpanded()">Products</span>
      </a>
    </li>
    <li *ngIf="!isKnowledgeCenterOnly()">
      <a
        routerLink="/reports"
        routerLinkActive="active"
        queryParamsHandling="merge"
        [queryParams]="resetQueryParams"
        class="reports white"
        matTooltip="Reports"
        matTooltipPosition="right"
        [matTooltipClass]="isCollapsed() ? 'xos-tooltip menu-tooltip' : 'hidden'"
        (click)="hideSettingsSubMenu()"
      >
        <i class="xos-icon icon-trend-arrow"></i>
        <span *ngIf="isExpanded()">Reports</span>
      </a>
    </li>
    <li *ngIf="isSalesAvailable()">
      <a
        routerLink="/sales"
        routerLinkActive="active"
        queryParamsHandling="merge"
        [queryParams]="resetQueryParams"
        class="sales white"
        matTooltip="Sales"
        matTooltipPosition="right"
        [matTooltipClass]="isCollapsed() ? 'xos-tooltip menu-tooltip' : 'hidden'"
        (click)="hideSettingsSubMenu()"
      >
        <i class="xos-icon icon-sales-black"></i>
        <span *ngIf="isExpanded()">Sales</span>
      </a>
    </li>
    <li *ngIf="isKnowledgeCenterAvailable()">
      <a
        routerLinkActive="active"
        class="knowledge white"
        target="_blank"
        rel="noopener noreferrer"
        [matTooltip]="
          isCollapsed()
            ? 'Knowledge center'
            : 'Get help on your Xos product or this platform here. If a new window doesn\'t open, check your popups.'
        "
        [matTooltipPosition]="isCollapsed() ? 'right' : 'below'"
        [matTooltipClass]="isCollapsed() ? 'xos-tooltip menu-tooltip' : 'xos-tooltip knowledge-center-tooltip'"
        (click)="openKnowledgeCenter()"
      >
        <i class="xos-icon icon-knowledgecenter-black"></i>
        <span *ngIf="isExpanded()">Knowledge center</span>
      </a>
    </li>
    <li *ngIf="isKnowledgeCenterOnly()">
      <a
        routerLink="/knowledge-center"
        routerLinkActive="active"
        queryParamsHandling="merge"
        [queryParams]="resetQueryParams"
        class="knowledge white"
        matTooltip="Knowledge center"
        matTooltipPosition="right"
        [matTooltipClass]="isCollapsed() ? 'xos-tooltip menu-tooltip' : 'hidden'"
        (click)="hideSettingsSubMenu()"
      >
        <i class="xos-icon icon-knowledgecenter-black"></i>
        <span *ngIf="isExpanded()">Knowledge center</span>
      </a>
    </li>
    <li *ngIf="isAdminAvailable()">
      <a
        routerLink="/admin"
        routerLinkActive="active"
        queryParamsHandling="merge"
        [queryParams]="resetQueryParams"
        class="admin white"
        matTooltip="Admin"
        matTooltipPosition="right"
        [matTooltipClass]="isCollapsed() ? 'xos-tooltip menu-tooltip' : 'hidden'"
        (click)="hideSettingsSubMenu()"
      >
        <i class="xos-icon icon-admin-black"></i>
        <span *ngIf="isExpanded()">Admin</span>
      </a>
    </li>
    <li>
      <a
        routerLink="/settings"
        routerLinkActive="active"
        queryParamsHandling="merge"
        [queryParams]="resetQueryParams"
        class="settings white"
        matTooltip="Settings"
        matTooltipPosition="right"
        [matTooltipClass]="isCollapsed() ? 'xos-tooltip menu-tooltip' : 'hidden'"
        (click)="navigateToSettings()"
      >
        <i class="xos-icon icon-settings"></i>
        <span *ngIf="isExpanded()">Settings</span>
        <i *ngIf="isExpanded()" class="xos-icon icon-arrow-white-small"></i>
      </a>
    </li>
  </ul>
  <div *ngIf="(showSettingsMenu$ | async) && !isCollapsed()" class="back-link-wrapper">
    <a (click)="hideSettingsSubMenu()" class="back-link"> <i class="xos-icon icon-arrow-white"></i> Settings </a>
  </div>
  <ul *ngIf="(showSettingsMenu$ | async) && !isCollapsed()" class="settings-menu">
    <li>
      <a
        routerLink="/settings/profile"
        routerLinkActive="active"
        queryParamsHandling="merge"
        [queryParams]="resetQueryParams"
        class="profile"
      >
        <span>Profile</span>
      </a>
    </li>
    <li>
      <a
        routerLink="/settings/general"
        routerLinkActive="active"
        queryParamsHandling="merge"
        [queryParams]="resetQueryParams"
        class="general"
      >
        <span>General</span>
      </a>
    </li>
    <ng-container *ngIf="permissions$ | async">
      <li *ngIf="isNotificationsAvailable()">
        <a
          routerLink="/settings/notifications"
          routerLinkActive="active"
          queryParamsHandling="merge"
          [queryParams]="resetQueryParams"
          class="notifications"
        >
          <span>Notifications</span>
        </a>
      </li>
      <li *ngIf="isVehicleSettingsAvailable()">
        <a
          routerLink="/settings/fleet"
          routerLinkActive="active"
          queryParamsHandling="merge"
          [queryParams]="resetQueryParams"
          class="vehicles"
        >
          <span>{{ isPowerTrain ? 'Powertrains' : 'Vehicles' }}</span>
        </a>
      </li>
      <li *ngIf="isChargerSettingsAvailable()">
        <a
          routerLink="/settings/chargers"
          routerLinkActive="active"
          queryParamsHandling="merge"
          [queryParams]="resetQueryParams"
          class="chargers"
        >
          <span>Chargers</span>
        </a>
      </li>
      <li *ngIf="isUtilityPolicyAvailable()">
        <a
          routerLink="/settings/utility-policy"
          routerLinkActive="active"
          queryParamsHandling="merge"
          [queryParams]="resetQueryParams"
          class="utility-policy"
        >
          <span>Utility policy</span>
        </a>
      </li>
    </ng-container>
  </ul>
</nav>
<div class="additional-widgets-wrapper">
  <xos-upgrade-banner *ngIf="isFreePlan && isExpanded()"></xos-upgrade-banner>
  <xos-download-app *ngIf="isMobileBannerAvailable() && isExpanded()"></xos-download-app>
  <div *ngIf="hasCustomerSupport$ | async" class="bottom-icon">
    <a
      class="support white"
      matTooltip="Customer support"
      matTooltipPosition="right"
      [matTooltipClass]="isCollapsed() ? 'xos-tooltip menu-tooltip' : 'hidden'"
      (click)="toggleWebWidget()"
    >
      <i class="xos-icon icon-support-chat"></i>
      <span *ngIf="isExpanded()">Customer support</span>
    </a>
  </div>
</div>
