import { chunk, difference, flatten, sortBy } from 'lodash/fp';
import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HourPrice, PriceType, UpdatedRange } from '../../chargers/models/utility-policies.models';

@Injectable({
  providedIn: 'root',
})
export class UtilityPolicyService {
  private range$: Subject<UpdatedRange> = new Subject<UpdatedRange>();

  getUpdatedRange$(): Observable<UpdatedRange> {
    return this.range$.asObservable();
  }

  setUpdatedRange(range: UpdatedRange): void {
    this.range$.next(range);
  }

  getFreeRanges(hourPrices: HourPrice[]): number[][] {
    const takenSpots = flatten(hourPrices.map(item => [...Array(item.to - item.from + 1).keys()].map(i => i + item.from)));
    const freeSpots = difference([...Array(24).keys()], takenSpots);

    // finding start and end spots for each time interval that is longer than 1 hour
    const freeEdges = freeSpots.filter(
      (el, index) => index === 0 || index === freeSpots.length - 1 || el + 1 !== freeSpots[index + 1] || el - 1 !== freeSpots[index - 1],
    );

    // finding intervals only 1 hour long
    const oneHourSpots = freeEdges.filter(el => !freeSpots.some(item => item === el + 1 || item === el - 1));

    // first add 1 hour intervals which has start and end spots as the same hour, then split the rest spots on pairs to represent longer intervals
    return [...oneHourSpots.map(spot => [spot, spot]), ...chunk(2, difference(freeEdges, oneHourSpots))];
  }

  getAllHourPrices(hourPrices: HourPrice[]): HourPrice[] {
    const greyHourPrices = this.getFreeRanges(hourPrices).map(item => ({ from: item[0], to: item[1], type: PriceType.Undefined }));
    return sortBy('to', [...hourPrices, ...greyHourPrices]);
  }
}
