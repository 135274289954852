export enum WarrantyClaimStatus {
  NotStarted = 1,
  InProgress = 2,
  Escalated = 3,
  ReOpened = 4,
  Closed = 5,
  Cancelled = 6,
  PendingWarrantyReview = 7,
  WarrantyApproved = 8,
  Scheduled = 9,
  OnHold = 10,
  WarrantyClaimAccepted = 11,
  WarrantyClaimReturned = 12,
  WarrantyClaimDenied = 13,
  WarrantyClaimInReview = 14,
  WarrantyPartsReturnRequested = 15,
  WarrantyClaimPaid = 16,
  WarrantyClaimPendingPayment = 17,
}

export enum WarrantyClaimStatusLabels {
  'Warranty Claim In Review' = 1, //the status with id 1 is no longer used (XOSPHERE-2539)
  'In Progress' = 2,
  'Escalated' = 3,
  'Re-opened' = 4,
  'Closed' = 5,
  'Cancelled' = 6,
  'Pending Warranty Review' = 7,
  'Warranty Approved' = 8,
  'Scheduled' = 9,
  'On Hold' = 10,
  'Warranty Claim Accepted' = 11,
  'Warranty Claim Returned' = 12,
  'Warranty Claim Denied' = 13,
  'In Review' = 14,
  'Warranty Parts Return Requested' = 15,
  'Warranty Claim Paid' = 16,
  'Warranty Claim Pending Payment' = 17,
}
