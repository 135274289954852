import { Directive, AfterViewInit, OnDestroy, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { Subscription } from 'rxjs';
import { MatLegacyOptionSelectionChange as MatOptionSelectionChange } from '@angular/material/legacy-core';

@Directive({ selector: '[xosSelectActiveOption]' })
export class SelectActiveOptionDirective implements AfterViewInit, OnDestroy {
  observable$!: Subscription;
  constructor(@Optional() private autoTrigger: MatAutocompleteTrigger, @Optional() private control: NgControl) {}

  ngAfterViewInit() {
    this.observable$ = this.autoTrigger.panelClosingActions.subscribe((x: MatOptionSelectionChange | any) => {
      if (this.autoTrigger.activeOption && this.control?.control) {
        const value = this.autoTrigger.activeOption.value;
        this.control.control.setValue(value, { emit: false });
        this.autoTrigger.writeValue(this.autoTrigger.activeOption.value);
      } else if (x?.isUserInput) {
        const value = x?.source?.value;
        this.control.control?.setValue(value, { emit: false });
        this.autoTrigger.writeValue(value);
      }
    });
  }

  ngOnDestroy() {
    this.observable$.unsubscribe();
  }
}
