import { Component, Input } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'xos-reschedule-request-toast',
  templateUrl: './reschedule-request-toast.component.html',
  styleUrls: ['./reschedule-request-toast.component.scss']
})
export class RescheduleRequestToastComponent extends Toast {
  @Input() id: string = '';
  @Input() vehicle: string = '';

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage
  ) {
    super(toastrService, toastPackage);
  }

  close(): void {
    this.toastrService.clear();
  }
}
