<div class="error-tooltip" [ngClass]="tooltipPosition">
  <span class="error-title">
    {{ errorMessage.title }}
  </span>
  <span *ngIf="errorMessage.description" [innerHTML]="errorMessage.description" class="error-description"> </span>
  <ul *ngIf="errorMessage.messages">
    <li *ngFor="let message of errorMessage.messages">
      {{ message }}
    </li>
  </ul>
</div>
