import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { take, tap } from 'rxjs/operators';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BasicModalComponent } from 'src/app/shared/components/basic-modal/basic-modal.component';
import { KnowledgeCenterService } from 'src/app/knowledge-center/services/knowledge-center.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ErrorNotifierInterceptor implements HttpInterceptor {
  constructor(private matDialog: MatDialog, private injector: Injector, private router: Router) {}

  SKIP_API_URLS: string[] = ['VehiclesTelematics', 'ChargersTelematics', 'ResetOrphanPassword'];
  SKIP_LOCATION_URLS: string[] = ['/embed/'];
  isDialogDisplayed = false;

  isSkipLocation(): boolean {
    return this.SKIP_LOCATION_URLS.some(skipUrl => this.router.url.match(skipUrl));
  }

  isSkipUrl(url: string): boolean {
    return this.SKIP_API_URLS.some(skipUrl => url.match(skipUrl));
  }

  showErrorMessageDialog(message: string) {
    const dialog = this.matDialog.open(BasicModalComponent, {
      data: {
        title: 'Error',
        description: message,
        buttons: ['Close'],
        type: 'error-message',
      },
    });

    dialog.afterClosed().pipe(take(1)).subscribe();
  }

  showErrorDialog() {
    if (this.isDialogDisplayed) {
      console.warn('Do not show error dialog as previous is still on the screen');
      return;
    }
    const dialog = this.matDialog.open(BasicModalComponent, {
      data: {
        title: "Uh oh, we're experiencing some technical difficulties!",
        description: 'Please contact support and let us know what happened. ',
        buttons: ['Contact support', 'Cancel'],
        type: 'contact-support',
      },
    });
    this.isDialogDisplayed = true;

    dialog
      .afterClosed()
      .pipe(take(1))
      .subscribe(res => {
        this.isDialogDisplayed = false;
        if (res === 'contact-support') {
          // The problem: HttpClient depends on HTTP_INTERCEPTORS, ErrorNotifierInterceptor depends on KnowledgeCenterService,
          // and KnowledgeCenterService depends on HttpClient. This is Cyclic error.
          // To resolve that dependency, we do not use DI for KnowledgeCenterService
          // details: https://github.com/angular/angular/issues/18224#issuecomment-316957213
          const knowledgeCenterService = this.injector.get(KnowledgeCenterService);
          knowledgeCenterService.toggleKnowledgeCenterWidget();
        }
      });
  }

  // @ts-ignore
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> | void {
    const isApiUrl = req.url.startsWith(environment.apiUrl);

    if (!isApiUrl) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      tap(
        _event => {},
        error => {
          if (error instanceof HttpErrorResponse) {
            console.error(error);
            const urlSuffix = req.url.substring(environment.apiUrl.length);
            if (!this.isSkipUrl(urlSuffix) && !this.isSkipLocation() && error.status != 403) {
              if (error.status === HttpStatusCode.BadRequest && !!error?.error?.Message) {
                this.showErrorMessageDialog(error?.error?.Message);
              } else {
                this.showErrorDialog();
              }
            }
          }
        },
      ),
    );
  }
}
