import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { isEmpty } from 'lodash/fp';
import { EMPTY } from 'rxjs';
import { catchError, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { loadedUserSettings, loadUserSettings } from './core.actions';
import { selectUserSettings } from './core.selectors';
import { UserService } from '../services/auth/user.service';

@Injectable()
export class CoreEffects {
  constructor(private actions$: Actions, private store: Store, private userService: UserService) {}

  loadUserSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserSettings),
      withLatestFrom(this.store.select(selectUserSettings)),
      filter(([_action, settings]) => isEmpty(settings)),
      mergeMap(() => {
        return this.userService.getUserSettings().pipe(
          map(settings => loadedUserSettings({ settings })),
          catchError((error: Error) => {
            console.error(error.message);
            return EMPTY;
          }),
        );
      }),
    ),
  );
}
