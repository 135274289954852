<div class="banner-wrapper">
  <p>Download mobile app</p>
  <div class="links-wrapper">
    <a href="https://apps.apple.com/us/app/xosphere-go-fleet-management/id6443417135" target="_blank">
      <img src="../../../../assets/images/app-store.svg" alt="download on the app store" />
    </a>
    <a href="https://play.google.com/store/apps/details?id=com.xosphere.driverapp" target="_blank">
      <img src="../../../../assets/images/google-play.svg" alt="get it on google play" />
    </a>
  </div>
</div>
