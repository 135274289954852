<div
  class="spinner-container"
  [ngClass]="{
    'display-none': !visible,
    'spinner-container-grey': variant === 'Grey',
    'spinner-container-white': variant === 'White'
  }"
>
  <div class="spinner">
    <mat-spinner class="mat-spinner" strokeWidth="1"></mat-spinner>
    <img src="../../../../assets/images/logo.svg" alt="logo" />
  </div>
</div>
