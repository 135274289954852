<div [formGroup]="periodForm" [ngClass]="{ edit: !viewMode }">
  <h4 *ngIf="!viewMode">Set up time of use periods</h4>
  <div class="xos-field-wrapper">
    <xos-moment-datepicker
      title="Start date"
      [selectedDate]="selectedDate"
      (dateChange)="dateChange($event)"
      [disabled]="viewMode"
    ></xos-moment-datepicker>
  </div>
  <h4 *ngIf="!viewMode">
    Schedule preview
    <i
      *ngIf="!selectedPeriod"
      class="icon-info xos-icon"
      matTooltipPosition="above"
      matTooltipClass="xos-tooltip"
      matTooltip="Modify time of use periods to see the Schedule preview. To proceed, please, fill out the form so that your time of use periods cover 24 hours."
    >
    </i>
  </h4>
  <div class="period-part-wrapper" [ngClass]="{ view: viewMode }">
    <xos-energy-pricing-chart [hourPrices]="displayHourPrices" [allActive]="true"></xos-energy-pricing-chart>
  </div>
  <div *ngIf="!isCompletedChart()" class="pricing-error-message">
    <p>Time of use periods incomplete!</p>
    Please ensure your time of use periods cover 24 hours.
  </div>
  <h4 *ngIf="!viewMode">Modify time of use periods to adjust schedule</h4>
  <div
    formArrayName="peaks"
    *ngFor="let peak of peaks?.controls; let i = index"
    class="peak-wrapper"
    [ngClass]="getLabel(peak) | lowercase"
  >
    <ng-container [formGroupName]="i">
      <div class="peak-main">
        <div class="peak-name">
          <div class="icon-wrapper"><i class="xos-icon icon-clock-white"></i></div>
          <div class="peak-label" (click)="openPeakDetails(i, peak)">{{ getLabel(peak) }} peak</div>
        </div>
        <mat-slide-toggle *ngIf="!viewMode" formControlName="enabled" (toggleChange)="togglePeak(i)"></mat-slide-toggle>
        <div *ngIf="viewMode" class="view-peak-ranges">{{ getDisplayRanges(peak?.value?.ranges) }}</div>
      </div>
      <div *ngIf="!viewMode" class="edit-peak-ranges">{{ getDisplayRanges(peak?.value?.ranges) }}</div>
    </ng-container>
  </div>
</div>
