import { Component, Input } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'xos-delete-attachment',
  templateUrl: './delete-attachment.component.html',
  styleUrls: ['../cancel-request/cancel-request.component.scss']
})
export class DeleteAttachmentComponent extends Toast {
  @Input() id: string = '';
  @Input() vehicle: string = '';

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage
  ) {
    super(toastrService, toastPackage);
  }

  close(): void {
    this.toastrService.clear();
  }
}
