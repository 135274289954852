import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PermissionType } from 'src/app/core/models/permissions.enum';
import { UserService } from 'src/app/core/services/auth/user.service';
import { MembershipService } from 'src/app/core/services/membership/membership.service';
import { environment } from 'src/environments/environment';

function getWindow(): any {
  return window;
}

@Injectable({
  providedIn: 'root',
})
export class KnowledgeCenterService {
  hubspotChatContainer: HTMLElement | null;

  constructor(private httpClient: HttpClient, private userService: UserService, private membershipService: MembershipService) {
    membershipService.checkPermissionAsync(PermissionType.OpenKnowledgeCenterWebWidget).subscribe(allowed => {
      if (allowed) {
        this.configureWidget();
        this.authenticateWidget();
        this.closeWebWidget();
      }
    });
    this.hubspotChatContainer = document.querySelector('#hubspot-chat-container') as HTMLElement;
  }

  isWebWidgetOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isChatIconVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  window = getWindow();

  public getChatIconVisibleObservable$(): Observable<boolean> {
    return this.isChatIconVisible.asObservable();
  }

  public toggleKnowledgeCenterWidget(): void {
    const isOpen = this.isWebWidgetOpen.getValue();
    isOpen ? this.closeWebWidget() : this.openWebWidget();
    this.isChatIconVisible.next(isOpen);
  }

  private configureWidget(): void {
    if (environment.hubspotEnabled) {
      this.window.addEventListener('hubspot-chat-close-btn-clicked', () => {
        this.closeWebWidget();
      });
      return;
    }
    const authKnowledgeCenterFn = (callback: any) => {
      this.getKnowledgeCenterToken().subscribe(token => {
        callback(token);
      });
    };
    const authChatFn = (callback: any) => {
      this.getChatToken().subscribe(token => {
        callback(token);
      });
    };

    this.window.zE('webWidget', 'updateSettings', {
      cookies: true,
      errorReporting: true,
      webWidget: {
        color: {
          theme: '#070707',
          launcher: '#070707',
          launcherText: '#ffffff',
          button: '#d58547',
          resultLists: '#070707',
          header: '#070707',
          articleLinks: '#070707',
        },
        helpCenter: {
          enabled: true,
          title: {
            '*': 'Search for help',
          },
        },
        contactForm: {
          enabled: true,
          title: {
            '*': 'Leave us feedback',
          },
          attachments: false,
        },
        chat: {
          departments: {
            enabled: [],
          },
        },
        authenticate: {
          jwtFn: authKnowledgeCenterFn,
          chat: {
            jwtFn: authChatFn,
          },
        },
        position: { horizontal: 'left', vertical: 'bottom' },
        offset: { horizontal: '0px', vertical: '0px' },
        launcher: {
          label: {
            '*': 'Xos Support',
          },
        },
      },
    });

    this.window.zE('webWidget:on', 'close', () => {
      getWindow().zE('webWidget', 'hide');
      this.isWebWidgetOpen.next(false);
      this.isChatIconVisible.next(true);
    });
    this.window.zE('webWidget:on', 'open');
  }

  public authenticateWidget(): void {
    if (environment.hubspotEnabled) {
      this.authenticateHubspotWidget();
      return;
    }

    this.userService.getCurrentUser().subscribe(user => {
      this.window.zE('webWidget', 'identify', {
        name: user.fullName,
        email: user.email,
      });
      this.window.zE('webWidget', 'prefill', {
        name: {
          value: user.fullName,
          readOnly: true,
        },
        email: {
          value: user.email,
          readOnly: true,
        },
      });
      this.window.zE('webWidget', 'helpCenter:reauthenticate');
      this.window.zE('webWidget', 'chat:reauthenticate');

      this.showChatIcon();
    });
  }

  public authenticateHubspotWidget(): void {
    this.userService.getCurrentUser().subscribe(user => {
      this.getHubspotIdentificationToken().subscribe(token => {
        this.window.hsConversationsSettings = {
          ...this.window.hsConversationsSettings,
          identificationEmail: user.email,
          identificationToken: token,
        };
      });
      this.showChatIcon();
    });
  }

  public logout(): void {
    this.closeWebWidget();
    this.logoutWebWidget();
    this.hideChatIcon();
  }

  public showChatIcon() {
    this.isChatIconVisible.next(true);
  }

  public hideChatIcon() {
    this.isChatIconVisible.next(false);
  }

  public openWebWidget(): void {
    if (environment.hubspotEnabled) {
      this.openHubspotWebWidget();
    } else {
      this.openZendeskWebWidget();
    }
    this.isWebWidgetOpen.next(true);
  }

  public closeWebWidget(): void {
    if (environment.hubspotEnabled) {
      this.closeHubspotWebWidget();
    } else {
      this.closeZendeskWebWidget();
    }
    this.isWebWidgetOpen.next(false);
  }

  private logoutWebWidget(): void {
    if (environment.hubspotEnabled) {
      this.logoutHubspotWebWidget();
    } else {
      this.logoutZendeskWebWidget();
    }
  }

  private openZendeskWebWidget(): void {
    this.window.zE('webWidget', 'show');
    this.window.zE('webWidget', 'open');
  }

  private closeZendeskWebWidget(): void {
    this.window.zE('webWidget', 'close');
    this.window.zE('webWidget', 'hide');
  }

  private logoutZendeskWebWidget(): void {
    this.window.zE('webWidget', 'logout');
  }

  private openHubspotWebWidget(): void {
    this.window.HubSpotConversations.widget.load({ widgetOpen: true });
    //@ts-ignore
    this.hubspotChatContainer.style.display = 'block';
  }

  private closeHubspotWebWidget(): void {
    //@ts-ignore
    this.hubspotChatContainer.style.display = 'none';
  }

  private logoutHubspotWebWidget(): void {
    this.window.hsConversationsSettings = {
      ...this.window.hsConversationsSettings,
      identificationEmail: '',
      identificationToken: '',
    };
  }

  public openKnowledgeCenter(redirectUrl: string = ''): void {
    if (!environment.hubspotEnabled) {
      this.openAuthenticatedZendeskUrl('https://xostrucks.zendesk.com/hc/en-us');
      return;
    }
    this.getSSOToken().subscribe(jwtToken => {
      var url = '';
      //open knowledge center from Xosphere
      if (redirectUrl == '') {
        url = `${environment.knowledgeCenterUrl}/_hcms/mem/jwt?jwt=${jwtToken}&redirect_url=${environment.knowledgeCenterUrl}`;
        window.open(url, '_blank', 'noopener,noreferrer');

        //Hubspot SSO verification
      } else if (redirectUrl.includes('_hcms/mem/jwt/verify')) {
        url = `${redirectUrl}?jwt=${jwtToken}`;
        window.location.href = url;

        //Hubspot SSO login
      } else {
        url = `${environment.knowledgeCenterUrl}/_hcms/mem/jwt?jwt=${jwtToken}&redirect_url=${redirectUrl}`;
        window.location.href = url;
      }
    });
  }

  public openAuthenticatedZendeskUrl(url: string): void {
    const linkPrefix = 'https://xostrucks.zendesk.com/access/jwt?jwt=';
    const linkSuffix = '&return_to=' + url;
    this.getSSOToken().subscribe(jwtToken => {
      const fullLink = linkPrefix + jwtToken + linkSuffix;
      window.open(fullLink, '_blank', 'noopener,noreferrer');
    });
  }

  private getChatToken(): Observable<string> {
    return this.httpClient.get(`${environment.apiUrl}/Support/GetChatToken`, { responseType: 'text' });
  }

  private getHubspotIdentificationToken(): Observable<string> {
    return this.httpClient.get(`${environment.apiUrl}/Support/GetHubspotIdentificationToken`, { responseType: 'text' });
  }

  public getKnowledgeCenterToken(): Observable<string> {
    return this.httpClient.get(`${environment.apiUrl}/Support/GetKnowledgeCenterToken`, { responseType: 'text' });
  }

  public getSSOToken(): Observable<string> {
    return this.httpClient.get(`${environment.apiUrl}/Support/GetSSOToken`, { responseType: 'text' });
  }
}
