import { Component, Input } from '@angular/core';

export type ErrorMessage = {
  message?: string;
  title?: string;
  code?: string;
};

export const NoError: ErrorMessage = {
  message: '',
  code: '',
};

@Component({
  selector: 'xos-error-box',
  templateUrl: './error-box.component.html',
  styleUrls: ['./error-box.component.scss'],
})
export class ErrorBoxComponent {
  @Input() errorMessage!: ErrorMessage;

  get title(): string {
    return this.errorMessage?.title || 'Error';
  }

  get description(): string | undefined {
    return this.errorMessage?.message;
  }
}
