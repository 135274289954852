import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ExternalService } from '../../services/external.service';
import { EMPTY, Subject, timer } from 'rxjs';
import { catchError, concatMap, takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { WidgetData } from '../../models/overview-widget.interface';

@Component({
  selector: 'xos-embed-widget-overview',
  templateUrl: './embed-widget-overview.component.html',
  styleUrls: ['./embed-widget-overview.component.scss'],
})
export class EmbedWidgetOverviewComponent implements OnInit, OnDestroy {
  id!: string;
  theme: 'dark' | 'light' = 'dark';
  mode: 'desktop' | 'mobile' = 'desktop';
  metricSystem: boolean = false;
  data!: WidgetData;
  fails: number = 0;
  private unsubscribe$: Subject<void> = new Subject();
  readonly interval: number = 15 * 60 * 1000; // 15 minutes
  readonly failAttempts: number = 20;
  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange(): void {
    if (document.hidden) {
      setTimeout(() => {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
      }, 10000);
    } else {
      if (this.unsubscribe$.isStopped) {
        this.unsubscribe$ = new Subject();
        this.startPolling();
      }
    }
  }

  constructor(private route: ActivatedRoute, private externalService: ExternalService) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id') || '';
    this.theme = this.route.snapshot.queryParams?.theme?.toLowerCase() === 'light' ? 'light' : 'dark';
    this.mode = this.route.snapshot.queryParams?.mode?.toLowerCase() === 'desktop' ? 'desktop' : 'mobile';
    this.startPolling();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getClasses(): string {
    return `${this.theme} ${this.mode}`;
  }

  get unit(): string {
    return this.metricSystem ? 'kilometers' : 'miles';
  }

  private startPolling() {
    timer(0, this.interval)
      .pipe(
        concatMap(() => {
          return this.externalService.getCustomerWidgetData(this.id).pipe(
            catchError((error: Error) => {
              this.fails++;
              console.error(error.message);
              // stop polling after 20 attempts
              if (this.fails === this.failAttempts) {
                this.unsubscribe$.next();
              }
              return EMPTY;
            }),
          );
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(response => {
        this.fails = 0;
        this.metricSystem = response.metricSystem;
        this.data = response.data;
      });
  }
}
