<div class="modal-content-wrapper">
  <header class="modal-header">
    <h1 class="modal-title" [innerHTML]="data.title"></h1>
  </header>
  <section class="modal-body">
    <p>{{ data.description }}</p>
  </section>
  <footer class="modal-footer">
    <button *ngIf="data?.buttons?.length" mat-raised-button class="`modal-cancel-button`" (click)="close()">
      {{ data.buttons[1] }}
    </button>
    <button
      *ngIf="data?.buttons?.length"
      mat-raised-button
      class="modal-action-button"
      [ngClass]="data.type | lowercase"
      (click)="action()"
    >
      {{ data.buttons[0] }}
    </button>
  </footer>
</div>
