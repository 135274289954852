import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ErrorData, Errors, ValidationMessages } from '../../models/validation-messages';
import { TooltipPosition } from '../../models/tooltip.type';

@Component({
  selector: 'xos-error-tooltip',
  templateUrl: './error-tooltip.component.html',
  styleUrls: ['./error-tooltip.component.scss'],
})
export class ErrorTooltipComponent implements OnChanges {
  @Input() errors!: any;
  @Input() tooltipPosition: TooltipPosition = 'right';
  errorMessage!: ErrorData;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.errors?.currentValue) {
      this.getMessage();
    }
  }

  private getMessage(): void {
    switch (true) {
      case this.errors.required:
        this.errorMessage = ValidationMessages.get(Errors.emptyPassword) as ErrorData;
        break;
      case this.errors.email:
        this.errorMessage = ValidationMessages.get(Errors.emailValidation) as ErrorData;
        break;
      case this.errors.emailOrPassword:
        this.errorMessage = ValidationMessages.get(Errors.emailOrPassword) as ErrorData;
        break;
      case this.errors.passwordStrength:
        this.errorMessage = ValidationMessages.get(Errors.passwordStrength) as ErrorData;
        break;
      case this.errors.oldPasswordEmpty:
        this.errorMessage = ValidationMessages.get(Errors.oldPasswordEmpty) as ErrorData;
        break;
      case this.errors.confirmedValidator:
        this.errorMessage = ValidationMessages.get(Errors.passwordValidationMatch) as ErrorData;
        break;
      case this.errors.duplicateValue:
        this.errorMessage = ValidationMessages.get(Errors.duplicateValue) as ErrorData;
        break;
      case this.errors.wrongCode:
        this.errorMessage = ValidationMessages.get(Errors.wrongCode) as ErrorData;
        break;
    }
  }
}
