import { BasicConfigStateItem, FeatureStateItem } from './products.actions';
import { VehicleType } from '../models/vehicle-type.interface';

export interface ProductsState {
  readonly features: FeatureStateItem[];
  readonly basicConfig: BasicConfigStateItem[];
  readonly vehicleTypes: VehicleType[];
}

export const initialState: ProductsState = { features: [], basicConfig: [], vehicleTypes: [] };
