import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { KnowledgeCenterService } from 'src/app/knowledge-center/services/knowledge-center.service';
import { AuthService } from '../../services/auth/auth.service';
import { UserService } from '../../services/auth/user.service';
import { MFA_ACTIVE } from '../../components/login-form/login-form.component';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private auth: AuthService,
    private supportService: KnowledgeCenterService,
    private userService: UserService,
  ) {}

  canActivate(_next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.getAuthenticatedUser().pipe(
      switchMap(cognitoUser => {
        if (!cognitoUser) {
          this.supportService.logout();
          this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
          return of(false);
        } else {
          return this.userService.getCurrentUser().pipe(
            map(currentUser => {
              if (!currentUser.isMfaEnabled && cognitoUser) {
                return true;
              } else {
                currentUser.isMfaEnabled &&
                  cognitoUser.preferredMFA === 'NOMFA' &&
                  this.router.navigate(['/login'], { queryParams: { mfa: true } });
                return currentUser.isMfaEnabled && cognitoUser.preferredMFA === MFA_ACTIVE;
              }
            }),
          );
        }
      }),
    );
  }
}
