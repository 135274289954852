import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { OptionalCategory, OptionalFeature } from '../models/optional-feature.interface';
import { VehicleType } from '../models/vehicle-type.interface';
import { BasicTruckConfiguration } from '../models/basic-truck-configuration.interface';
import { Quote } from '../models/quote.interface';
import { QuoteInfo } from '../models/quote-info.interface';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  pageTitle$: BehaviorSubject<string> = new BehaviorSubject<string>('Products');
  showBreadcrumbs$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  showTotalPrice$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  totalPrice$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(private httpClient: HttpClient) {}

  getOptionalFeatures(typeId: number): Observable<OptionalFeature[]> {
    return this.httpClient.get<OptionalFeature[]>(`${environment.apiUrl}/OptionalFeatures/${typeId}`).pipe(
      map(optionalFeatures =>
        optionalFeatures.map(item =>
          /\s/.test(item.name) ? { ...item, name: item.name.toLowerCase().replace(/^./, str => str.toUpperCase()) } : item,
        ),
      ),
      shareReplay(),
      catchError((error: Error) => {
        console.error(error.message);
        return EMPTY;
      }),
    );
  }

  getVehicleTypes(): Observable<VehicleType[]> {
    return this.httpClient.get<VehicleType[]>(`${environment.apiUrl}/VehicleTypes`).pipe(
      shareReplay(),
      catchError((error: Error) => {
        console.error(error.message);
        return EMPTY;
      }),
    );
  }

  getBasicConfigurations(vehicleTypeId: number): Observable<BasicTruckConfiguration[]> {
    return this.httpClient.get<BasicTruckConfiguration[]>(`${environment.apiUrl}/BaseConfigurations/${vehicleTypeId}`).pipe(
      catchError((error: Error) => {
        console.error(error.message);
        return EMPTY;
      }),
    );
  }

  saveQuote(quote: Quote): Observable<QuoteInfo> {
    return this.httpClient.put<QuoteInfo>(`${environment.apiUrl}/Quotes`, quote).pipe(
      catchError((error: Error) => {
        console.error(error.message);
        return EMPTY;
      }),
    );
  }

  getOptionCategories(optionalFeatures: OptionalFeature[], options: any[]): OptionalCategory[] {
    const categories = [...new Set(optionalFeatures.map(item => item.category))];
    const getOptions = (categoryName: string) =>
      optionalFeatures.filter(el => el.category === categoryName).filter(option => options.find(id => id === option.id));
    return categories.map((item: any) => {
      return {
        name: item,
        options: getOptions(item).map(option => ({ name: option.name, price: option.price })),
        ids: [],
      };
    });
  }
}
