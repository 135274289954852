import { some } from 'lodash/fp';
import { Observable } from 'rxjs';

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { PermissionType } from 'src/app/core/models/permissions.enum';
import { AnalyticsService } from '../../../core/services/analytics.service';
import { MembershipService } from '../../../core/services/membership/membership.service';
import { KnowledgeCenterService } from '../../../knowledge-center/services/knowledge-center.service';
import { SidenavState } from '../../../overview/models/sidenav-state.enum';
import { SidenavService } from '../../services/sidenav.service';

@Component({
  selector: 'xos-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  @Input() state!: SidenavState;
  PermissionType = PermissionType;
  resetQueryParams = { tab: undefined, id: undefined, chargerId: undefined, connectorId: undefined, action: undefined };
  showSettingsMenu$: Observable<boolean> = this.sidenavService.showSettingsMenu;
  permissions$: Observable<PermissionType[]> = this.membershipService.permissions;
  readonly storageKey: string = 'beforeSettingsUrl';
  isPowerTrain: boolean = false;
  hasCustomerSupport$: Observable<boolean> = this.membershipService.checkPermissionAsync(PermissionType.OpenKnowledgeCenterWebWidget);

  constructor(
    private supportService: KnowledgeCenterService,
    private membershipService: MembershipService,
    private analytics: AnalyticsService,
    private router: Router,
    private sidenavService: SidenavService,
  ) {}

  ngOnInit() {
    this.sidenavService.toggleSettingsMenu(this.router.routerState.snapshot.url.search('settings') > -1);
  }

  isMaintenanceAvailable(): boolean {
    return (
      this.membershipService.isPermissionAvailable(PermissionType.ServiceRequests) ||
      this.membershipService.isPermissionAvailable(PermissionType.ViewPreventativeMaintenance) ||
      this.membershipService.isPermissionAvailable(PermissionType.ServiceRequestsHistory) ||
      this.membershipService.isPermissionAvailable(PermissionType.ViewWarrantyClaims)
    );
  }

  isProductsAvailable(): boolean {
    return this.membershipService.isPermissionAvailable(PermissionType.SubmitQuotes);
  }

  isKnowledgeCenterAvailable(): boolean {
    return this.membershipService.isPermissionAvailable(PermissionType.OpenKnowledgeCenter) && !this.isKnowledgeCenterOnly();
  }

  isSalesAvailable(): boolean {
    return (
      this.membershipService.isPermissionAvailable(PermissionType.ViewOrders) ||
      this.membershipService.isPermissionAvailable(PermissionType.ViewQuotes) ||
      this.membershipService.isPermissionAvailable(PermissionType.ViewWarrantyRegistrations)
    );
  }

  isOverviewAvailable(): boolean {
    return (
      this.membershipService.isPermissionAvailable(PermissionType.VehiclesTelematics) ||
      this.membershipService.isPermissionAvailable(PermissionType.ViewBasicVehicleTelematics)
    );
  }

  isAdminAvailable(): boolean {
    return this.membershipService.isSysAdmin || this.membershipService.isPermissionAvailable(PermissionType.TenantAdministration);
  }

  openKnowledgeCenter(): void {
    this.analytics.trackEvent('OpenKnowledgeCenter', { source: 'Sidenav' });
    this.supportService.openKnowledgeCenter();
    this.hideSettingsSubMenu();
  }

  isCollapsed(): boolean {
    return this.state === SidenavState.Collapsed;
  }

  isExpanded(): boolean {
    return this.state === SidenavState.Expanded;
  }

  toggleWebWidget(): void {
    this.supportService.toggleKnowledgeCenterWidget();
  }

  isKnowledgeCenterOnly(): boolean {
    return (
      this.membershipService.isPermissionAvailable(PermissionType.OpenKnowledgeCenter) &&
      this.membershipService.isPermissionAvailable(PermissionType.OpenKnowledgeCenterWebWidget) &&
      this.membershipService.myPermissions?.length === 2
    );
  }

  navigateToSettings(): void {
    this.sidenavService.toggleMainSideNav(SidenavState.Expanded, true);
  }

  isNotificationsAvailable(): boolean {
    return (
      this.membershipService.isPermissionAvailable(PermissionType.ServiceRequests) ||
      this.membershipService.isPermissionAvailable(PermissionType.ServiceRequestsHistory) ||
      this.membershipService.isPermissionAvailable(PermissionType.SubmitQuotes) ||
      this.membershipService.isPermissionAvailable(PermissionType.ViewWarrantyRegistrations)
    );
  }

  isVehicleSettingsAvailable(): boolean {
    this.isPowerTrain = this.membershipService.isPermissionAvailable(PermissionType.EditPowertrainsNicknames);
    return this.membershipService.isPermissionAvailable(PermissionType.VehiclesBaseInformation) || this.isPowerTrain;
  }

  isChargerSettingsAvailable(): boolean {
    return this.membershipService.isPermissionAvailable(PermissionType.ChargersBaseInformation);
  }

  isUtilityPolicyAvailable(): boolean {
    return this.membershipService.isPermissionAvailable(PermissionType.EditUtilityPolicies);
  }

  hideSettingsSubMenu() {
    this.sidenavService.toggleSettingsMenu();
  }

  isMobileBannerAvailable(): boolean {
    const supportedPermissions: PermissionType[] = [
      PermissionType.ServiceRequests,
      PermissionType.ServiceRequestsHistory,
      PermissionType.DriverWiFiSetup,
      PermissionType.ViewInspections,
      PermissionType.CreateInspection,
      PermissionType.VehiclesTelematics,
    ];
    return some(permission => this.membershipService.isPermissionAvailable(permission), supportedPermissions);
  }

  get isFreePlan(): boolean {
    return this.membershipService.isPermissionAvailable(PermissionType.ViewBasicVehicleTelematics);
  }
}
