<span class="user-icon-wrapper" [ngClass]="{ 'avatar-wrapper': avatarUrl, 'table-view': tableView, 'show-mail': showMailIcon }">
  <i *ngIf="showMailIcon" class="xos-icon icon-mail-white"></i>
  <span *ngIf="!showMailIcon">
    <span class="default-icon" *ngIf="defaultIcon && !avatarUrl">
      {{ defaultIcon }}
    </span>
    <span class="user-icon" *ngIf="icon && !avatarUrl">
      {{ icon }}
    </span>
    <img class="default-icon avatar" *ngIf="avatarUrl" [src]="avatarUrl" [alt]="userName" />
  </span>
</span>
