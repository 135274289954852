import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GuidesService } from 'src/app/core/services/guides.service';
import { AnalyticsService } from '../../../core/services/analytics.service';
import { AuthService } from '../../../core/services/auth/auth.service';
import { UserInfo, UserService } from '../../../core/services/auth/user.service';
import { MembershipService } from '../../../core/services/membership/membership.service';
import { KnowledgeCenterService } from '../../../knowledge-center/services/knowledge-center.service';
import { NotificationsService } from '../../../notifications/services/notifications.service';
import { tap } from 'rxjs/operators';
import { PermissionType } from 'src/app/core/models/permissions.enum';
import { SidenavService } from '../../services/sidenav.service';
import { SidenavState } from '../../../overview/models/sidenav-state.enum';

@Component({
  selector: 'xos-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent implements OnInit {
  public currentUserInfo$!: Observable<UserInfo>;
  public isNotificationsAvailable: boolean = false;
  public notificationsCount: number = 0;
  PermissionType = PermissionType;

  constructor(
    public authService: AuthService,
    private userService: UserService,
    private notificationsService: NotificationsService,
    private knowledgeCenterService: KnowledgeCenterService,
    public membershipService: MembershipService,
    private router: Router,
    private analytics: AnalyticsService,
    private guides: GuidesService,
    private sidenavService: SidenavService,
  ) {}

  ngOnInit(): void {
    this.currentUserInfo$ = this.userService.getCurrentUser().pipe(tap(user => this.userService.setCurrentUserName(user.fullName)));
    this.notificationsService.currentNotificationsCountUnread$.subscribe(count => {
      this.notificationsCount = count;
    });

    this.isNotificationsAvailable =
      this.membershipService.isPermissionAvailable(PermissionType.ServiceRequests) ||
      this.membershipService.isPermissionAvailable(PermissionType.ServiceRequestsHistory) ||
      this.membershipService.isPermissionAvailable(PermissionType.SubmitQuotes) ||
      this.membershipService.isPermissionAvailable(PermissionType.ViewWarrantyRegistrations);
  }

  toggleNotificationCenter(): void {
    this.analytics.trackEvent('HeaderNavigation.ToggleNotificationCenter');
    this.notificationsService.toggleNotificationCenterSideNav();
  }

  onClickLogout() {
    this.analytics.trackEvent('Logout');
    this.userService.forceReloadSettings();
    this.knowledgeCenterService.logout();
    this.authService.logOut().then(_ => this.router.navigate(['/login']));
  }

  openKnowledgeCenter(): void {
    this.analytics.trackEvent('OpenKnowledgeCenter', { source: 'HeaderNavigation' });
    this.knowledgeCenterService.openKnowledgeCenter();
  }

  isGuideAvailable() {
    return this.guides.isGuideAvailableForCurrentUrl();
  }

  openGuide(): void {
    this.guides.launchGuide();
  }

  navigateToSettings(): void {
    this.router.navigateByUrl('/settings/profile');
    this.sidenavService.toggleMainSideNav(SidenavState.Expanded, true);
  }

  get isFreePlan(): boolean {
    return this.membershipService.isPermissionAvailable(PermissionType.ViewBasicVehicleTelematics);
  }

  upgradeFreePlan(): void {
    window.location.href = 'mailto:sales@xostrucks.com';
  }
}
